import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { tableOptions } from './TableOptions';

import {
    Business,
    Edit,
    Delete,
    Speed,
    MergeType,
    MoreVert,
    PermIdentity,
} from '@material-ui/icons'

export default function CustomerTable() {
    const [customers, setCustomers] = useState([]);
    const [objectColumns, setObjectColumns] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const [canCreateCustomers, setCanCreateCustomers] = useState(true); // Permission level removed
    const classes = useStyles();
    const muiTable = useRef(null);

    useEffect(() => {
        /*let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "11") > -1) {
                    setCanCreateCustomers(true);
                } else {
                    setCanCreateCustomers(false);
                }
            }
        }*/

        setObjectColumns(
            [
                {
                    name: "",
                    options: {
                        filter: false,
                        viewColumns: false,
                    }
                },
                {
                    name: "Namn",
                    options: {
                        display: "true",
                    }
                },
                {
                    name: "Kundnummer",
                    options: {
                        filter: false,
                        display: "true",
                    }
                },

                {
                    name: "Antal anläggningar",
                    options: {
                        display: "true",
                    }
                }
            ]
        );
    }, []);

    useEffect(() => {
        axios.get("/customers/getall")
            .then(res => {
                if (res.status === 200) {
                    setCustomers(res.data);
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })

        //axios.post('/Customers/create', { data: { name: "Testy" } }).then(res => {
        //    console.log(['customers create result', res]);
        //}).catch(err => {
        //    console.log('err');
        //    console.log(err);
        //})

        let columnsVisibleStorage = JSON.parse(localStorage.getItem('customerObjectColumns'));

        if (columnsVisibleStorage) {
            setObjectColumns(columnsVisibleStorage);
        }
    }, []);

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function getBtns(customerId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(customerId)}>
                <MoreVert id={"menuBtn" + customerId} />
            </div>
        )
    }

    function onColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...objectColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = "true";
        } else if (action === "remove") {
            changingColumn.options.display = "false"
        }

        localStorage.setItem('customerObjectColumns', JSON.stringify(tempObjectColumns));
    }

    function facilityBtnClick(e, customerId) {
        let custName = customers.find(cust => cust.id === customerId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/anläggningar/anläggningslista?kundnamn=' + custName.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/anläggningar/anläggningslista?kundnamn=' + custName.name,
                '_blank'
            );
        }
    }

    function userBtnClick(e, customerId) {
        let custName = customers.find(cust => cust.id === customerId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/användare/användarlista?kundnamn=' + custName.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/användare/användarlista?kundnamn=' + custName.name,
                '_blank'
            );
        }
    }

    function pressureBtnClick(e, customerId) {
        let custName = customers.find(cust => cust.id === customerId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/forteckning?objektTyp=0&kundnamn=' + custName.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/forteckning?objektTyp=0&kundnamn=' + custName.name,
                '_blank'
            );
        }
    }

    function liftBtnClick(e, customerId) {
        let custName = customers.find(cust => cust.id === customerId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/forteckning?objektTyp=1&kundnamn=' + custName.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/forteckning?objektTyp=1&kundnamn=' + custName.name,
                '_blank'
            );
        }
    }

    function editBtnClick(e, customerId) {
        if (!canCreateCustomers) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {

            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/kunder/redigera?kundId=' + customerId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/kunder/redigera?kundId=' + customerId,
                    '_blank'
                );
            }
        }
    }

    function delBtnClick(customerId) {
        if (!canCreateCustomers) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort användaren?")) {
                axios.delete("/Customers/delete/" + customerId).then(
                    res => {
                        if (res.status === 200) {
                            setCustomers(customers.filter(customer => customer.id !== customerId));
                            sendNotification("Kund borttagen!", true);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => pressureBtnClick(e, menuPlacement)}
                    >
                        <Speed className={classes.profileMenuIcon} /> Visa tryckobjekt
                    </MenuItem>

                    <MenuItem
                        onMouseDown={e => liftBtnClick(e, menuPlacement)}
                    >
                        <MergeType className={classes.profileMenuIcon} /> Visa liftobjekt
                    </MenuItem>

                    <MenuItem
                        onMouseDown={e => facilityBtnClick(e, menuPlacement)}
                    >
                        <Business className={classes.profileMenuIcon} /> Visa anläggningar
                    </MenuItem>

                    <MenuItem
                        onMouseDown={e => userBtnClick(e, menuPlacement)}
                    >
                        <PermIdentity className={classes.profileMenuIcon} /> Visa användare
                    </MenuItem>
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> <span style={!canCreateCustomers ? { textDecoration: "line-through" } : {}}>Redigera</span>
                    </MenuItem>

                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> <span style={!canCreateCustomers ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                    </MenuItem>
                </Menu>}
                <Grid item xs={12}>
                    {customers && (
                        <>
                            <MUIDataTable
                                title="Lista över kunder"
                                data={
                                    customers.map(
                                        (customer) => {
                                            return [
                                                getBtns(customer.id),
                                                customer.name,
                                                customer.customerNumber,
                                                customer.facilities.length
                                            ]
                                        }
                                    )
                                }
                                columns={objectColumns}
                                options={tableOptions(onColumnViewChange)}
                                key={customers}
                                ref={muiTable}
                            />
                        </>
                    )}
                </Grid>
                {/* <Grid item xs={12}>
          <Widget
            title="Material-UI Table"
            noBodyPadding
            bodyClass={classes.tableWrapper}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
            </Grid>
        </>
    );
}
