import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import useStyles from "./styles";

import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  Create,
  PictureAsPdf,
} from "@material-ui/icons";
import uuid from "uuid/v4";

import ManometerImage from "../../images/TemplateImages/manometer.jpg";
import SakerhetsVentilImage from "../../images/TemplateImages/sakerhetsventil.png";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import { toast } from "react-toastify";
import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import Notification from "../../components/Notification/Notification";
import {
  useManagementDispatch,
  useManagementState,
} from "../../context/ManagementContext";
import config from "../../config";
import Axios from "axios";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

const EditUser = () => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  let { id } = useParams();
  const managementDispatch = useManagementDispatch();
  const managementValue = useManagementState();

  function extractExtensionFrom(filename) {
    if (!filename) {
      return null;
    }

    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(filename)[1];
  }

  const uploadToServer = async (file, path, filename) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", filename);
    const uri = `${config.baseURLApi}/file/upload/${path}`;
    await Axios.post(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const privateUrl = `${path}/${filename}`;

    return `${config.baseURLApi}/file/download?privateUrl=${privateUrl}`;
  };

  const handleFile = async (event) => {
    const file = event.target.files[0];

    const extension = extractExtensionFrom(file.name);
    const id = uuid();
    const filename = `${id}.${extension}`;
    const privateUrl = `users/avatar/${filename}`;

    const publicUrl = await uploadToServer(file, "users/avatar", filename);
    let avatarObj = {
      id: id,
      name: file.name,
      sizeInBytes: file.size,
      privateUrl,
      publicUrl,
      new: true,
    };

    setData({
      ...data,
      avatar: [...data.avatar, avatarObj],
    });

    return null;
  };

  useEffect(() => {
    setData(managementValue.currentUser);
  }, [managementDispatch, managementValue, id]);

  const panelArray = [
    {
      id: 0,
      open: false,
    },
    {
      id: 1,
      open: false,
    },
    {
      id: 2,
      open: false,
    },
    {
      id: 3,
      open: false,
    },
    {
      id: 4,
      open: false,
    },
  ];
  const [expanded, setExpanded] = React.useState(panelArray);

  const handleExpandChange = (panelId) => () => {
    setExpanded(
      expanded.map((panel) =>
        panel.id === panelId
          ? { ...panel, open: !expanded[panelId].open }
          : { ...panel }
      )
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Accordion expanded={expanded[0].open} onChange={handleExpandChange(0)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>1. Finns korrekt monterad manometer?</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <span className={classes.checkGreen}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkOrange}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkRed}>
                      <RadioButtonChecked />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography>2022-06-10</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Lars Lindberg</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Spillberg AB</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.QuestionUnderline}>
            <Grid item xs={3}>
              <Typography>Finns korrekt monterad manometer?</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Nej</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                Förslag till åtgärd:
                <br />
                Montera manometer
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <img
                className={classes.GridImage}
                src={ManometerImage}
                alt="Manometer"
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion expanded={expanded[1].open} onChange={handleExpandChange(1)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>2. Utförs FLT på projektet?</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <span className={classes.checkGreen}>
                      <RadioButtonChecked />
                    </span>
                    <span className={classes.checkOrange}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkRed}>
                      <RadioButtonUnchecked />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography>2022-06-05</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Lars Lindberg</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Spillberg AB</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.QuestionUnderline}>
            <Grid item xs={3}>
              <Typography>Utförs FLT på projektet?</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Ja</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography></Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion expanded={expanded[2].open} onChange={handleExpandChange(2)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>3. Utförs kontroll (AKO) på projektet?</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <span className={classes.checkGreen}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkOrange}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkRed}>
                      <RadioButtonChecked />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography>2022-06-02</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Anders Harber</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Topp AB</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.QuestionUnderline}>
            <Grid item xs={3}>
              <Typography>
                Utförs kontroll (AKO) på projektet? <Create />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Ja</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography></Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion expanded={expanded[3].open} onChange={handleExpandChange(3)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>4. Utförs egenkontroll på objektet?</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <span className={classes.checkGreen}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkOrange}>
                      <RadioButtonChecked />
                    </span>
                    <span className={classes.checkRed}>
                      <RadioButtonUnchecked />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography>2022-06-08</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Lars Lindberg</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Spillberg AB</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.QuestionUnderline}>
            <Grid item xs={3}>
              <Typography>Utförs egenkontroll på objektet?</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Beräkning behövs</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                Förslag till åtgärd:
                <br />
                Se bifogat dokument
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <PictureAsPdf />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion expanded={expanded[4].open} onChange={handleExpandChange(4)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={9}>
                  <Typography>5. Är anordningen avsäkrad med korrekt monterad säkerhetsventil?</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <span className={classes.checkGreen}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkOrange}>
                      <RadioButtonUnchecked />
                    </span>
                    <span className={classes.checkRed}>
                      <RadioButtonChecked />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography>2022-06-10</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Hannes Jonasson</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Tryckbolaget</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.QuestionUnderline}>
            <Grid item xs={3}>
              <Typography>
                Är anordningen avsäkrad med korrekt monterad säkerhetsventil?
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Nej</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                Förslag till åtgärd:
                <br />
                Installera säkerhetsventil
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <img
                className={classes.GridImage}
                src={SakerhetsVentilImage}
                alt="Säkerhetsventil"
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default EditUser;
