import React, { useState, useEffect } from 'react'
import Widget from '../Widget'
import { toast } from 'react-toastify'
import Notification from "../../components/Notification/Notification";
import axios from 'axios';
import {
    Delete,
    Image,
    ArrowBackIos,
    ArrowForwardIos
} from '@material-ui/icons'
import {
    Box,
    Grid,
    Modal,
    Backdrop,
    Fade,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button
} from '@material-ui/core'
import { Typography } from '../Wrappers'

// styles
import useStyles from './styles.js'
import { cleanseDateStringFromT } from '../../helpers/storedDataHelper';

const ActionModal = ({
    actionModalOpen,
    actionModalCloseNow,
    objectID
}) => {
    const classes = useStyles();
    const currentDate = new Date();
    const fileInput = React.useRef(null);
    const [measureID, setMeasureID] = useState(""); //Actions are called measures locally, but are still named Actions in the back-end
    const [measureAllowProceed, setMeasureAllowProceed] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [measureImageList, setMeasureImageList] = useState([]);
    const [currentShownImage, setCurrentShownImage] = useState(0);
    const [measure, setMeasure] = useState(
        {
            actionText: "",
            status: 0,
            objectID: objectID,
            formID: null,
            actionExpirationDate: cleanseDateStringFromT(getProperCurrentDate()),
            actionResponsible: "",
            images: []
        }
    );

    useEffect(() => {
        if (measure.actionText) {
            setMeasureAllowProceed(true);
        } else {
            setMeasureAllowProceed(false);
        }
    }, [measure.actionText])

    const handleFile = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('File', file);
        formData.append('ObjectID', measure.objectID);

        axios.post('/Forms/imageUpload', formData).then(
            res => {
                if (res.status === 201) {
                    let newMeasureImageList = [...measureImageList]
                    newMeasureImageList.push(res.data);
                    setMeasureImageList(newMeasureImageList);
                    setCurrentShownImage(newMeasureImageList.length - 1);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        return;
    }

    const imageModalOpenNow = () => {
        setImageModalOpen(true);
    };

    const imageModalCloseNow = () => {
        setImageModalOpen(false);
    };

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function getProperCurrentDate() {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return finalDateString;
    }

    function removeSelectedImage() {
        let newImages = [...measureImageList].filter((item, index) => { return (index !== currentShownImage) });

        if (currentShownImage > 0) {
            setCurrentShownImage(currentShownImage - 1);
        }

        setMeasureImageList(newImages);
    }

    function handleChange(e) {
        setMeasure({
            ...measure,
            [e.target.name]: e.target.value,
        });
    }

    function createActionPoint() {
        let newMeasure = { ...measure }
        newMeasure.images = measureImageList;

        axios.post("/action/create/", newMeasure).then(
            res => {
                if (res.status === 200) {
                    sendNotification("Åtgärdspunkt skapad!", true);
                    actionModalCloseNow(true);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalContent}
                open={actionModalOpen}
                onClose={() => actionModalCloseNow()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={actionModalOpen}>
                    <div className={classes.modalPaper} style={{ overflow: "auto", maxWidth: "648px", width: "100%" }}>
                        <Widget>
                            <Grid item justifyContent={'center'} style={{ boxShadow: "none" }} container>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    width={600}
                                    style={{ width: "100%", maxWidth: "600px" }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        weight={'medium'}
                                    >
                                        Skapa åtgärd
                                    </Typography>
                                    <TextField
                                        id="text"
                                        label="Åtgärdstext"
                                        onChange={handleChange}
                                        value={measure.actionText}
                                        name="actionText"
                                        variant="outlined"
                                        style={{ marginBottom: 30, marginTop: 30 }}
                                        helperText="Redigera texten till åtgärden. Tryck enter för att lägga till ny rad"
                                        multiline
                                    />
                                    <div className={classes.splicedFields}>
                                        <TextField
                                            id="text"
                                            label="Ansvarig"
                                            onChange={handleChange}
                                            name="actionResponsible"
                                            value={measure.actionResponsible}
                                            variant="outlined"
                                            style={{ marginBottom: 30 }}
                                            className={classes.splicedField}
                                            helperText="Skriv in namn på ansvarig till åtgärdspunkten"
                                        />
                                        <div className={classes.splicedFieldDistancer} />
                                        <TextField
                                            type="date"
                                            onChange={handleChange}
                                            name="actionExpirationDate"
                                            value={measure.actionExpirationDate}
                                            variant="outlined"
                                            style={{ marginBottom: 35 }}
                                            helperText="Ange sista datum för åtgärdspunkten"
                                            className={classes.splicedField}
                                        />
                                    </div>

                                    <div className={classes.splicedFields}>
                                        <FormControl variant="outlined" className={classes.splicedField}>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                name="status"
                                                onChange={handleChange}
                                                label="Status"
                                                value={measure.status}
                                            >
                                                <MenuItem value={0}>Ej åtgärdad</MenuItem>
                                                <MenuItem value={1}>Pågående</MenuItem>
                                                <MenuItem value={2}>Åtgärdad</MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                Ange status för åtgärdspunkten
                                            </FormHelperText>
                                        </FormControl>

                                        <div className={classes.splicedFieldDistancer} />

                                        <Grid container item xs={12} justifyContent="center" className={classes.splicedField} style={{ position: "relative" }}>
                                            {measureImageList.length !== 0 && <div>
                                                {measureImageList[currentShownImage - 1] && <ArrowBackIos style={{ color: "#17a506", position: "absolute", top: "50px", transform: "translateY(-50%)", left: "0%", zIndex: 10, cursor: "pointer" }} onClick={() => setCurrentShownImage(currentShownImage - 1)} />}
                                                {measureImageList[currentShownImage + 1] && <ArrowForwardIos style={{ color: "#17a506", position: "absolute", top: "50px", transform: "translateY(-50%)", right: "0%", zIndex: 10, cursor: "pointer" }} onClick={() => setCurrentShownImage(currentShownImage + 1)} />}
                                                <div className={classes.imgContainer}>
                                                    {measureImageList[currentShownImage] && <img className={classes.thumbnail + " image"} src={measureImageList[currentShownImage].filePath} />}
                                                    <span
                                                        onClick={() => removeSelectedImage()}
                                                        className={classes.imgCross}
                                                    >
                                                        <Delete />
                                                    </span>
                                                    <div className={classes.imgMiddle + " middle"}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => imageModalOpenNow()}
                                                            style={{ height: "50%" }}
                                                        >
                                                            Förstora
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>}

                                            <div style={{ marginTop: 10 }}>
                                                <label
                                                    className={classes.uploadLabel}
                                                    style={{ cursor: 'pointer', margin: "0 auto", display: "block" }}
                                                >
                                                    <Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                    {'Ladda upp'}
                                                    <input style={{ display: 'none' }} accept="image/jpeg, image/png, .pdf, .doc, .docx, .txt, .xls, .xlsx" type="file" ref={fileInput} onChange={e => handleFile(e, measureID)} />
                                                </label>
                                                <Typography
                                                    size={'sm'}
                                                >
                                                    Accepterade filformat: <br /> .PNG, .JPG/.JPEG
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Box
                                            display={'flex'}
                                            justifyContent={'flex-end'}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: "20px" }}
                                                onClick={() => createActionPoint()}
                                                disabled={!measureAllowProceed}
                                            >
                                                Lägg till åtgärdspunkt
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => actionModalCloseNow(false)}
                                            >
                                                Avbryt
                                            </Button>
                                        </Box>
                                    </div>
                                </Box>
                            </Grid>
                        </Widget>
                    </div>
                </Fade>
            </Modal>

            <Modal //Enlarged image modal
                className={classes.modalContent}
                open={imageModalOpen}
                onClose={() => imageModalCloseNow()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={imageModalOpen} onClick={() => imageModalCloseNow()}>
                    <div className={classes.modalPaper}>
                        {measureImageList[currentShownImage] && <img src={measureImageList[currentShownImage].filePath} className={classes.modalImage} />}
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
export default ActionModal
