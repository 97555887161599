import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "../objecttables/styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { measureTableOptions, tableOptions } from './TableOptions';
import { cleanseDateStringFromT } from '../../helpers/storedDataHelper';
import checkedImage from '../../images/CheckMark.png';
import checkedImageYellow from '../../images/CheckMarkOrange.png';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from '../../components/Wrappers'

import {
    Edit,
    Delete,
    Speed,
    MoreVert
} from '@material-ui/icons';

export default function TemplateTable() {
    const [measureColumns, setMeasureColumns] = useState();
    const [canCreateAction, setCanCreateAction] = useState(false);
    const [objNrFilter, setObjNrFilter] = useState([]);
    const [resolvedFilter, setResolvedFilter] = useState(["Ej åtgärdad", "Pågående"]);
    const [measureList, setMeasureList] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const classes = useStyles();
    const muiTable = useRef(null);

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "51") > -1) {
                    setCanCreateAction(true);
                } else {
                    setCanCreateAction(false);
                }
            }
        }

        setMeasureColumns(
            [
                {
                    name: "",
                    options: {
                        filter: false,
                        viewColumns: false
                    }
                },
                {
                    name: "Åtgärdat",
                    options: {
                        display: "true",
                        filterList: resolvedFilter,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value === "Åtgärdad") {
                                return <img style={{ width: "32px" }} src={checkedImage} />
                            } else if (value === "Pågående") {
                                return <img style={{ width: "32px" }} src={checkedImageYellow} />
                            } else {
                                return <span></span>
                            }
                        },
                    }
                },
                {
                    name: "Åtgärdstext",
                    options: {
                        filter: false,
                        display: "true",
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>;
                        },
                    }
                },
                {
                    name: "Skapad",
                    options: {
                        filter: false,
                        display: "true",
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{cleanseDateStringFromT(value)}</div>
                        },
                    }
                },
                {
                    name: "Ändrad",
                    options: {
                        filter: false,
                        display: "true",
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{cleanseDateStringFromT(value)}</div>
                        },
                    }
                },
                {
                    name: "Objektnamn",
                    options: {
                        filter: false,
                        display: "true",
                    }
                },
                {
                    name: "Objektnummer",
                    options: {
                        filter: false,
                        display: true,
                        filterList: objNrFilter
                    }
                }
            ]
        );
    }, [objNrFilter, resolvedFilter]);

    useEffect(() => {
        axios.get("/Action/getall").then(
            res => {
                if (res.status === 200) {
                    setMeasureList(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        const params = window.location.href.split('?')[1];
        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const objectNumber = urlParams.get('ObjektNummer');
            setObjNrFilter([objectNumber]);
            setResolvedFilter(['Ej åtgärdad']);
        }
    }, []);

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Mall borttaget!",
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function getBtns(measureId) {
        return (
            <div style={{ cursor: "pointer", marginLeft: "6px" }} className={"menuBtn" + measureId} onClick={() => menuClick(measureId)}>
                <Edit id={"menuBtn" + measureId} className={classes.mobileMenuButton} />
                <MoreVert id={"menuBtn" + measureId} className={classes.threeDotsButton} />
            </div>
        )
    }

    function excelFunction() {
        let dateTime = new Date();
        let month = dateTime.getMonth().toString();

        if (month.length === 1) {
            month = "0" + month;
        }

        axios({
            url: "/Action/getallExcelFile",
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Åtgärdslista_" + dateTime.getFullYear().toString() + month + dateTime.getDate().toString() + ".xlsx");
            document.body.appendChild(link);
            link.click();
        });
    }

    function openObject(e, measureId) {
        let openObjectId = measureList.find(measure => measure.id === measureId).object.id;

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/dokument?objektID=' + openObjectId;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/dokument?objektID=' + openObjectId,
                '_blank'
            );
        }
    }

    function editBtnClick(e, measureId) {
        if (!canCreateAction) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/åtgärdspunkter/redigera?åtgärdsID=' + measureId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/åtgärdspunkter/redigera?åtgärdsID=' + measureId,
                    '_blank'
                );
            }
        }
    }

    function onColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...measureColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = "true";
        } else if (action === "remove") {
            changingColumn.options.display = "false"
        }

        localStorage.setItem('objectColumns', JSON.stringify(tempObjectColumns));
    }

    function delBtnClick(measureId) {
        if (!canCreateAction) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort åtgärdspunkten?")) {
                axios.delete("/action/" + measureId).then(
                    res => {
                        if (res.status === 200) {
                            setMeasureList(measureList.filter(measure => measure.id != measureId));
                            sendDeletedNotification();
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function onFilterChange(changedColumn, filterList, type) {
        setResolvedFilter(filterList[1]);
        setObjNrFilter(filterList[6]);
    }

    function getStatusText(statusInt) {
        if (statusInt === 2) {
            return "Åtgärdad";
        } else if (statusInt === 1) {
            return "Pågående";
        } else {
            return "Ej åtgärdad";
        }
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    const getMuiTheme = () => createMuiTheme({
        palette: {
            primary: {
                main: "#17a506"
            }
        },
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    '@media (max-width: 960px)': {
                        padding: "2px 0px 0px 4px",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }
                }
            },
            MUIDataTableFilterList: {
                root: {
                    '@media (max-width: 960px)': {
                        margin: "0px 16px 10px 16px"
                    }
                }
            }
        }
    })

    return (<>
        <Grid container spacing={4}>
            {showMenu && <Menu
                id="profile-menu"
                open={true}
                className={classes.headerMenu}
                onClose={() => menuClick(null)}
                classes={{ paper: classes.profileMenu }}
                anchorEl={document.getElementsByClassName("menuBtn" + menuPlacement)[0]}
                disableAutoFocusItem
            >
                <MenuItem
                    onMouseDown={e => openObject(e, menuPlacement)}
                >
                    <Speed className={classes.profileMenuIcon} /> Gå till objekt
                </MenuItem>
                <MenuItem
                    onMouseDown={e => editBtnClick(e, menuPlacement)}
                >
                    <Edit className={classes.profileMenuIcon} /> <span style={!canCreateAction ? { textDecoration: "line-through" } : {}}>Redigera</span>
                </MenuItem>
                <MenuItem
                    onClick={() => delBtnClick(menuPlacement)}
                >
                    <Delete className={classes.profileMenuIcon} /> <span style={!canCreateAction ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                </MenuItem>
            </Menu>}
            <Grid item xs={12}>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title="Lista över åtgärdspunkter"
                        data={
                            measureList.map(
                                measure => {
                                    return [
                                        getBtns(measure.id),
                                        getStatusText(measure.status),
                                        measure.actionText,
                                        measure.created,
                                        measure.changed,
                                        measure.object ? measure.object.objectName : "",
                                        measure.object ? measure.object.objectNr : ""
                                    ]
                                }
                            )
                        }
                        columns={measureColumns}
                        options={measureTableOptions(onColumnViewChange, onFilterChange, excelFunction, canCreateAction)}
                        key={measureList}
                        ref={muiTable}
                    />
                </MuiThemeProvider>
            </Grid>
        </Grid>
    </>);
}
