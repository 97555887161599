import React, { useEffect } from 'react'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Grid,
    Box
} from "@material-ui/core";
import Widget from '../../components/Widget/Widget'
import {
    Checkbox,
    FormControlLabel,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";

const EditQuestionGroup = () => {
    const classes = useStyles();
    const [questionGroupId, setQuestionGroupId] = React.useState("");

    const [questionGroup, setQuestionGroup] = React.useState({
        name: "",
        description: ""
    });

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const newQuestionId = urlParams.get('checklistaID');
            setQuestionGroupId(newQuestionId);

            axios.get('/FormQuestionGroup/' + newQuestionId).then(
                res => {
                    setQuestionGroup(res.data);
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });
        }
    }, []);

    function handleChange(e) {
        setQuestionGroup({
            ...questionGroup,
            [e.target.name]: e.target.value,
        });
    }

    const handleNext = () => {
        axios.put('/FormQuestionGroup/update/' + questionGroupId, questionGroup).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = '/#/app/frågor/lista?checklistaID=' + questionGroupId;
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Fråga ändrad!",
            variant: "contained",
            color: "success"
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Redigera checklista
                            </Typography>
                            <TextField
                                id="text"
                                label="Fråga"
                                onChange={handleChange}
                                name="name"
                                value={questionGroup.name}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Skriv in själva frågan"
                            />
                            <TextField
                                id="description"
                                label="Beskrivning"
                                onChange={handleChange}
                                name="description"
                                value={questionGroup.description}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Ge en frivillig beskrivning som visas under själva frågan"
                            />
                            <div>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        disabled={questionGroup.name.length === 0}
                                    >
                                        Redigera checklista
                                    </Button>
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid >
        </Grid >
    )
}

export default EditQuestionGroup
