import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    tabIcon: {
        verticalAlign: "middle",
        height: "22px"
    },
    tableWrapper: {
        overflowX: "auto"
    },
    badge: {
        //margin: theme.spacing(2, 2, 0, 0),
        margin: 0,
    },
    MUIDataTableBodyCell: {
        stackedCommon: {
            '@media (max-width: 959.95px)': {
                height: "auto"
            }
        }
    },
    headerMenu: {
        marginTop: theme.spacing(7),
    },
    headerMenuList: {
        display: "flex",
        flexDirection: "column",
    },
    headerMenuItem: {
        "&:hover, &:focus": {
            backgroundColor: theme.palette.background.light,
            // color: "white",
        },
    },
    headerMenuButton: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(0.5),
    },
    headerMenuButtonSandwich: {
        marginLeft: 9,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0
        },
        padding: theme.spacing(0.5),
    },
    headerMenuButtonCollapse: {
        marginRight: theme.spacing(2),
    },
    headerIcon: {
        fontSize: 28,
        color: "rgba(255, 255, 255, 0.35)",
    },
    headerIconCollapse: {
        color: "white",
    },
    profileMenu: {
        minWidth: 150,
    },
    profileMenuUser: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
    },
    profileMenuItem: {
        color: theme.palette.text.hint,
    },
    profileMenuIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.text.hint,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    profileMenuLink: {
        fontSize: 16,
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer",
        },
    },
}));
