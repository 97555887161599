import React, { useEffect } from 'react'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import {
    TextField,
    Grid,
    Box
} from "@material-ui/core";
import Widget from '../../components/Widget/Widget'

const AddQuestion = () => {
    const classes = useStyles()
    const [ownAnswerTexts, setOwnAnswerTexts] = React.useState(["Ja", "Nej", "Ej aktuell"]);
    const [questionGroupId, setQuestionGroupId] = React.useState("");
    const [questionType, setQuestionType] = React.useState("question");
    const [headerText, setHeaderText] = React.useState("");
    const [newOrder, setNewOrder] = React.useState(0);

    const [question, setQuestion] = React.useState({
        text: "",
        description: "",
        answers: [],
        questionType: "",
        formQuestionGroupID: ""
    });

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const newQuestionGroupId = urlParams.get('checklistaID');

            if (newQuestionGroupId) {
                setQuestionGroupId(newQuestionGroupId);

                axios.get("/FormQuestion/getall/" + newQuestionGroupId).then(
                    res => {
                        if (res.status === 200) {
                            if (res.data.length > 0 && res.data[res.data.length - 1].sortOrder !== null) {
                                setNewOrder(res.data[res.data.length - 1].sortOrder);
                            }
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }, []);

    function handleChange(e) {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value,
        });
    }

    function handleAnswerChange(e, answerNumber) {
        let newAnswers = [...ownAnswerTexts]
        newAnswers[answerNumber] = e.target.value;
        setOwnAnswerTexts(newAnswers);
    }

    const handleNext = () => {
        if (questionType === "header") {
            let newQuestion = {
                text: headerText,
                description: "Rubrik",
                sortOrder: 0,
                answers: [],
                hideComment: true,
                questionType: "header",
                formQuestionGroupID: questionGroupId
            }

            axios.post('/FormQuestion/Create', newQuestion).then(res => {
                if (res.status === 200) {
                    sendNotification();
                    window.location.href = '/#/app/frågor/lista?checklistaID=' + questionGroupId;
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })
        } else {
            let newQuestion = { ...question };
            newQuestion.formQuestionGroupID = questionGroupId;
            newQuestion.answers = [];
            newQuestion.sortOrder = newOrder;

            for (var i = 0; i < ownAnswerTexts.length; i++) {
                if (ownAnswerTexts[i]) {
                    newQuestion.answers.push({ id: i + 1, text: ownAnswerTexts[i] });
                }
            }

            axios.post('/FormQuestion/Create', newQuestion).then(res => {
                if (res.status === 200) {
                    sendNotification();
                    window.location.href = '/#/app/frågor/lista?checklistaID=' + questionGroupId;
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Fråga tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa ny fråga
                            </Typography>
                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                                style={{ marginBottom: 35 }}
                            >
                                <InputLabel>
                                    Fråga eller rubrik
                                </InputLabel>
                                <Select
                                    value={questionType}
                                    name="questionType"
                                    onChange={e => setQuestionType(e.target.value)}
                                    label="Fråga eller rubrik"
                                >
                                    <MenuItem value="question">Fråga</MenuItem>
                                    <MenuItem value="header">Rubrik</MenuItem>
                                </Select>
                                <FormHelperText>
                                    Ange om du vill ha en fråga eller rubrik
                                </FormHelperText>
                            </FormControl>

                            {questionType === "question" && <>
                                <TextField
                                    id="text"
                                    label="Frågetext"
                                    onChange={handleChange}
                                    name="text"
                                    value={question.text}
                                    variant="outlined"
                                    style={{ marginBottom: 30 }}
                                    helperText="Skriv in själva frågan"
                                />
                                <TextField
                                    id="description"
                                    label="Beskrivning"
                                    onChange={handleChange}
                                    name="description"
                                    value={question.description}
                                    variant="outlined"
                                    style={{ marginBottom: 30 }}
                                    helperText="Ge en frivillig beskrivning som visas under själva frågan"
                                />

                                <Typography style={{ fontWeight: "bold" }}>Svarsalternativ</Typography>
                                <Typography style={{ fontSize: "0.85rem" }}>Lämna fält tomma för att ha färre svarsalternativ</Typography>

                                <TextField
                                    id="answer1"
                                    label="Alternativ 1"
                                    onChange={(e) => handleAnswerChange(e, 0)}
                                    name="answer1"
                                    value={ownAnswerTexts[0]}
                                    variant="outlined"
                                    style={{ marginBottom: 30, marginTop: 30 }}
                                    helperText="Skriv första svarsalternativet"
                                />
                                <TextField
                                    id="answer2"
                                    label="Alternativ 2"
                                    onChange={(e) => handleAnswerChange(e, 1)}
                                    name="answer2"
                                    value={ownAnswerTexts[1]}
                                    variant="outlined"
                                    style={{ marginBottom: 30 }}
                                    helperText="Skriv andra svarsalternativet"
                                />
                                <TextField
                                    id="answer3"
                                    label="Alternativ 3"
                                    onChange={(e) => handleAnswerChange(e, 2)}
                                    name="answer3"
                                    value={ownAnswerTexts[2]}
                                    variant="outlined"
                                    style={{ marginBottom: 30 }}
                                    helperText="Skriv tredje svarsalternativet"
                                />

                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={question.text.length === 0}
                                        >
                                            Lägg till fråga
                                        </Button>
                                    </Box>
                                </div>
                            </>}

                            {questionType === "header" && <>
                                <TextField
                                    id="text"
                                    label="Rubriktext"
                                    onChange={e => setHeaderText(e.target.value)}
                                    name="text"
                                    value={headerText}
                                    variant="outlined"
                                    style={{ marginBottom: 30 }}
                                    helperText="Skriv in vad som ska stå som rubrik"
                                />

                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={headerText.length === 0}
                                        >
                                            Lägg till fråga
                                        </Button>
                                    </Box>
                                </div>
                            </>}
                        </Box>
                    </Grid>
                </Widget>
            </Grid >
        </Grid >
    )
}

export default AddQuestion
