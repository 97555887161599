import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import {
    SettingsBackupRestoreOutlined as ResetColumns,
    Archive
} from "@material-ui/icons";

function getToolbar(resetColFunction, excelFunction, allowed) {
    if (!allowed) {
        return;
    }

    const addedToolbar = () => (
        <>
            <Tooltip onClick={() => resetColFunction()} title="Återställ kolumner">
                <IconButton>
                    <ResetColumns />
                </IconButton>
            </Tooltip>
            <Tooltip onClick={() => excelFunction()} title="Hämta excel-ark">
                <IconButton>
                    <Archive />
                </IconButton>
            </Tooltip>
        </>
    );

    return addedToolbar;
}

function getMeasureToolbar(excelFunction, allowed) {
    if (!allowed) {
        return;
    }

    const addedToolbar = () => (
        <>
            <Tooltip onClick={() => excelFunction()} title="Hämta excel-ark">
                <IconButton>
                    <Archive />
                </IconButton>
            </Tooltip>
        </>
    );

    return addedToolbar;
}

function customSort(data, colIndex, order) {
    let remainers = [];
    let newData = data.filter((row) => {
        const foundRow = row.data[colIndex]
        if (!(typeof foundRow === "undefined" || foundRow === null)) {
            if (typeof foundRow === "number") {
                if (foundRow < 0) {
                    console.log(foundRow);
                    remainers.push(row);
                    return false;
                }
                return true;
            } else {
                if (foundRow.length > 0) {
                    if (foundRow[0] === '-' || foundRow[0] === '-' || foundRow[0] === '0') {
                        remainers.push(row);
                        return false;
                    }
                    return true;
                }
            }
        }

        remainers.push(row);
        return false;
    });

    if (order === "asc") {
        newData.sort(function comp(a, b) {
            if (a.data[colIndex] > b.data[colIndex]) {
                return 1;
            } else if (a.data[colIndex] < b.data[colIndex]) {
                return -1;
            } else {
                return 0;
            }
        });
    } else {
        newData.sort(function comp(a, b) {
            if (a.data[colIndex] > b.data[colIndex]) {
                return -1;
            } else if (a.data[colIndex] < b.data[colIndex]) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    return newData.concat(remainers);
}

export const textLabels = {
    body: {
        noMatch: "Inga resultat hittades",
        toolTip: "Klicka för att sortera"
    },
    pagination: {
        rowsPerPage: "Rader per sida"
    },
    viewColumns: {
        title: "Visa kolumner",
    },
    filter: {
        all: "VISA ALLA",
        title: "FILTER",
        reset: "ÅTERSTÄLL"
    },
    toolbar: {
        search: "Sök",
        viewColumns: "Visa kolumner",
        filterTable: "Välj filter"
    },
    selectedRows: {
        text: "rad eller rader markerade"
    }
};

export function measureTableOptions(onColumnViewChange, onFilterChange, excelFunction, canCreateAction) {
    return ({
        filterType: "checkbox",
        onFilterChange: onFilterChange,
        textLabels: textLabels,
        print: false,
        download: false,
        rowsPerPage: 100,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        selectableRows: "none",
        customSort: (data, colIndex, order) => {
            let remainers = [];
            let newData = data.filter((row) => {
                const foundRow = row.data[colIndex]
                if (!(typeof foundRow === "undefined" || foundRow === null)) {
                    if (typeof foundRow === "number") {
                        if (foundRow < 0) {
                            console.log(foundRow);
                            remainers.push(row);
                            return false;
                        }
                        return true;
                    } else {
                        if (foundRow.length > 0) {
                            if (foundRow[0] === '-' || foundRow[0] === '-' || foundRow[0] === '0') {
                                remainers.push(row);
                                return false;
                            }
                            return true;
                        }
                    }
                }

                remainers.push(row);
                return false;
            });

            if (order === "asc") {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return 1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return -1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            return newData.concat(remainers);
        },
        onColumnViewChange: onColumnViewChange,
        options: {
            display: "true",
        },
        customToolbar: getMeasureToolbar(excelFunction, canCreateAction)
    })
}

export function questionTableOptions() {
    return ({
        filterType: "checkbox",
        textLabels: textLabels,
        print: false,
        download: false,
        sort: false,
        filter: false,
        search: false,
        viewColumns: false,
        rowsPerPage: 100,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        selectableRows: "none",
        options: {
            display: "true",
        }
    })
}

export function tableOptions(onColumnViewChange, onFilterChange) {
    return ({
        filterType: "checkbox",
        onFilterChange: onFilterChange,
        textLabels: textLabels,
        print: false,
        download: false,
        rowsPerPage: 100,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        selectableRows: "none",
        customSort: (data, colIndex, order) => {
            let remainers = [];
            let newData = data.filter((row) => {
                const foundRow = row.data[colIndex]
                if (!(typeof foundRow === "undefined" || foundRow === null)) {
                    if (typeof foundRow === "number") {
                        if (foundRow < 0) {
                            console.log(foundRow);
                            remainers.push(row);
                            return false;
                        }
                        return true;
                    } else {
                        if (foundRow.length > 0) {
                            if (foundRow[0] === '-' || foundRow[0] === '-' || foundRow[0] === '0') {
                                remainers.push(row);
                                return false;
                            }
                            return true;
                        }
                    }
                }

                remainers.push(row);
                return false;
            });

            if (order === "asc") {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return 1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return -1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            return newData.concat(remainers);
        },
        onColumnViewChange: onColumnViewChange,
        //customRowRender: (data, dataIndex, rowIndex) => {
        //    console.log(['customRowRender', data, dataIndex, rowIndex]);

        //    return (<div>Test</div>)
        //},
        options: {
            display: "true",
        }
    })
}

export function documentTableOptions(onColumnViewChange, onFilterChange) {
    return ({
        filterType: "checkbox",
        onFilterChange: onFilterChange,
        textLabels: textLabels,
        print: false,
        download: false,
        rowsPerPage: 10,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        selectableRows: "none",
        customSort: (data, colIndex, order) => {
            let remainers = [];
            let newData = data.filter((row) => {
                const foundRow = row.data[colIndex]
                if (!(typeof foundRow === "undefined" || foundRow === null)) {
                    if (typeof foundRow === "number") {
                        if (foundRow < 0) {
                            console.log(foundRow);
                            remainers.push(row);
                            return false;
                        }
                        return true;
                    } else {
                        if (foundRow.length > 0) {
                            if (foundRow[0] === '-' || foundRow[0] === '-' || foundRow[0] === '0') {
                                remainers.push(row);
                                return false;
                            }
                            return true;
                        }
                    }
                }

                remainers.push(row);
                return false;
            });

            if (order === "asc") {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return 1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return -1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            return newData.concat(remainers);
        },
        onColumnViewChange: onColumnViewChange,
        //customRowRender: (data, dataIndex, rowIndex) => {
        //    console.log(['customRowRender', data, dataIndex, rowIndex]);

        //    return (<div>Test</div>)
        //},
        options: {
            display: "true",
        }
    })
}

export function objectTableOptions(onColumnViewChange, onFilterChange, resetColFunction, setRowProps, excelFunction, canCreateObjects) {
    return ({
        filterType: "dropdown",
        onFilterChange: onFilterChange,
        textLabels: textLabels,
        print: false,
        download: false,
        rowsPerPage: 100,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        responsive: 'scrollMaxHeight',
        fixedSelectColumn: true,
        scrollMaxHeight: '500px',
        selectableRows: "none",
        customSort: (data, colIndex, order) => {
            let remainers = [];
            let newData = data.filter((row) => {
                const foundRow = row.data[colIndex]

                if (foundRow) {
                    if (typeof foundRow === "number") {
                        if (foundRow <= 0) {
                            remainers.push(row);
                            return false;
                        }
                        return true;
                    } else {
                        if (foundRow.length > 0) {
                            if (foundRow[0] === '-' || foundRow[0] === '-' || foundRow[0] === '0') {
                                remainers.push(row);
                                return false;
                            }
                            return true;
                        }
                    }
                }

                remainers.push(row);
                return false;
            });

            if (order === "asc") {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return 1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return -1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            return newData.concat(remainers);
        },
        onColumnViewChange: onColumnViewChange,
        //customRowRender: (data, dataIndex, rowIndex) => {
        //    console.log(['customRowRender', data, dataIndex, rowIndex]);
        //},
        setRowProps: setRowProps,
        options: {
            display: "true",
        },
        customToolbar: getToolbar(resetColFunction, excelFunction, canCreateObjects)
    })
}

export function modalTableOptions(onColumnViewChange, selRows) {
    return ({
        filterType: "checkbox",
        textLabels: textLabels,
        print: false,
        download: false,
        rowsPerPage: 100,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        fixedSelectColumn: true,
        responsive: 'scrollMaxHeight',
        scrollMaxHeight: '500px',
        rowsSelected: selRows(),
        customSort: (data, colIndex, order) => {
            let remainers = [];
            let newData = data.filter((row) => {
                const foundRow = row.data[colIndex]

                if (foundRow) {
                    if (typeof foundRow === "number") {
                        if (foundRow <= 0) {
                            remainers.push(row);
                            return false;
                        }
                        return true;
                    } else {
                        if (foundRow.length > 0) {
                            if (foundRow[0] === '-' || foundRow[0] === '-' || foundRow[0] === '0') {
                                remainers.push(row);
                                return false;
                            }
                            return true;
                        }
                    }
                }

                remainers.push(row);
                return false;
            });

            if (order === "asc") {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return 1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                newData.sort(function comp(a, b) {
                    if (a.data[colIndex] > b.data[colIndex]) {
                        return -1;
                    } else if (a.data[colIndex] < b.data[colIndex]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            return newData.concat(remainers);
        },
        onColumnViewChange: onColumnViewChange,
        //customRowRender: (data, dataIndex, rowIndex) => {
        //    console.log(['customRowRender', data, dataIndex, rowIndex]);

        //    return (<div>Test</div>)
        //},
        options: {
            display: "true",
        }
    })
}

export function questionGroupTableOptions() {
    return ({
        filter: false,
        sort: false,
        textLabels: textLabels,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        rowsPerPage: 100,
        fixedHeaderOptions: {
            xAxis: true,
            yAxis: true
        },
        selectableRows: "none",
        options: {
            display: "true",
        }
    })
}

export const getMuiTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: '#17a506'
        }
    },
    overrides: {
        MuiTableCell: {
            body: {
                color: "unset",
                padding: "4px 8px",
            }
        },
        MuiTableRow: {
            root: {
                '&$selected': {
                    backgroundColor: '#7bbd73!important'
                }
            },
        },
        MUIDataTableBodyRow: {
            root: {
                '&:nth-child(even)': {
                    backgroundColor: '#F2F2F2'
                },
                '&:hover': {
                    backgroundColor: "#BBD2B8!important"
                }
            }
        },
        MUIDataTableToolbarSelect: {
            iconButton: {
                display: "none"
            }
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    color: "#17a506!important"
                }
            }
        }
    }
})
