import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { tableOptions } from './TableOptions';
import { Button } from '../../components/Wrappers'

import {
    Store,
    Speed,
    Edit,
    Delete,
    MoreVert,
    MergeType,
    Description
} from '@material-ui/icons'

export default function FacilityTable() {
    const [customerFilter, setCustomerFilter] = useState([]);
    const [facilities, setFacilities] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const [canCreateFacilities, setCanCreateFacilities] = useState(true);
    const [archives, setArchives] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const classes = useStyles();
    const muiTable = useRef(null);

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "11") > -1) {
                    setCanCreateFacilities(true);
                } else {
                    setCanCreateFacilities(false);
                }
            }

            if (currentUser.roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }

        axios.get("/Facilities/getall")
            .then(res => {
                if (res.status === 200) {
                    setFacilities(res.data);

                    axios.get("/Objects/type/" + 3)
                        .then(res => {
                            if (res.status === 200) {
                                setArchives(res.data);
                            }
                        }).catch(err => {
                            console.log('err');
                            console.log(err);
                        })
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const customerName = urlParams.get('kundnamn');
            setCustomerFilter([customerName]);
        }
    }, []);

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function GetBtns(facilityId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(facilityId)}>
                <MoreVert id={"menuBtn" + facilityId} />
            </div>
        )
    }

    function archiveClickBtn(e, facilityId) {
        let selectedArchive = archives.find(arc => arc.facility?.id === facilityId);

        if (selectedArchive) {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/anläggningar/anläggning?objektID=' + selectedArchive.id;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/anläggningar/anläggning?objektID=' + selectedArchive.id,
                    '_blank'
                );
            }
        }
    }

    /*function archiveClickBtn(facilityId) {
        let selectedArchive = archives.find(arc => arc.facility.id === facilityId);
        window.location.href = '/#/app/anläggningar/anläggning?objektID=' + selectedArchive.id;
    }*/

    function sectionClickBtn(e, facilityId) {
        let selectedFac = facilities.find(fac => fac.id === facilityId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/avdelningar/avdelningslista?anläggningsID=' + facilityId + '&anläggningsnamn=' + selectedFac.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/avdelningar/avdelningslista?anläggningsID=' + facilityId + '&anläggningsnamn=' + selectedFac.name,
                '_blank'
            );
        }
    }

    function objectBtnClick(e, facilityId) {
        let selectedFac = facilities.find(fac => fac.id === facilityId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/forteckning?anläggningsNamn=' + selectedFac.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/forteckning?anläggningsNamn=' + selectedFac.name,
                '_blank'
            );
        }
    }

    function pressureBtnClick(e, facilityId) {
        let selectedFac = facilities.find(fac => fac.id === facilityId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/forteckning?objektTyp=0&anläggningsNamn=' + selectedFac.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/forteckning?objektTyp=0&anläggningsNamn=' + selectedFac.name,
                '_blank'
            );
        }
    }

    function liftBtnClick(e, facilityId) {
        let selectedFac = facilities.find(fac => fac.id === facilityId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/forteckning?objektTyp=1&anläggningsNamn=' + selectedFac.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/forteckning?objektTyp=1&anläggningsNamn=' + selectedFac.name,
                '_blank'
            );
        }
    }

    function editBtnClick(e, facilityId) {
        if (!canCreateFacilities) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/anläggningar/redigera?anläggningsId=' + facilityId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/anläggningar/redigera?anläggningsId=' + facilityId,
                    '_blank'
                );
            }
        }
    }

    function delBtnClick(facilityId) {
        if (!canCreateFacilities) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort anläggningen?")) {
                axios.delete("/Facilities/" + facilityId).then(
                    res => {
                        if (res.status === 200) {
                            setFacilities(facilities.filter(facility => facility.id !== facilityId));
                            sendNotification("Anläggning borttagen!", true);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function createBtnClick(e) {
        if (!canCreateFacilities) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/anläggningar/ny';
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/anläggningar/ny',
                    '_blank'
                );
            }
        }
    }

    function onFilterChange(changedColumn, filterList, type) {
        setCustomerFilter(filterList[2]);
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => archiveClickBtn(e, menuPlacement)}
                    >
                        <Description className={classes.profileMenuIcon} /> Visa anläggning
                    </MenuItem>
                    <MenuItem
                        onMouseDown={e => sectionClickBtn(e, menuPlacement)}
                    >
                        <Store className={classes.profileMenuIcon} /> Visa avdelningar
                    </MenuItem>

                    <MenuItem
                        onMouseDown={e => pressureBtnClick(e, menuPlacement)}
                    >
                        <Speed className={classes.profileMenuIcon} /> Visa tryckobjekt
                    </MenuItem>

                    <MenuItem
                        onMouseDown={e => liftBtnClick(e, menuPlacement)}
                    >
                        <MergeType className={classes.profileMenuIcon} /> Visa liftobjekt
                    </MenuItem>
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> <span style={!canCreateFacilities ? { textDecoration: "line-through" } : {}}>Redigera</span>
                    </MenuItem>

                    {isAdmin && <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> <span style={!canCreateFacilities ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                    </MenuItem>}
                </Menu>}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onMouseDown={e => createBtnClick(e)}
                        style={canCreateFacilities ? { marginBottom: 28 } : { marginBottom: 28, textDecoration: "line-Through" }}
                    >
                        Skapa anläggning
                    </Button>
                    {facilities && (
                        <>
                            <MUIDataTable
                                title="Lista över anläggningar"
                                data={facilities.map(
                                    (facility, index) => {
                                        return [
                                            GetBtns(facility.id, index),
                                            facility.name,
                                            facility.customer ? facility.customer.name : null,
                                            facility.city
                                        ]
                                    })}
                                columns={[
                                    {
                                        name: "",
                                        options: {
                                            filter: false,
                                            viewColumns: false,
                                        }
                                    },
                                    {
                                        name: "Namn",
                                        options: {
                                            filterList: []
                                        }
                                    },
                                    {
                                        name: "Kund",
                                        options: {
                                            filterList: customerFilter,
                                            display: isAdmin ? true : false,
                                            viewColumns: isAdmin ? true : false,
                                            filter: isAdmin ? true : false,
                                        }
                                    },
                                    {
                                        name: "Ort"
                                    },
                                ]}
                                options={tableOptions(null, onFilterChange)}
                                key={facilities}
                                ref={muiTable}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
}