import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';

const textLabels = {
  pagination: {
    rowsPerPage: "Rader per sida"
  }
};

export default function UserList() {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get("/users/getall")
      .then(res => {
        if (res.status === 200) {
          setData(res.data);
        }
      }).catch(err => {
          console.log('err');
          console.log(err);
      })
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {data && (
            <>
              <MUIDataTable
                title="Lista över användare"
                data={data.map(user => {
                  return [
                    user.fullName,
                    user.email,
                    (user.roles[0] || '')
                  ]
                })}
                columns={["Namn", "E-Post", "Roll"]}
                options={{
                  filterType: "checkbox",
                  textLabels: textLabels,
                  print: false,
                  download: false
                }}
              />
            </>
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <Widget
            title="Material-UI Table"
            noBodyPadding
            bodyClass={classes.tableWrapper}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
      </Grid>
    </>
  );
}
