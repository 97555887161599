import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const ChangePassword = () => {
    const [newPassword, setNewPassword] = React.useState(
        {
            password: '',
            confirmPassword: ''
        }
    );

    function handleChange(e) {
        setNewPassword(
            {
                ...newPassword,
                [e.target.name]: e.target.value,
            }
        );
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.put('/Users/change-password', newPassword).then(
            res => {
                if (res.status === 200) {
                    sendNotification();
                    window.location.href = '/#/app/användare/användarlista';
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Lösenord ändrat!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Ändra ditt lösenord
                            </Typography>
                            <TextField
                                id="passwordField"
                                label="Nytt lösenord"
                                onChange={handleChange}
                                name="password"
                                value={newPassword.password}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in ditt nya lösenord"
                                type={'password'}
                            />
                            <TextField
                                id="confirmPasswordField"
                                label="Bekräfta nytt lösenord"
                                onChange={handleChange}
                                name="confirmPassword"
                                value={newPassword.confirmPassword}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in ditt nya lösenord igen för att bekräfta"
                                type={'password'}
                            />
                            <Typography
                                variant={'h5'}
                                style={{ marginBottom: 30, fontSize: "16px" }}
                            >
                                Lösenordet måste uppfylla följande krav:
                                <ul>
                                    <li>Minst 6 tecken</li>
                                    <li>Minst en liten bokstav ('a' - 'z')</li>
                                    <li>Minst en stor bokstav ('A' - 'Z')</li>
                                    <li>Minst en siffra ('0' - '9')</li>
                                    <li>Minst ett tecken som inte är bokstav eller siffra (Ex. '!' eller '#')</li>
                                </ul>
                            </Typography>
                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Ändra lösenord
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default ChangePassword
