import tinycolor from "tinycolor2";

const primary = "#17a506";
const secondary = "#f19100";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const green = "#17A506";
const orange = "#F19100";
const red = "#E40613";
const grey = "#757575";

const lightenRate = 7.5;
const darkenRate = 15;

export default {
    palette: {
        green: {
            main: green,
            light: tinycolor(green)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(green)
                .darken(darkenRate)
                .toHexString()
        },
        orange: {
            main: orange,
            light: tinycolor(orange)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(orange)
                .darken(darkenRate)
                .toHexString()
        },
        red: {
            main: red,
            light: tinycolor(red)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(red)
                .darken(darkenRate)
                .toHexString()
        },
        grey: {
            main: grey,
            light: tinycolor(grey)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(grey)
                .darken(darkenRate)
                .toHexString()
        },
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString()
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: "#ccc"
        },
        warning: {
            main: warning,
            light: tinycolor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(warning)
                .darken(darkenRate)
                .toHexString()
        },
        success: {
            main: success,
            light: tinycolor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(success)
                .darken(darkenRate)
                .toHexString()
        },
        info: {
            main: info,
            light: tinycolor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(info)
                .darken(darkenRate)
                .toHexString()
        },
        inherit: {
            main: "inherit",
            light: tinycolor("inherit")
                .lighten("inherit")
                .toHexString(),
            dark: tinycolor("inherit")
                .darken("inherit")
                .toHexString()
        },
        text: {
            primary: "#4A4A4A",
            secondary: "#6E6E6E",
            hint: "#B9B9B9"
        },
        background: {
            default: "#F6F7FF",
            light: "#F3F5FF"
        }
    },
    customShadows: {
        widget:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetDark:
            "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetWide:
            "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
    }
};
