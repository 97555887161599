import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "../objecttables/styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { questionTableOptions } from './TableOptions';
import { Button } from '../../components/Wrappers'

import {
    Edit,
    Delete,
    MoreVert,
    ArrowUpward,
    ArrowDownward
} from '@material-ui/icons';
import { Typography } from "../../components/Wrappers/Wrappers";

export default function QuestionGroupTable() {
    const [questionColumns, setQuestionColumns] = useState();
    const [questionList, setQuestionList] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [editOrderMode, setEditOrderMode] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const [questionGroupName, setQuestionGroupName] = useState("");
    const [questionGroupDescription, setQuestionGroupDescription] = useState("");
    const [questionGroupId, setQuestionGroupId] = useState("");
    const classes = useStyles();
    const muiTable = useRef(null);

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const newQuestionGroupId = urlParams.get('checklistaID');
            setQuestionGroupId(newQuestionGroupId);

            if (newQuestionGroupId) {
                axios.get("/FormQuestion/getall/" + newQuestionGroupId).then(
                    res => {
                        if (res.status === 200) {
                            setQuestionList(res.data);

                            axios.get("/FormQuestionGroup/" + newQuestionGroupId).then(
                                res => {
                                    if (res.status === 200) {
                                        setQuestionGroupName(res.data.name);
                                        setQuestionGroupDescription(res.data.description);
                                    }
                                }
                            ).catch(err => {
                                console.log('err');
                                console.log(err);
                            })
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }

        setQuestionColumns(
            [
                {
                    name: "",
                    options: {
                        filter: false,
                        viewColumns: false
                    }
                },
                {
                    name: "Namn",
                    options: {
                        filter: false,
                        display: true,
                    }
                },
                {
                    name: "Beskrivning",
                    options: {
                        filter: false,
                        display: true,
                    }
                }
            ]
        );
    }, []);

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function sendNotification(text) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function GetMoveBtns(templateId) {
        return (
            <>
                <div style={{ textAlign: "center" }}>
                    <ArrowUpward id={"menuBtn" + templateId} className={classes.arrow} onClick={() => MoveQuestionUp(templateId)} />
                </div>
                <div style={{ textAlign: "center" }}>
                    <ArrowDownward id={"menuBtn" + templateId} className={classes.arrow} onClick={() => MoveQuestionDown(templateId)} />
                </div>
            </>
        )
    }

    function MoveQuestionUp(questionId) {
        let questionIndex = questionList.findIndex(q => q.id == questionId);

        if (questionIndex > 0) {
            let newQuestionList = [...questionList];
            let tempQuestion = questionList[questionIndex - 1];
            newQuestionList[questionIndex - 1] = newQuestionList[questionIndex];
            newQuestionList[questionIndex] = tempQuestion;
            setQuestionList(newQuestionList);
        }
    }

    function MoveQuestionDown(questionId) {
        let questionIndex = questionList.findIndex(q => q.id == questionId);
        if (questionIndex < questionList.length - 1) {
            let newQuestionList = [...questionList];
            let tempQuestion = questionList[questionIndex + 1];
            newQuestionList[questionIndex + 1] = newQuestionList[questionIndex];
            newQuestionList[questionIndex] = tempQuestion;
            setQuestionList(newQuestionList);
        }
    }

    function GetBtns(templateId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(templateId)}>
                <MoreVert id={"menuBtn" + templateId} />
            </div>
        )
    }

    function editBtnClick(e, questionId) {
        if (questionGroupId) {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/frågor/redigera?frageID=' + questionId + '&checklistaID=' + questionGroupId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/frågor/redigera?frageID=' + questionId + '&checklistaID=' + questionGroupId,
                    '_blank'
                );
            }
        }
    }

    function onColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...questionColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = true;
        } else if (action === "remove") {
            changingColumn.options.display = "false"
        }

        localStorage.setItem('objectColumns', JSON.stringify(tempObjectColumns));
    }

    function editGroupBtn(e) {
        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/checklistor/redigera?checklistaID=' + questionGroupId;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/checklistor/redigera?checklistaID=' + questionGroupId,
                '_blank'
            );
        }
    }

    function createBtnClick(e) {
        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = window.location.href = '/#/app/frågor/ny?checklistaID=' + questionGroupId;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                window.location.href = '/#/app/frågor/ny?checklistaID=' + questionGroupId,
                '_blank'
            );
        }
    }

    function updateOrderBtnClick() {
        let sortOrder = [];

        for (var i = 0; i < questionList.length; i++) {
            sortOrder.push({
                questionID: questionList[i].id,
                sortOrder: i
            });
        }

        axios.put("/FormQuestion/updateSortOrder", sortOrder).then(
            res => {
                if (res.status === 200) {
                    setEditOrderMode(false);
                    sendNotification("Ordning ändrad!");
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function undoOrderBtnClick(questionId) {
        axios.get("/FormQuestion/getall/" + questionGroupId).then(
            res => {
                if (res.status === 200) {
                    setQuestionList(res.data);
                    setEditOrderMode(false);
                    sendNotification("Ordning ångrad!");
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function delBtnClick(questionId) {
        if (window.confirm("Är du säker på att du vill ta bort frågan?")) {
            axios.delete("/FormQuestion/" + questionId).then(
                res => {
                    if (res.status === 200) {
                        setQuestionList(questionList.filter(question => question.id != questionId));
                        sendNotification("Fråga borttagen!");
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> Redigera
                    </MenuItem>

                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> Ta bort
                    </MenuItem>
                </Menu>}
                <Grid item xs={12}>
                    {editOrderMode ? <>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={updateOrderBtnClick}
                            style={{ marginBottom: 28, marginRight: "20px" }}
                        >
                            Verkställ ordning
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={undoOrderBtnClick}
                            style={{ marginBottom: 28, marginRight: "20px" }}
                        >
                            Tillbaka
                        </Button>
                        <Typography>Ändra ordningen på frågorna genom att klicka på pilarna</Typography>
                    </>
                        :
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={createBtnClick}
                                style={{ marginBottom: 28, marginRight: "20px" }}
                            >
                                Skapa ny fråga till gruppen
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={editGroupBtn}
                                style={{ marginBottom: 28, marginRight: "20px" }}
                            >
                                Ändra namn och beskrivning på grupp
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setEditOrderMode(true)}
                                style={{ marginBottom: 28 }}
                            >
                                Ändra ordning på frågor
                            </Button></>}

                    {(questionList && questionList.length > 0) && (
                        <>
                            <MUIDataTable
                                title={"Checklista: " + questionGroupName}
                                data={
                                    questionList.map(
                                        question => {
                                            return [
                                                editOrderMode ? GetMoveBtns(question.id) : GetBtns(question.id),
                                                question.questionType === "header" ? <strong>{question.text}</strong> : question.text,
                                                question.questionType === "header" ? <strong>{question.description}</strong> : question.description,
                                            ]
                                        }
                                    )
                                }
                                columns={questionColumns}
                                options={questionTableOptions(onColumnViewChange)}
                                key={questionList}
                                ref={muiTable}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

