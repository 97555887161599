import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const EditFacility = () => {
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [newFacility, setNewFacility] = React.useState({
        name: '',
        city: '',
        customerID: 0
    });

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);

                setNewFacility({
                    ...newFacility,
                    customerID: currentUser.customerID
                });
            }
        }

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const facilityId = urlParams.get('anläggningsId');

            axios.get('/facilities/' + facilityId).then(
                res => {
                    if (res.status === 200) {
                        setNewFacility(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })

            axios.get("/customers/getall").then(
                res => {
                    if (res.status === 200) {
                        setCustomers(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }, []);

    function handleChange(e) {
        switch (e.target.name) {
            case 'facilityName':
                setNewFacility({
                    ...newFacility,
                    ["name"]: e.target.value,
                });
                break;

            case 'city':
                setNewFacility({
                    ...newFacility,
                    ["city"]: e.target.value,
                });
                break;

            case 'customerId':
                axios.get("/customers/" + e.target.value).then(
                    res => {
                        if (res.status === 200) {
                            setNewFacility({
                                ...newFacility,
                                ["customer"]: res.data
                            });
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })

                console.log(newFacility);
                break;

            default:
                break;
        }
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.put('/facilities/update/' + newFacility.id, {
            name: newFacility.name,
            city: newFacility.city,
            customerID: newFacility.customer.id
        }).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = "/#/app/anläggningar/anläggningslista"
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Anläggning ändrad!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Redigera anläggning
                            </Typography>
                            <TextField
                                id="nameField"
                                label="Namn"
                                onChange={handleChange}
                                name="facilityName"
                                value={newFacility.name || ""}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in anläggningens nya namn"
                            />
                            <TextField
                                id="cityField"
                                label="Ort"
                                onChange={handleChange}
                                name="city"
                                value={newFacility.city || ""}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in anläggningens nya ort"
                            />
                            {isAdmin && <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Kund</InputLabel>
                                <Select
                                    name="customerId"
                                    value={newFacility.customer ? newFacility.customer.id : "none"}
                                    onChange={handleChange}
                                    label="Kund"
                                >
                                    <MenuItem value="none">
                                        Ingen kund vald
                                    </MenuItem>
                                    {
                                        customers.map(
                                            customer => {
                                                return (
                                                    <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </FormControl>}

                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                                style={{ marginBottom: 35 }}
                            >
                            </FormControl>
                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Spara ändringar
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default EditFacility
