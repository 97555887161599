import React, { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Edit, Delete, Check, Clear } from '@material-ui/icons';

const styles = (theme) => ({
    todoItemWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0px',
        margin: '0 0 0 0',
    },
    todoNewRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0px',
        borderBottom: `1px solid rgba(185, 185, 185, 0.3)`,
        margin: '0 24px',
        '&:hover': {
            color: '#17a506',
            cursor: 'pointer'
        }
    },
    todoRowEditing: {
        width: '100%'
    },
    todoItemHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0px 8px 44px',
        borderBottom: `1px solid rgba(185, 185, 185, 0.3)`,
        margin: '0 24px',
        fontSize: '16px',
        color: "#17a506"
    },
    itemIsDone: {
        color: theme.palette.text.hint,
        textDecoration: 'line-through',
        'div > div': {
            background: '#9B9B9B !important'
        }
    },
    taskText: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        paddingLeft: 8,
        borderBottom: `1px solid rgba(185, 185, 185, 0.3)`,
        '& p': {
            margin: 0,
            fontSize: 15,
            fontWeight: theme.typography.fontWeightMedium,
            maxWidth: 400,
            overflow: 'hidden',
            lineBreak: 'anywhere',
            whiteSpace: 'break-spaces'
        }
    },
    time: {
        fontSize: 10,
        fontWeight: 500,
    },
    menuItem: {
        color: theme.palette.text.hint,
    },
    tabIconsEditContainer: {
        marginLeft: "4px"
    },
    tabIconsContainer: {
        marginLeft: "6px"
    },
    tabIcon: {
        color: "#17a506",
        cursor: "pointer"
    }
})

const ToDoItem = ({ tabOnly, tabIndex, rowIndex, classes, title, backgroundColor, header, editRow, removeRow, rowModalOpenNow }) => {
    const [editing, setEditing] = useState(false);
    const [editValue, setEditValue] = useState(() => title);
    const fieldRef = useRef(null);

    const stopEditing = (save) => {
        if (save) {
            if (tabOnly) {
                editRow(tabIndex, editValue)
            } else {
                editRow(tabIndex, rowIndex, editValue);
            }
        } else {
            setEditValue(() => title);
        }

        setEditing(false);
    }

    if (header) {
        return (
            <div className={`${classes.todoItemHeader}`} style={{ backgroundColor: backgroundColor }}>
                <span style={{ fontWeight: "bold" }}>{title}</span>
            </div>
        )
    }

    return (
        <div className={`${classes.todoItemWrap}`} style={{ backgroundColor: backgroundColor }}>
            <div className={classes.taskText}>
                <textarea ref={fieldRef} style={{ display: editing ? "block" : "none" }} className={classes.todoRowEditing} value={editValue} onChange={e => setEditValue(e.target.value)} />
                <p style={{ display: editing ? "none" : "block" }}>{title}</p>
            </div>
            <div>
                {editing ? <div className={classes.tabIconsEditContainer}>
                    <Check className={classes.tabIcon} onClick={() => { stopEditing(true) }} />
                    <Clear className={classes.tabIcon} onClick={() => { stopEditing(false) }} />
                </div> : <div className={classes.tabIconsContainer}>
                    <Edit className={classes.tabIcon} onClick={() => { rowModalOpenNow() }} />
                    {/*<Delete className={classes.tabIcon} onClick={() => { removeRow(tabIndex, rowIndex) }} />*/}
                </div>}
            </div>
        </div>
    )
}

export default withStyles(styles)(ToDoItem);