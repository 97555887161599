import { makeStyles } from '@material-ui/styles'
import tinycolor from "tinycolor2";

export default makeStyles(theme => ({
    objectDataContainer: {
        width: "100%",
    },
    threeDotsButton: {
        display: "inline-flex",
        '@media (max-width: 960px)': {
            display: "none"
        }
    },
    mobileMenuButton: {
        display: "none",
        height: "20px",
        marginBottom: "4px",
        color: "#17a506",
        '@media (max-width: 960px)': {
            display: "inline-flex"
        }
    },
    topButtonContainer: {
        '@media (max-width: 960px)': {
            justifyContent: "center",
            margin: "0 auto"
        },
    },
    commentContainer: {
        //overflowY: "auto",
        //maxHeight: "150px",
        marginBottom: "30px",
        whiteSpace: "pre-wrap"
    },
    commentRow: {
        borderStyle: "groove",
        borderWidth: "1px",
        padding: "20px 14px 6px 14px",
        position: "relative"
    },
    commentName: {
        fontSize: "0.7rem",
        position: "absolute",
        top: "0px",
        left: "20px"
    },
    commentDate: {
        fontSize: "0.7rem",
        position: "absolute",
        top: "0px",
        right: "30px"
    },
    commentX: {
        color: "red",
        position: "absolute",
        top: "20px",
        right: "10px",
        cursor: "pointer"
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modalImage: {
        maxWidth: "80vw",
        maxHeight: "80vh"
    },
    imgContainer: {
        maxWidth: "200px",
        position: "relative",
        display: "inline-block",
        '&:hover .image': {
            opacity: 0.3
        },
        '&:hover .middle': {
            opacity: 1
        }
    },
    imgMiddle: {
        transition: ".5s ease",
        opacity: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    imgText: {
        backgroundColor: "#04AA6D",
        color: "white",
        fontSize: "16px",
        padding: "16px 32px",
    },
    imgCross: {
        color: "red",
        position: "absolute",
        right: "5px",
        top: "0",
        fontSize: "24px",
        cursor: "pointer"
    },
    thumbnail: {
        maxWidth: "200px",
        maxHeight: "180px",
        margin: "0 3px",
        opacity: 1,
        display: "block",
        width: "auto",
        height: "auto",
        transition: ".5s ease",
        backfaceVisibility: "hidden",
    },
    TextIcons: {
        display: "inline",
        verticalAlign: "middle",
        color: "#94c57e"
    },
    noBreakWhenPrint: {
        breakInside: "avoid"
    },
    questionContainer: {
        maxHeight: "186px",
        overflowY: "scroll",
        marginBottom: 30
    },
    center: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    docBtn: {
        marginRight: "20px",
        marginTop: "10px",
        width: "188px"
    },
    removeGridSettings: {
        flexBasis: "auto"
    },
    mainImage: {
        width: "auto",
        maxWidth: "100%",
        maxHeight: "380px",
        margin: "0 auto",
        display: "block",
    },
    imgContainer: {
        position: "relative",
        display: "block",
        margin: "0 auto",
        '&:hover .image': {
            opacity: 0.3
        },
        '&:hover .middle': {
            opacity: 1
        }
    },
    imgMiddle: {
        transition: ".5s ease",
        opacity: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    noTopOrBottomPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    splicedFields: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    splicedField: {
        width: '100%',
        marginBottom: 35
    },
    splicedFieldDistancer: {
        width: '10%'
    },
    checkbox: {
        'Mui-checked': {
            color: '#17a506'
        },
        'MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#17a506'
        }
    },
    icon: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& .MuiSvgIcon-root': {
            marginRight: 5,
        },
    },
    stepCompleted: {
        root: {
            color: 'green'
        }
    },
    layoutContainer: {
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
        border: "1px dashed",
        borderColor: theme.palette.primary.main,
        position: "relative"
    },
    layoutText: {
        color: tinycolor(theme.palette.background.light)
            .darken()
            .toHexString()
    },
    layoutButtonsRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    layoutButton: {
        backgroundColor: theme.palette.background.light,
        width: 125,
        height: 50,
        outline: "none",
        border: "none"
    },
    layoutButtonActive: {
        backgroundColor: tinycolor(theme.palette.background.light)
            .darken()
            .toHexString()
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: theme.spacing(2)
    },
    notificationCallButton: {
        color: "white",
        marginBottom: theme.spacing(1),
        textTransform: "none"
    },
    codeContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2)
    },
    codeComponent: {
        flexGrow: 1
    },
    notificationItem: {
        marginTop: theme.spacing(2)
    },
    notificationCloseButton: {
        position: "absolute",
        right: theme.spacing(2)
    },
    toastsContainer: {
        width: "auto",
        marginTop: theme.spacing(6),
        right: 0
    },
    progress: {
        visibility: "hidden"
    },
    notification: {
        display: "flex",
        alignItems: "center",
        background: "transparent",
        boxShadow: "none",
        overflow: "visible"
    },
    searchIcon: {
        color: "rgba(0, 0, 0, 0.23)"
    },
    imgWrap: {
        height: 100,
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: '0 0 10px 0px #ccc',
        marginRight: 20,
        marginBottom: 20,
        maxWidth: 125,
        position: 'relative'
    },
    docWrap: {
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: '0 0 10px 0px #ccc',
        maxWidth: 125,
        position: 'relative',
        marginRight: '10px'
    },
    docMargin: {
        marginRight: '10px'
    },
    delBtnWrap: {
        display: 'table'
    },
    galleryWrap: {
        display: 'flex'
    },
    uploadLabel: {
        backgroundColor: tinycolor(theme.palette.primary.main),
        color: '#f4f4f4',
        maxWidth: 220,
        display: 'inline-block',
        borderRadius: 8,
        textAlign: 'center',
        padding: '8px 12px',
        marginBottom: '20px'
    },
    deleteImageX: {
        fontSize: 20,
        cursor: 'pointer',
        lineHeight: 0,
        color: "#E40613"
    },
    deleteImageTrashcan: {
        fontSize: 20,
        cursor: 'pointer',
        lineHeight: 0,
        color: "#E40613",
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    docImg: {
        fontSize: 20,
        lineHeight: 0,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    verticalAlignCell: {
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    headerGreen: {
        backgroundColor: "#94c57e",
        borderRadius: "20px"
    },
    headerYellow: {
        backgroundColor: "#ea953c",
        borderRadius: "20px"
    },
    headerRed: {
        backgroundColor: "#e15e5e",
        borderRadius: "20px"
    },
    checkGreen: {
        color: "#17a506"
    },
    checkOrange: {
        color: "orange"
    },
    checkRed: {
        color: "red"
    },
    GridImage: {
        maxWidth: "100%",
        maxHeight: "200px",
        display: "inline",
        verticalAlign: "middle"
    },
    QuestionUnderline: {
        borderTopStyle: "dashed",
        borderTopWidth: "1px"
    },
    NewQuestionRow: {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        backgroundColor: "#ffffff",
        padding: "0px 16px",
        minHeight: "48px",
        alignItems: "center",
        display: "flex",
        textAlign: "center"
    },
    TextIcons: {
        display: "inline",
        verticalAlign: "middle",
        color: "#94c57e"
    },
    InlineCenter: {
        verticalAlign: "middle", display: "inline"
    },
    Buttons: {
        backgroundColor: "#17a506", color: "white", marginTop: "6px"
    },
    SignLine: {
        height: "40px", width: "600px", borderBottomStyle: "dotted"
    },
    SignLineSolid: {
        height: "40px", width: "600px", borderBottomStyle: "solid"
    },
    IntroImage: {
        maxWidth: "500px", maxHeight: "500px"
    },
    TableLeft: {
        width: "500px", fontWeight: "bold", padding: "2px"
    },
    TableRight: {
        width: "500px"
    },
    isInactiveCheckboxText: {
        fontSize: "16px",
        lineHeight: '42px'
    },
    noPaddingInPrint: {
    },
    '@media print': {
        heading: {
            fontSize: "14px"
        },
        noTopOrBottomPadding: {
            maxWidth: "1240px"
        },
        isInactiveCheckboxText: {
            fontSize: "16px",
            lineHeight: '42px'
        },
        onlyPrint: {
            visibility: "visible!important",
        },
        noPrint: {
            visibility: "hidden",
            display: "none"
        },
        noPaddingInPrint: {
            padding: "0!important"
        },
        hideAllElseInPrint: {
            visibility: "visible",
            position: "absolute",
            left: 0,
            top: 0
        },
        mainImage: {
            maxHeight: "160px"
        },
        TableLeft: {
            padding: "0px",
            fontSize: "12px",
            width: "400px"
        },
        TableRight: {
            padding: "0px",
            fontSize: "12px",
            width: "400px"
        },
        disableWidgetBoxShadow: {
            "& .MuiPaper-root": {
                boxShadow: "none"
            }
        },
        imgMiddle: {
            display: "none"
        },
        imgContainer: {
            '&:hover .image': {
                opacity: 1
            },
            '&:hover .middle': {
                opacity: 1
            }
        },
    },
    '@media screen': {
        noPrint: {
            visibility: "visible!important"
        },
        onlyPrint: {
            visibility: "hidden!important",
            display: "none!important"
        },
    },
}))
