import React from 'react';
import {
    Home as HomeIcon,
    FilterNone as UIElementsIcon,
    BorderAll as TableIcon,
    Business,
    LibraryBooks as LibraryIcon,
    HelpOutline as FAQIcon,
    BarChart as ChartIcon,
    Map as MapIcon,
    Apps as CoreIcon,
    Description as DescriptionIcon,
    ShoppingCart as ShoppingCartIcon,
    StarBorder as ExtraIcon,
    Chat as ChatIcon,
    Add as AddSectionIcon,
    FolderOpen as FolderIcon,
    Description as DocumentationIcon,
    Person as PersonIcon,
    AccountCircle as ProfileIcon,
    Build,
    PermMedia,
    Speed,
    QuestionAnswer,
    FormatListBulleted
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const structure = [
    { id: 0, label: 'Hem', link: '/app/dashboard', icon: <HomeIcon /> },
    { id: 1, type: 'divider' },
    {
        id: 2,
        label: 'Kunder',
        link: '/app/kunder/kundlista',
        badgeColor: 'secondary',
        icon: <PersonIcon />,
        adminOnly: true,
        children: [
            {
                label: 'Kundlista',
                link: '/app/kunder/kundlista',
            },
            {
                label: 'Ny kund',
                link: '/app/kunder/ny',
                adminOnly: true
            }
        ],
    },
    {
        id: 3,
        label: 'Anläggningar',
        link: '/app/anläggningar/anläggningslista',
        badgeColor: 'secondary',
        icon: <Business />,
        children: [
            {
                label: 'Anläggningslista',
                link: '/app/anläggningar/anläggningslista',
            },
            {
                label: 'Ny anläggning',
                link: '/app/anläggningar/ny',
                permissionNeeded: "11",
            },
        ],
    },
    {
        id: 4,
        label: 'Objekttyper',
        link: '/app/objekttyper/objekttyplista',
        badgeColor: 'secondary',
        icon: <Build />,
        adminOnly: true,
        children: [
            {
                label: 'Lista på objekttyper',
                link: '/app/objekttyper/objekttyplista',
            },
            {
                label: 'Ny objekttyp',
                link: '/app/objekttyper/ny',
                adminOnly: true
            }
        ],
    },
    {
        id: 5,
        label: 'Objekt',
        link: '/app/objekt/forteckning',
        badgeColor: 'secondary',
        icon: <Speed />,
        adminOnly: false,
        children: [
            {
                label: 'Förteckning',
                link: '/app/objekt/forteckning',
            },
            {
                label: 'Nytt objekt',
                link: '/app/objekt/nytt',
                permissionNeeded: "41",
            }
        ],
    },
    //{
    //    id: 6,
    //    label: 'Dokumenttyper',
    //    link: '/app/user',
    //    badgeColor: 'secondary',
    //    icon: <LibraryIcon />,
    //    children: [
    //        {
    //            label: 'Lista på dokumenttyper',
    //            link: '/app/dokumenttyper/dokumenttyplista',
    //        },
    //        {
    //            label: 'Ny dokumenttyp',
    //            link: '/app/user/add',
    //        }
    //    ],
    //},
    {
        id: 6,
        label: 'Åtgärdspunkter',
        link: '/app/åtgärdspunkter/åtgärdslista',
        badgeColor: 'secondary',
        icon: <FormatListBulleted />,
        adminOnly: false,
        children: [
            {
                label: 'Lista på åtgärdspunkter',
                link: '/app/åtgärdspunkter/åtgärdslista',
            }
        ],
    },
    {
        id: 7,
        label: 'Checklistor',
        link: '/app/checklistor/lista',
        badgeColor: 'secondary',
        icon: <QuestionAnswer />,
        adminOnly: false,
        children: [
            {
                label: 'Lista på checklistor',
                link: '/app/checklistor/lista',
            },
            {
                label: 'Ny checklista',
                link: '/app/checklistor/ny',
                permissionNeeded: "61",
            }
        ],
    },
    { id: 8, type: 'divider' },
    {
        id: 9,
        label: 'Användare',
        link: '/app/användare/användarlista',
        badgeColor: 'secondary',
        icon: <PersonIcon />,
        adminOnly: true,
        children: [
            {
                label: 'Användarlista',
                link: '/app/användare/användarlista',
            },
            {
                label: 'Ny användare',
                link: '/app/användare/ny',
                adminOnly: true
            }
        ],
    }
]

function AddSection() {
    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '50%',
            height: 30,
            width: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
        },
    }))

    const classes = useStyles()

    return (
        <section className={classes.root}>
            <AddSectionIcon />
        </section>
    )
}

function Chat() {
    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            height: 45,
            width: 45,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
        },
    }))

    const classes = useStyles()

    return (
        <>
            <section className={classes.root}>
                <ChatIcon />
            </section>
        </>
    )
}

export default structure
