import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import { tableOptions } from './TableOptions';
import axios from 'axios';
import {
    PermIdentity,
    Work,
    Edit,
    Delete
} from '@material-ui/icons'

const textLabels = {
    pagination: {
        rowsPerPage: "Rader per sida"
    }
};

function GetBtns() {
    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Edit />
            <Delete />
        </div>
    )
}

export default function ObjectTypeTable() {
    const [documentTypes, setDocumentTypes] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        setDocumentTypes([
            { name: "Kontrollintyg" },
            { name: "Kontrollrapporter" },
            { name: "Avvikelserapporter" },
            { name: "Flödesschema" },
            { name: "Driftinstruktioner & av- & påställning" },
            { name: "Riskbedömning" },
            { name: "Fortlöpande tillsyn" },
            { name: "Livslängdsjournal" },
            { name: "Historik (kronologiskt)" },
            { name: "Dokumentation från tillverkare" },
            { name: "Övrigt" }
        ]);
    }, []);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    {documentTypes && (
                        <>
                            <MUIDataTable
                                title="Lista över dokumenttyper"
                                data={documentTypes.map(documentType => {
                                    return [
                                        documentType.name,
                                        GetBtns()
                                    ]
                                })}
                                columns={["Namn", ""]}
                                options={tableOptions(null)}
                            />
                        </>
                    )}
                </Grid>
                {/* <Grid item xs={12}>
          <Widget
            title="Material-UI Table"
            noBodyPadding
            bodyClass={classes.tableWrapper}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
            </Grid>
        </>
    );
}
