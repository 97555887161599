import axios from 'axios';
import cfg from '../config'
import jwt from "jsonwebtoken";
import { signOut } from '../context/UserContext'

export function configureInterceptors(dispatch) {
    axios.interceptors.request.use(
        async (config) => {
            const token = localStorage.getItem("token");
            config.headers = {
                'Authorization': `Bearer ${token != null ? token : ""}`,
                'Content-Type': 'application/json'
            };
            config.baseURL = cfg.baseURLApi;

            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalConfig = error.config;
            if (error.response.status === 401 && !originalConfig._retry && originalConfig.url !== "/auth/refresh") {
                //to avoid infinite loop if request fails again
                originalConfig._retry = true;
                const newTokens = await refreshAccessToken();
                if(newTokens !== null) {
                    originalConfig.headers["Authorization"] = `Bearer ${newTokens?.accessToken != null ? newTokens.accessToken : ""}`;
                    return axios(originalConfig);
                }
                else {
                    return Promise.reject(error);
                }
            }

            return Promise.reject(error);
        }
    );

    async function refreshAccessToken() {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");

        const response = await axios.post('/auth/refresh', JSON.stringify({ AccessToken: token != null ? token : "", RefreshToken: refreshToken != null ? refreshToken : "" }), { headers: { 'Content-Type': "application/json" } })
            .catch(() => {
                signOut(dispatch, null);
                return null;
            });

        if (response?.data) {
            let user = jwt.decode(response.data.accessToken);

            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("user_id", user.id);
            return response.data;
        }

        return null;
    }
}