import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Add, Check, Clear } from '@material-ui/icons';

const styles = (theme) => ({
    todoItemWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0px',
        borderBottom: `1px solid rgba(185, 185, 185, 0.3)`,
        margin: '0 24px',
    },
    todoNewRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0px',
        margin: '0 0 0 0',
        '&:hover': {
            color: '#17a506',
        }
    },
    todoNewRowEditing: {
        width: "80%"
    },
    todoItemHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0px 8px 44px',
        borderBottom: `1px solid rgba(185, 185, 185, 0.3)`,
        margin: '0 24px',
        fontSize: '16px',
        color: "#17a506"
    },
    itemIsDone: {
        color: theme.palette.text.hint,
        textDecoration: 'line-through',
        'div > div': {
            background: '#9B9B9B !important'
        }
    },
    taskText: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        paddingLeft: 8,
        '& p': {
            margin: 0,
            fontSize: 15,
            fontWeight: theme.typography.fontWeightMedium,
            maxWidth: 400,
            overflow: 'hidden',
        }
    },
    time: {
        fontSize: 10,
        fontWeight: 500,
    },
    menuItem: {
        color: theme.palette.text.hint,
    }
})

const NewsTasksItemAdd = ({ tabOnly, classes, backgroundColor, tabIndex, rowModalOpenNow }) => {
    return (
        <div className={`${classes.todoNewRow}`} style={{ backgroundColor: backgroundColor }}>
            <div style={{ cursor: "pointer" }} className={classes.taskText} onClick={() => tabOnly ? rowModalOpenNow(tabIndex, -3) : rowModalOpenNow(tabIndex, -2)}>
                <Add style={{ color: "#17a506", position: "relative", bottom: "2px" }} />
                {tabOnly ? <p style={{ marginLeft: "6px" }}>Lägg till ny tabb</p> : <p style={{ marginLeft: "6px" }}>Lägg till ny rad</p>}
            </div>
        </div>
    )
}

export default withStyles(styles)(NewsTasksItemAdd);