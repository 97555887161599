import React from 'react';

const ProjectIcon = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.317243" fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#FF5992"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667H10.6667C11.0349 16.6667 11.3333 16.9651 11.3333 17.3333C11.3333 17.7015 11.0349 18 10.6667 18H10C8.89543 18 8 17.1046 8 16V10C8 8.89543 8.89543 8 10 8H16C17.1046 8 18 8.89543 18 10V10.6667C18 11.0349 17.7015 11.3333 17.3333 11.3333C16.9651 11.3333 16.6667 11.0349 16.6667 10.6667V10C16.6667 9.63181 16.3682 9.33333 16 9.33333H10C9.63181 9.33333 9.33333 9.63181 9.33333 10V16C9.33333 16.3682 9.63181 16.6667 10 16.6667ZM14.6667 12.6667H20.6667C21.7712 12.6667 22.6667 13.5621 22.6667 14.6667V20.6667C22.6667 21.7712 21.7712 22.6667 20.6667 22.6667H14.6667C13.5621 22.6667 12.6667 21.7712 12.6667 20.6667V14.6667C12.6667 13.5621 13.5621 12.6667 14.6667 12.6667ZM14.6667 14C14.2985 14 14 14.2985 14 14.6667V20.6667C14 21.0349 14.2985 21.3333 14.6667 21.3333H20.6667C21.0349 21.3333 21.3333 21.0349 21.3333 20.6667V14.6667C21.3333 14.2985 21.0349 14 20.6667 14H14.6667Z" fill="#FF5992"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="15" height="15">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667H10.6667C11.0349 16.6667 11.3333 16.9651 11.3333 17.3333C11.3333 17.7015 11.0349 18 10.6667 18H10C8.89543 18 8 17.1046 8 16V10C8 8.89543 8.89543 8 10 8H16C17.1046 8 18 8.89543 18 10V10.6667C18 11.0349 17.7015 11.3333 17.3333 11.3333C16.9651 11.3333 16.6667 11.0349 16.6667 10.6667V10C16.6667 9.63181 16.3682 9.33333 16 9.33333H10C9.63181 9.33333 9.33333 9.63181 9.33333 10V16C9.33333 16.3682 9.63181 16.6667 10 16.6667ZM14.6667 12.6667H20.6667C21.7712 12.6667 22.6667 13.5621 22.6667 14.6667V20.6667C22.6667 21.7712 21.7712 22.6667 20.6667 22.6667H14.6667C13.5621 22.6667 12.6667 21.7712 12.6667 20.6667V14.6667C12.6667 13.5621 13.5621 12.6667 14.6667 12.6667ZM14.6667 14C14.2985 14 14 14.2985 14 14.6667V20.6667C14 21.0349 14.2985 21.3333 14.6667 21.3333H20.6667C21.0349 21.3333 21.3333 21.0349 21.3333 20.6667V14.6667C21.3333 14.2985 21.0349 14 20.6667 14H14.6667Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
    )
}

export default ProjectIcon;