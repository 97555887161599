import React from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import { mockUser } from "./mock";

//config
import config from "../../src/config";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                ...action.payload
            };
        case "REGISTER_REQUEST":
        case "RESET_REQUEST":
        case "PASSWORD_RESET_EMAIL_REQUEST":
            return {
                ...state,
                isFetching: true,
                errorMessage: '',
            };
        case "SIGN_OUT_SUCCESS":
            return { ...state };
        case "AUTH_INIT_ERROR":
            return Object.assign({}, state, {
                currentUser: null,
                loadingInit: false,
            });
        case "REGISTER_SUCCESS":
        case "RESET_SUCCESS":
        case "PASSWORD_RESET_EMAIL_SUCCESS":
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
            });
        case 'AUTH_FAILURE':
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload,
            });
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    var [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: () => {
            const token = localStorage.getItem("token")
            if (config.isBackend && token) {
                const date = new Date().getTime() / 1000;
                const data = jwt.decode(token);
                if (!data) return false;
                return date < data.exp;
            } else if (token) {
                return true
            }
            return false;
        },
        isFetching: false,
        errorMessage: '',
        currentUser: null,
        loadingInit: true,
    });

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
    }
    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(
    dispatch,
    login,
    password,
    history,
    setIsLoading,
    setError,
) {
    setError(false);
    setIsLoading(true);
    // We check if app runs with backend mode
    if (!config.isBackend) {
        setTimeout(() => {
            setError(null);
            doInit()(dispatch);
            setIsLoading(false);
            receiveToken("token", dispatch);
        }, 2000);
    } else {
        if (login.length > 0 && password.length > 0) {
            axios.post("/auth/login", JSON.stringify({ email: login, password }), { headers: { 'Content-Type': "application/json" } })
                .then(res => {
                    const tokens = res.data;
                    setError(null);
                    setIsLoading(false);
                    receiveToken(tokens.accessToken, tokens.refreshToken, dispatch);
                    doInit()(dispatch);
                })
                .catch(() => {
                    setError(true);
                    setIsLoading(false);
                });
        } else {
            dispatch({ type: "LOGIN_FAILURE" });
        }
    }
}

export function sendPasswordResetEmail(email) {
    return (dispatch) => {
        if (!config.isBackend) {
            return
        } else {
            dispatch({
                type: 'PASSWORD_RESET_EMAIL_REQUEST',
            });
            axios.post("/auth/send-password-reset-email", { email }).then(res => {
                dispatch({
                    type: 'PASSWORD_RESET_EMAIL_SUCCESS',
                });
                toast.success("Email with resetting instructions has been sent");
            }).catch(err => {
                dispatch(authError(err.response.data));
            })
        }
    }
}

function signOut(dispatch, history) {
    localStorage.removeItem("token");
    localStorage.removeItem('refreshToken')
    localStorage.removeItem("user");
    localStorage.removeItem('user_id');
    sessionStorage.removeItem('dataStorage');
    document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    axios.defaults.headers.common["Authorization"] = "";
    dispatch({ type: "SIGN_OUT_SUCCESS" });

    if (history !== null)
        history.push("/login");
}

export function receiveToken(token, refreshToken, dispatch) {
    let user;

    // We check if app runs with backend mode
    if (config.isBackend) {
        user = jwt.decode(token);
    } else {
        user = {
            email: config.auth.email
        };
    }

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("theme", "default");
    sessionStorage.setItem("user_id", user.id);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    dispatch({ type: "LOGIN_SUCCESS" });
}

async function findMe() {
    if (config.isBackend) {
        const response = await axios.get('/auth', { headers: { 'Content-Type': "application/json" } });
        return response.data;
    } else {
        return mockUser;
    }
}

async function refreshAccessToken(dispatch) {
    if (!config.isBackend) {
        return "token";
    } else {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        if (token && refreshToken) {
            await axios.post('/auth/refresh', JSON.stringify({ AccessToken: token, RefreshToken: refreshToken }), { headers: { 'Content-Type': "application/json" } })
                .then(res => {
                    const tokens = res.data;
                    receiveToken(tokens.accessToken, tokens.refreshToken, dispatch);
                    doInit()(dispatch);
                    return tokens;
                })
                .catch((e) => {
                    console.log(e);
                    signOut(dispatch, null);
                    return null;
                });
        }
    }
}

export function authError(payload) {
    return {
        type: 'AUTH_FAILURE',
        payload,
    };
}

export function doInit() {
    return async (dispatch) => {
        let currentUser = null;
        if (!config.isBackend) {
            currentUser = mockUser;

            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: {
                    currentUser,
                },
            });
        } else {
            try {
                let token = localStorage.getItem('token');
                if (token) {
                    currentUser = await findMe();
                    sessionStorage.setItem('user_id', currentUser.id);
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                }
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: {
                        currentUser,
                    },
                });
            } catch (error) {
                console.log(error);

                dispatch({
                    type: 'AUTH_INIT_ERROR',
                    payload: error,
                });
            }
        }
    }
}

export async function loadUser(dispatch) {
    try {
        let token = localStorage.getItem("token");

        if(token) {
            let data = jwt.decode(token);
            let date = new Date().getTime() / 1000;
            if(data) {
                if(data.exp < date) {
                    await refreshAccessToken(dispatch);
                } else {
                    let currentUser = await findMe();

                    dispatch({
                        type: "LOGIN_SUCCESS",
                        payload: { currentUser }
                    })
                }
            } else {
                signOut(dispatch, null);
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export function registerUser(
    dispatch,
    login,
    password,
    history,
    setIsLoading,
    setError,
    social = ""
) {
    return () => {
        if (!config.isBackend) {
            history.push('/login');
        } else {
            dispatch({
                type: 'REGISTER_REQUEST',
            });
            if (login.length > 0 && password.length > 0) {
                axios.post("/auth/signup", { email: login, password }).then(res => {
                    dispatch({
                        type: 'REGISTER_SUCCESS'
                    });
                    toast.success("You've been registered successfully. Please check your email for verification link");
                    history.push('/login');
                }).catch(err => {
                    dispatch(authError(err.response.data));
                })

            } else {
                dispatch(authError('Something was wrong. Try again'));
            }
        }
    };
}

export function verifyEmail(token, history) {
    return (dispatch) => {
        if (!config.isBackend) {
            history.push('/login');
        } else {
            axios.put("/auth/verify-email", { token }).then(verified => {
                if (verified) {
                    toast.success("Your email was verified");
                }
            }).catch(err => {
                toast.error(err.response.data);
            }).finally(() => {
                history.push('/login');
            })
        }
    }
}

export function resetPassword(token, password, history) {
    return (dispatch) => {
        if (!config.isBackend) {
            history.push('/login');
        } else {
            dispatch({
                type: 'RESET_REQUEST',
            });
            axios.put("/auth/password-reset", { token, password }).then(res => {
                dispatch({
                    type: 'RESET_SUCCESS',
                });
                toast.success("Password has been updated");
                history.push('/login');
            }).catch(err => {
                dispatch(authError(err.response.data));
            })
        }
    }
}