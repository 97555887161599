import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const AddObjectType = () => {
    const [newObjectType, setNewObjectType] = React.useState({
        name: '',
        mainTypeID: 1
    });

    function handleChange(e) {
        if (e.target.name === 'name') {
            setNewObjectType({
                ...newObjectType,
                name: e.target.value,
            });
        } else {
            setNewObjectType({
                ...newObjectType,
                mainTypeID: e.target.value,
            });
        }
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.post('/ObjectTypes/create', newObjectType).then(res => {
            if (res.status === 201) {
                sendNotification();
                window.location.href = '/#/app/objekttyper/objekttyplista';
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekttyp tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa ny objekttyp
                            </Typography>
                            <TextField
                                id="nameField"
                                label="Namn"
                                onChange={handleChange}
                                name="name"
                                value={newObjectType.name || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in objekttypens namn"
                            />
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Objekttyp</InputLabel>
                                <Select
                                    labelId="simple-select-label"
                                    id="simple-select-label"
                                    name="mainType"
                                    value={(newObjectType.mainTypeID) ? newObjectType.mainTypeID : '-1'}
                                    onChange={handleChange}
                                    style={{ marginBottom: 35 }}
                                    label="Objekttyp"
                                >
                                    <MenuItem key="1" value="1">Trycksatta anordningar</MenuItem>
                                    <MenuItem key="2" value="2">Lyft</MenuItem>
                                </Select>
                            </FormControl>

                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Skapa objekttyp
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddObjectType
