import React, { useEffect, useState } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { toast } from "react-toastify";
import { tableOptions } from './TableOptions';
import {
    Edit,
    Delete,
    MoreVert
} from '@material-ui/icons'

export default function ObjectTypeTable() {
    const [objectTypes, setObjectTypes] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [canCreateObjectTypes, setCanCreateObjectTypes] = useState(true); // Permission level removed
    const [menuPlacement, setMenuPlacement] = useState();
    const classes = useStyles();

    function GetBtns(objectId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(objectId)}>
                <MoreVert id={"menuBtn" + objectId} />
            </div>
        )
    }

    function delBtnClick(objectTypeId) {
        if (!canCreateObjectTypes) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort objekttypen?")) {
                axios.delete("/ObjectTypes/delete/" + objectTypeId).then(
                    res => {
                        if (res.status === 200) {
                            setObjectTypes(objectTypes.filter(objectType => objectType.id !== objectTypeId));
                            sendNotification("Objekttyp borttagen!", true);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function editBtnClick(e, objectTypeId) {
        if (!canCreateObjectTypes) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/objekttyper/redigera?objektTypId=' + objectTypeId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/objekttyper/redigera?objektTypId=' + objectTypeId,
                    '_blank'
                );
            }
        }
    }

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    useEffect(() => {
        /*let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "31") > -1) {
                    setCanCreateObjectTypes(true);
                } else {
                    setCanCreateObjectTypes(false);
                }
            }
        }*/

        axios.get("/ObjectTypes/getall").then(
            res => {
                if (res.status === 200) {
                    console.log(['Objecttypess getall result', res]);
                    setObjectTypes(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }, []);

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekttyp borttagen!",
            variant: "contained",
            color: "success",
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> <span style={!canCreateObjectTypes ? { textDecoration: "line-through" } : {}}>Redigera</span>
                    </MenuItem>

                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> <span style={!canCreateObjectTypes ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                    </MenuItem>
                </Menu>}
                <Grid item xs={12}>
                    {objectTypes && (
                        <>
                            <MUIDataTable
                                title="Lista över objekttyper"
                                data={
                                    objectTypes.map(
                                        (objectType, index) => {
                                            return [
                                                GetBtns(objectType.id, index),
                                                objectType.name,
                                                objectType.mainType.name
                                            ]
                                        }
                                    )
                                }
                                columns={
                                    [
                                        {
                                            name: "",
                                            options: {
                                                filter: false,
                                                viewColumns: false,
                                                display: true
                                            }
                                        },
                                        {
                                            name: "Namn",
                                            options: {
                                                filter: false
                                            }
                                        },
                                        "Kategori"
                                    ]
                                }
                                options={tableOptions(null)}
                            />
                        </>
                    )}
                </Grid>
                {/* <Grid item xs={12}>
          <Widget
            title="Material-UI Table"
            noBodyPadding
            bodyClass={classes.tableWrapper}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
            </Grid>
        </>
    );
}
