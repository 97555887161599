const hostApi = process.env.NODE_ENV === "development"
    ? "https://localhost"
    : "https://checksoft.se";
const portApi = process.env.NODE_ENV === "development" ? 5001 : "";
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/api`;
const redirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://checksoft.se";

export default {
  hostApi,
  portApi,
  baseURLApi,
  redirectUrl,
    remote: "https://checksoft.se",
  isBackend: process.env.REACT_APP_BACKEND,
  auth: {
    email: "admin@flatlogic.com",
    password: "password"
  }
};
