let data = JSON.parse(sessionStorage.getItem('dataStorage'));
export const currentDate = new Date();

export function getDataInList(dataCategory) {
    let category = data[dataCategory];
    let newArray = [];
    for (var i = 0; i < category.length; i++) {
        newArray.push(category[i].listDisplayName);
    }

    return newArray;
}

export function findInObjectData(dataObjectGroupName, searchValue, propertyToReturn) {
    if (searchValue === null) {
        return null;
    }

    if (!data) {
        data = JSON.parse(sessionStorage.getItem('dataStorage'));
    }

    if (typeof (dataObjectGroupName) === 'undefined' || typeof (searchValue) === 'undefined') {
        console.log(['findInObjectData undefined! dataObjectGroupName, searchvalue, propertyToReturn:', dataObjectGroupName, searchValue, propertyToReturn]);
        return null;
    }

    try {
        let newSearchValue;

        if (typeof searchValue === "string") {
            newSearchValue = parseInt(searchValue);
        } else if (typeof searchValue === "number") {
            newSearchValue = searchValue;
        } else {
            console.log(searchValue);
            console.log(typeof searchValue);
            throw new Error("Value not string or int")
        }

        let objectFromData = data[dataObjectGroupName].find(obj => obj.value === newSearchValue);

        if (typeof (objectFromData) === 'undefined') {
            return null;
        }

        if (!propertyToReturn) {
            return objectFromData;
        }

        return objectFromData[propertyToReturn];
    } catch (e) {
        console.log(e);
        console.log(['findInObjectData error: dataObjectGroupName, searchvalue:', dataObjectGroupName, searchValue]);
    }

    return null;
    //objectData.classifications.find(obj => obj.value === object.controlClass).name
}

export function cleanseDateStringFromT(dateString) {
    if (dateString) {
        if (dateString === "0001-01-01T00:00:00") {
            return "";
        }

        return dateString.split('T')[0];
    } else {
        return dateString;
    }
}

export function getProperCurrentDate() {
    const currentDate = new Date();
    let finalDateString = currentDate.getFullYear() + "-";

    let month = currentDate.getMonth();

    if (month < 9) {
        month = "0" + (month + 1);
    } else {
        month = "" + (month + 1)
    }

    finalDateString += month + "-";

    let day = currentDate.getDate().toString();

    if (day.length === 1) {
        day = "0" + (day);
    }

    finalDateString += day;

    return finalDateString;
}

export function getProperCurrentDateAddMonths(monthsToAdd, useLastOfMonth) {
    let usedDate;

    if (useLastOfMonth) {
        usedDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + monthsToAdd + 1, 0);
    } else {
        usedDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + monthsToAdd, currentDate.getDate());
    }

    let finalDateString = usedDate.getFullYear() + "-";

    let month = usedDate.getMonth();

    if (month < 9) {
        month = "0" + (month + 1);
    } else {
        month = "" + (month + 1)
    }

    finalDateString += month + "-";

    let day = usedDate.getDate().toString();

    if (day.length === 1) {
        day = "0" + (day);
    }

    finalDateString += day;

    console.log(finalDateString);

    return finalDateString;
}