import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    Menu,
    MenuItem,
    CircularProgress,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { toast } from "react-toastify";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import { measureTableOptions, tableOptions } from '../tables/TableOptions';
import { cleanseDateStringFromT } from '../../helpers/storedDataHelper';
import {
    Publish,
    Delete,
    GetApp,
    MoreVert,
    Assignment,
    Speed,
    Edit
} from '@material-ui/icons'
import Widget from "../../components/Widget/Widget";
import missingImage from '../../images/missingImage.png';
import miniLogo from "../../images/CheckABminiopaque.png";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ActionModal from "../../components/ActionModal";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import checkedImage from '../../images/CheckMark.png';
import checkedImageYellow from '../../images/CheckMarkOrange.png';

export default function ArchiveItem() {
    const currentDate = new Date();
    const [documents, setDocuments] = useState([]);
    const [objectId, setObjectId] = useState([]);
    const [object, setObject] = useState(null);
    const [objectName, setObjectName] = useState([]);
    const [mainImage, setMainImage] = useState("");
    const [showImage, setShowImage] = useState(true);
    const [canAddDocuments, setCanAddDocuments] = useState(false);
    const [canCreateObjects, setCanCreateObjects] = useState(false);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [imageModalOpen, setImageModalOpen] = React.useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const [measureText, setMeasureText] = useState("");
    const [measureResponsible, setMeasureResponsible] = useState("");
    const [measureExpirationDate, setMeasureExpirationDate] = useState(getProperCurrentDate());
    const [measureStatus, setMeasureStatus] = useState(0);
    const [measureAllowProceed, setMeasureAllowProceed] = useState(false);
    const [showDocsNotMeasures, setShowDocsNotMeasures] = useState(true);
    const muiTable = useRef(null);
    const [measureColumns, setMeasureColumns] = useState();
    const [objNameFilter, setObjNameFilter] = useState([]);
    const [resolvedFilter, setResolvedFilter] = useState(["Ej åtgärdad", "Pågående"]);
    const [measureList, setMeasureList] = useState([]);
    const [canCreateAction, setCanCreateAction] = useState(false);
    const [measuresNotDone, setMeasuresNotDone] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        if (currentUser) {
            currentUser = JSON.parse(currentUser);
            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "71") > -1) { //See if user is allowed to create documents
                    setCanAddDocuments(true);
                } else {
                    setCanAddDocuments(false);
                }

                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "41") > -1) { //See if user is allowed to create documents
                    setCanCreateObjects(true);
                } else {
                    setCanCreateObjects(false);
                }
            }
        }

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const id = urlParams.get('objektID');
            setObjectId(id);

            axios.get("/objects/" + id).then(
                res => {
                    if (res.status === 200) {
                        setDocuments(res.data.documents);
                        setObjectName(res.data.objectName);
                        setObject(res.data);

                        axios.get("/Action/getallfromobject/" + id).then(
                            res2 => {
                                if (res.status === 200) {
                                    setMeasureList(res2.data);
                                    setResolvedFilter(['Ej åtgärdad', 'Pågående']);

                                    axios.get("/Action/getalldonefromobject/" + id + "?done=false").then(
                                        res3 => {
                                            if (res3.status === 200) {
                                                if (res3.data.length > 0) {
                                                    setMeasuresNotDone(true);
                                                } else {
                                                    setMeasuresNotDone(false);
                                                }
                                            }
                                        }
                                    ).catch(err => {
                                        console.log('err');
                                        console.log(err);
                                    });
                                }
                            }
                        ).catch(err => {
                            console.log('err');
                            console.log(err);
                        });

                        if (res.data.images.length > 0) {
                            setMainImage("/downloadImage/" + res.data.images[0].id);
                            setShowImage(true);
                        }
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }, []);

    useEffect(() => {
        if (measureText) {
            setMeasureAllowProceed(true);
        } else {
            setMeasureAllowProceed(false);
        }
    }, [measureText])

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "51") > -1) {
                    setCanCreateAction(true);
                } else {
                    setCanCreateAction(false);
                }
            }
        }

        setMeasureColumns(
            [
                {
                    name: "",
                    options: {
                        filter: false,
                        viewColumns: false
                    }
                },
                {
                    name: "Åtgärdat",
                    options: {
                        display: "true",
                        filterList: resolvedFilter,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value === "Åtgärdad") {
                                return <img style={{ width: "32px" }} src={checkedImage} />
                            } else if (value === "Pågående") {
                                return <img style={{ width: "32px" }} src={checkedImageYellow} />
                            } else {
                                return <span></span>
                            }
                        },
                    }
                },
                {
                    name: "Åtgärdstext",
                    options: {
                        filter: false,
                        display: "true",
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>;
                        },
                    }
                },
                {
                    name: "Skapad",
                    options: {
                        filter: false,
                        display: "true",
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{cleanseDateStringFromT(value)}</div>
                        },
                    }
                },
                {
                    name: "Ändrad",
                    options: {
                        filter: false,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{cleanseDateStringFromT(value)}</div>
                        },
                    }
                },
                {
                    name: "Objektnamn",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
                {
                    name: "Objektnummer",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
            ]
        );
    }, [resolvedFilter]);

    const imageModalOpenNow = () => {
        setImageModalOpen(true);
    };

    const imageModalCloseNow = () => {
        setImageModalOpen(false);
    };

    const actionModalOpenNow = () => {
        setActionModalOpen(true);
        setShowDocsNotMeasures(false);
    };

    const actionModalCloseNow = (save) => {
        if (save) {
            setMeasureText("");
            setMeasureExpirationDate(getProperCurrentDate());
            setMeasureResponsible("");
            setMeasureStatus(0);

            axios.get("/Action/getallfromobject" + objectId).then(
                res => {
                    if (res.status === 200) {
                        setMeasureList(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });
        }
    };

    const getMuiTheme = () => createMuiTheme({
        palette: {
            primary: {
                main: "#17a506"
            }
        },
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    '@media (max-width: 960px)': {
                        padding: "2px 0px 0px 4px",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }
                }
            },
            MUIDataTableFilterList: {
                root: {
                    '@media (max-width: 960px)': {
                        margin: "0px 16px 10px 16px"
                    }
                }
            }
        }
    })

    function getBtns(documentID) {
        return (
            <div style={{ cursor: "pointer" }} id={"menuBtn" + documentID} onClick={() => menuClick(documentID)}>
                <Edit className={classes.mobileMenuButton} />
                <MoreVert className={classes.threeDotsButton} />
            </div>
        )
    }

    function getBtnsMeasure(documentID) {
        return (
            <div style={{ cursor: "pointer" }} id={"menuBtnMeasure" + documentID} onClick={() => menuClick(documentID)}>
                <Edit className={classes.mobileMenuButton} />
                <MoreVert className={classes.threeDotsButton} />
            </div>
        )
    }

    function getStatusText(statusInt) {
        if (statusInt === 2) {
            return "Åtgärdad";
        } else if (statusInt === 1) {
            return "Pågående";
        } else {
            return "Ej åtgärdad";
        }
    }

    function onFilterChange(changedColumn, filterList, type) {
        setResolvedFilter(filterList[1]);
        //setObjNrFilter(filterList[6]);
    }

    function onColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...measureColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = "true";
        } else if (action === "remove") {
            changingColumn.options.display = "false"
        }

        localStorage.setItem('objectColumns', JSON.stringify(tempObjectColumns));
    }

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function getProperCurrentDate() {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return finalDateString;
    }

    function downloadBtnClick(documentID) {
        axios({
            url: "/Documents/download/" + documentID,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const doc = object.documents.find(doc => doc.id === documentID);
            const fileExtension = doc.documentFileDto.fileName.split('.')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.documentName + "." + fileExtension);
            document.body.appendChild(link);
            link.click();
        });
    }

    function excelFunction() {
        let dateTime = new Date();
        let month = dateTime.getMonth().toString();

        if (month.length === 1) {
            month = "0" + month;
        }

        axios({
            url: "/Action/getallExcelFile",
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Åtgärdslista_" + dateTime.getFullYear().toString() + month + dateTime.getDate().toString() + ".xlsx");
            document.body.appendChild(link);
            link.click();
        });
    }

    function addDocBtn(e) {
        if (e.button === 0 && !e.ctrlKey) {
            window.location.replace('/#/app/dokument/nytt?objektID=' + objectId + "&Arkiv");
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/dokument/nytt?objektID=' + objectId + "&Arkiv",
                '_blank'
            );
        }
    }

    function editDocBtn(documentID) {
        if (!canAddDocuments) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            window.location.href = '/#/app/dokument/redigera?dokumentID=' + documentID + "&objektID=" + objectId + "&arkiv";
        }
    }

    function openObject(e, measureId) {
        let openObjectId = measureList.find(measure => measure.id === measureId).object.id;

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/dokument?objektID=' + openObjectId;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/dokument?objektID=' + openObjectId,
                '_blank'
            );
        }
    }

    function editBtnClick(e, measureId) {
        if (!canCreateAction) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/åtgärdspunkter/redigera?åtgärdsID=' + measureId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/åtgärdspunkter/redigera?åtgärdsID=' + measureId,
                    '_blank'
                );
            }
        }
    }

    function delBtnClick(measureId) {
        if (!canCreateAction) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort åtgärdspunkten?")) {
                axios.delete("/action/" + measureId).then(
                    res => {
                        if (res.status === 200) {
                            setMeasureList(measureList.filter(measure => measure.id != measureId));
                            sendDeletedNotification();
                            setShowMenu(false);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function deleteDocBtnClick(documentId) {
        if (!canAddDocuments) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort dokumentet?")) {
                axios.delete("/Documents/" + documentId).then(
                    res => {
                        if (res.status === 200) {
                            //setMenuPlacement(null);
                            setShowMenu(false);
                            let tempDoc = documents.filter(docToRemove => docToRemove.id !== documentId);
                            setDocuments(tempDoc);
                            sendDocDeletedNotification();
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function sendDuplicatedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekt duplicerat!",
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekt borttaget!",
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function sendDocDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Dokument borttaget!",
            variant: "contained",
            color: "success",
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function getFileName(fileString) {
        return fileString.split('.')[0];
    }

    function getFileExtension(fileString) {
        return fileString.split('.')[1];
    }

    function showTemplateBtnClick(docName, templateId, docTypeId) {
        window.location.href = '/#/app/mallar/mall?dokumentNamn=' + docName + '&objektID=' + object.id + '&formID=' + templateId + "&dokumentTypID=" + docTypeId + "&arkiv";
    }

    function getFileTypeText(document) {
        if (document.formID) {
            if (document.formDto.statusID === 3) {
                return <span style={{ color: "#17a506" }}>Mall</span>;
            } else if (document.formDto.statusID === 2) {
                return <span style={{ color: "#ffa500" }}>Mall</span>;
            } else {
                return <span>Mall</span>;
            }
        }

        if (document.documentFileDto) {
            return document.documentFileDto.fileName.split('.')[1].toUpperCase();
        }

        return "Fil saknas";
    }

    function getRiskText(riskInt) {
        switch (riskInt) {
            case "-1":
                return "Saknas"
            case "0":
                return "Ej godkänd än"
            case "1":
                return "Ja, finns"
            default:
                return;
        }
    }

    function getLLJText(riskInt) {
        switch (riskInt) {
            case "-1":
                return "Saknas"
            case "0":
                return "Nej, men finns"
            case "1":
                return "Ja, finns"
            default:
                return;
        }
    }

    function goToMeasurePoints() {
        window.location.href = '/#/app/åtgärdspunkter/åtgärdslista?ObjektNummer=' + object.objectNr;
    }

    function getMenuBtns() {
        if (showDocsNotMeasures) {
            let chosenDoc = documents.find(doc => doc.id === menuPlacement);
            if (showMenu && chosenDoc) {
                return (
                    <Menu
                        id="profile-menu"
                        open={true}
                        className={classes.headerMenu}
                        onClose={() => menuClick(null)}
                        classes={{ paper: classes.profileMenu }}
                        anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                        disableAutoFocusItem
                    >
                        {(chosenDoc.formID && chosenDoc.formDto.statusID === 1) &&
                            <MenuItem
                                onClick={() => showTemplateBtnClick(chosenDoc.documentName, chosenDoc.formID, chosenDoc.type.id)}
                            >
                                <Assignment className={classes.profileMenuIcon} /> <span style={!canAddDocuments ? { textDecoration: "line-through" } : {}}> Redigera </span>
                            </MenuItem>
                        }
                        {(chosenDoc.formID && chosenDoc.formDto.statusID === 3) &&
                            <MenuItem
                                onClick={() => showTemplateBtnClick(chosenDoc.documentName, chosenDoc.formID, chosenDoc.type.id)}
                            >
                                <Assignment className={classes.profileMenuIcon} /> Visa
                            </MenuItem>
                        }
                        {!chosenDoc.formID && <>
                            {chosenDoc.documentFileDto ?
                                <MenuItem
                                    onClick={() => downloadBtnClick(menuPlacement)}
                                >
                                    <GetApp className={classes.profileMenuIcon} /> Ladda ned fil
                                </MenuItem>
                                :
                                <>
                                    <MenuItem
                                        onClick={() => editDocBtn(menuPlacement)}
                                    >
                                        <Publish className={classes.profileMenuIcon} /> <span style={!canAddDocuments ? { textDecoration: "line-through" } : {}}> Ladda upp fil </span>
                                    </MenuItem>
                                </>
                            }
                        </>}

                        <MenuItem
                            onClick={() => deleteDocBtnClick(menuPlacement)}
                        >
                            <Delete className={classes.profileMenuIcon} /> <span style={!canAddDocuments ? { textDecoration: "line-through" } : {}}> Ta bort </span>
                        </MenuItem>
                    </Menu>
                )
            }
        } else {
            if (showMenu) {
                return (<Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtnMeasure" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> <span style={!canCreateAction ? { textDecoration: "line-through" } : {}}>Redigera</span>
                    </MenuItem>
                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> <span style={!canCreateAction ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                    </MenuItem>
                </Menu>)
            }
        }
    }

    if (!object) {
        return (<CircularProgress />);
    }

    return (
        <>
            <Grid container spacing={4} className={classes.hideAllElseInPrint}>
                {showMenu && getMenuBtns()}
                <Grid container item spacing={3} xs={12} style={{ paddingBottom: 0 }}>
                    <Grid container item xs={12} sm={6}>
                        <img
                            src={miniLogo}
                            alt={"Checksoft"}
                            style={{ width: "29px", height: "29px" }}
                        />
                        <Typography variant='h4'>
                            <strong><span>{objectName}</span></strong>
                        </Typography>
                        <br />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Widget disableWidgetMenu className={classes.disableWidgetBoxShadow}>
                        <Grid container spacing={3}>
                            <Grid item>
                                {
                                    showImage && <>
                                        {
                                            mainImage.length > 0 ?
                                                <div className={classes.imgContainer}>
                                                    <img className={classes.mainImage + " image"} src={mainImage} alt="Översiktsbild" style={{ margin: 0 }} />
                                                    <div className={classes.imgMiddle + " middle"}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ height: "50%" }}
                                                            onClick={() => imageModalOpenNow()}
                                                        >
                                                            Förstora
                                                        </Button>
                                                    </div>
                                                </div>
                                                :
                                                <img src={missingImage} className={classes.mainImage + " " + classes.noPrint} alt="Översiktsbild saknas" style={{ margin: 0 }} />
                                        }
                                    </>
                                }
                            </Grid>

                            <Grid container className={classes.noPrint + " " + classes.topButtonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={e => addDocBtn(e)}
                                    className={classes.docBtn}
                                >
                                    Lägg till dokument
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { actionModalOpenNow() }}
                                    className={classes.docBtn}
                                >
                                    Skapa åtgärd
                                </Button>
                                {showDocsNotMeasures &&
                                    <Button
                                        variant="contained"
                                        color={measuresNotDone ? "secondary" : "primary"}
                                        onClick={() => setShowDocsNotMeasures(!showDocsNotMeasures)}
                                        className={classes.docBtn}
                                    >
                                        Se åtgärdspunkter
                                    </Button>
                                }
                                {!showDocsNotMeasures &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setShowDocsNotMeasures(!showDocsNotMeasures)}
                                        className={classes.docBtn}
                                    >
                                        Se dokument
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
                <Grid item xs={12} className={classes.noPrint}>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        {documents && (
                            showDocsNotMeasures && (<MUIDataTable
                                title={objectName}
                                data={
                                    documents.map(
                                        document => {
                                            return [
                                                getBtns(document.id, document.documentFileDto != null),
                                                document.documentName,
                                                document.type.name,
                                                getFileTypeText(document),
                                                document.executiveDate ? cleanseDateStringFromT(document.executiveDate) : cleanseDateStringFromT(document.dateChanged),
                                                document.created
                                            ]
                                        }
                                    )
                                }
                                columns={
                                    [
                                        {
                                            name: "",
                                            options: {
                                                filter: false,
                                                viewColumns: false,
                                            }
                                        },
                                        {
                                            name: "Dokumentnamn"
                                        },
                                        {
                                            name: "Dokumenttyp"
                                        },
                                        {
                                            name: "Filtyp"
                                        },
                                        {
                                            name: "Utförningsdatum"
                                        },
                                        {
                                            name: "Dokument Skapat"
                                        }
                                    ]
                                }
                                options={tableOptions(null)}
                            />)
                        )}

                        {documents && !showDocsNotMeasures && (<MUIDataTable
                            title="Lista över åtgärdspunkter"
                            data={
                                measureList.map(
                                    measure => {
                                        return [
                                            getBtnsMeasure(measure.id),
                                            getStatusText(measure.status),
                                            measure.actionText,
                                            measure.created,
                                            measure.changed,
                                            measure.object ? measure.object.objectName : "",
                                            measure.object ? measure.object.objectNr : ""
                                        ]
                                    }
                                )
                            }
                            columns={measureColumns}
                            options={measureTableOptions(onColumnViewChange, onFilterChange, excelFunction, canCreateAction)}
                            key={measureList}
                            ref={muiTable}
                        />)}
                    </MuiThemeProvider>
                </Grid>

                <Modal //Enlarged image modal
                    className={classes.modalContent}
                    open={imageModalOpen}
                    onClose={() => imageModalCloseNow()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={imageModalOpen} onClick={() => imageModalCloseNow()}>
                        <div className={classes.modalPaper} style={{ height: "90vh" }}>
                            <img src={mainImage} style={{ height: "100%", display: "block", margin: "0 auto" }} />
                        </div>
                    </Fade>
                </Modal>
                <ActionModal
                    actionModalOpen={actionModalOpen}
                    actionModalCloseNow={actionModalCloseNow}
                    objectID={objectId}
                />
            </Grid>
        </>
    );
}