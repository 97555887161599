import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "../objecttables/styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { questionGroupTableOptions } from './TableOptions';
import { Button } from '../../components/Wrappers'

import {
    Edit,
    Delete,
    QuestionAnswer,
    MoreVert
} from '@material-ui/icons';

export default function QuestionGroupTable() {
    const [questionColumns, setQuestionColumns] = useState();
    const [questionGroupList, setQuestionGroupList] = useState([]);
    const [questionGroupId, setQuestionGroupId] = useState("");
    const [questionList, setQuestionList] = useState([]);
    const [canCreateQuestion, setCanCreateQuestion] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const classes = useStyles();
    const muiTable = useRef(null);

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "61") > -1) {
                    setCanCreateQuestion(true);
                } else {
                    setCanCreateQuestion(false);
                }
            }
        }

        setQuestionColumns(
            [
                {
                    name: "",
                    options: {
                        filter: false,
                        viewColumns: false
                    }
                },
                {
                    name: "Namn",
                    options: {
                        filter: false,
                        display: "true",
                    }
                },
                {
                    name: "Beskrivning",
                    options: {
                        filter: false,
                        display: "true",
                    }
                }
            ]
        );
    }, []);

    useEffect(() => {
        axios.get("/FormQuestionGroup/getall").then(
            res => {
                if (res.status === 200) {
                    setQuestionGroupList(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }, []);

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Extrafråga borttagen!",
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function GetBtns(templateId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(templateId)}>
                <MoreVert id={"menuBtn" + templateId} />
            </div>
        )

        //return (
        //    <div style={{ display: "flex", justifyContent: "space-between", width: "160px", float: "right" }}>
        //        <div style={{ cursor: "pointer" }} onClick={() => editBtnClick(objectId)}>
        //            <Edit />
        //        </div>
        //        <div style={{ cursor: "pointer" }} onClick={() => delBtnClick(objectId)}>
        //            <Delete />
        //        </div>
        //    </div>
        //)
    }

    //function GetDocumentBtn() {
    //    return (
    //        <div style={{ display: "flex", justifyContent: "space-between", width: "30px", float: "left" }}>
    //            <div>
    //                <Description />
    //            </div>
    //        </div>
    //    )
    //}

    function editBtnClick(e, questionGroupId) {
        if (!canCreateQuestion) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/frågor/lista?checklistaID=' + questionGroupId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/frågor/lista?checklistaID=' + questionGroupId,
                    '_blank'
                );
            }
        }
    }

    function onColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...questionColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = "true";
        } else if (action === "remove") {
            changingColumn.options.display = "false"
        }

        localStorage.setItem('objectColumns', JSON.stringify(tempObjectColumns));
    }

    function delBtnClick(questionId) {
        if (!canCreateQuestion) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort checklistan?")) {
                axios.delete("/FormQuestionGroup/" + questionId).then(
                    res => {
                        if (res.status === 200) {
                            setQuestionGroupList(questionGroupList.filter(question => question.id != questionId));
                            sendDeletedNotification();
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> <span style={!canCreateQuestion ? { textDecoration: "line-through" } : {}}>Redigera</span>
                    </MenuItem>

                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> <span style={!canCreateQuestion ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                    </MenuItem>
                </Menu>}
                <Grid item xs={12}>
                    {(questionGroupList && questionGroupList.length > 0) && (
                        <>
                            <MUIDataTable
                                title="Lista över checklistor"
                                data={questionGroupList.map(
                                    question => {
                                        return [
                                            GetBtns(question.id),
                                            question.name,
                                            question.description
                                        ]
                                    }
                                )
                                }
                                columns={questionColumns}
                                options={questionGroupTableOptions()}
                                key={questionGroupList}
                                ref={muiTable}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

