import { makeStyles } from '@material-ui/styles'
import tinycolor from "tinycolor2";

export default makeStyles(theme => ({
    measureText: {
        display: "inline-block",
        position: "relative",
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: "1280px",
        width: "100%"
    },
    imgContainer: {
        maxWidth: "200px",
        position: "relative",
        display: "inline-block",
        '&:hover .image': {
            opacity: 0.3
        },
        '&:hover .middle': {
            opacity: 1
        }
    },
    imgMiddle: {
        transition: ".5s ease",
        opacity: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    imgText: {
        backgroundColor: "#04AA6D",
        color: "white",
        fontSize: "16px",
        padding: "16px 32px",
    },
    imgCross: {
        color: "red",
        position: "absolute",
        right: "5px",
        top: "0",
        fontSize: "24px",
        cursor: "pointer"
    },
    thumbNail: {
        maxWidth: "200px",
        maxHeight: "180px",
        margin: "0 3px",

        opacity: 1,
        display: "block",
        width: "100%",
        height: "auto",
        transition: ".5s ease",
        backfaceVisibility: "hidden",
    },
    mainImage: {
        //maxWidth: "30vw",
        //maxHeight: "30vh"

        maxWidth: "100%",
        maxHeight: "400px"
    },
    noPointer: {
        cursor: "default",
        '&:hover:not(.Mui-disabled)': {
            cursor: "default"
        }
    },
    hideAccordionArrow: {
        "& .MuiAccordionSummary-expandIcon": {
            visibility: "hidden",
            display: "none"
        }
    },
    '@media print': {
        noPrint: {
            visibility: "hidden!important",
            display: "none!important"
        },
        onlyPrint: {
            visibility: "visible!important",
        },
        "body": {
            visibility: "hidden !important",
            overflow: "visible",
            display: "block"
        },
        hideAllElseInPrint: {
            visibility: "visible",
            position: "absolute",
            left: 0,
            top: 0,
        },
        hideAccordionArrowInPrint: {
            "& .MuiAccordionSummary-expandIcon": {
                visibility: "hidden",
                display: "none"
            }
        },
        disableWidgetBoxShadow: {
            "& .MuiPaper-root": {
                boxShadow: "none"
            }
        },
        fullHeightWhenPrint: {
            height: "29.7cm"
        },
        QuestionUnderline: {
            borderTopStyle: "none",
            borderTopWidth: "0px",
            borderBottomStyle: "none",
            borderBottomWidth: "0px"
        },
        noBreakWhenPrint: {
            breakInside: "avoid",
            boxShadow: "none" //Remove fake border when printing
        },
    },
    '@media screen': {
        noPrint: {
            visibility: "visible!important"
        },
        onlyPrint: {
            visibility: "hidden!important",
            display: "none!important"
        },
    },
    icon: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& .MuiSvgIcon-root': {
            marginRight: 5,
        },
    },
    stepCompleted: {
        root: {
            color: 'green'
        }
    },
    layoutContainer: {
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
        border: "1px dashed",
        borderColor: theme.palette.primary.main,
        position: "relative"
    },
    layoutText: {
        color: tinycolor(theme.palette.background.light)
            .darken()
            .toHexString()
    },
    layoutButtonsRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    layoutButton: {
        backgroundColor: theme.palette.background.light,
        width: 125,
        height: 50,
        outline: "none",
        border: "none"
    },
    layoutButtonActive: {
        backgroundColor: tinycolor(theme.palette.background.light)
            .darken()
            .toHexString()
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: theme.spacing(2)
    },
    notificationCallButton: {
        color: "white",
        marginBottom: theme.spacing(1),
        textTransform: "none"
    },
    codeContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2)
    },
    codeComponent: {
        flexGrow: 1
    },
    notificationItem: {
        marginTop: theme.spacing(2)
    },
    notificationCloseButton: {
        position: "absolute",
        right: theme.spacing(2)
    },
    toastsContainer: {
        width: "auto",
        marginTop: theme.spacing(6),
        right: 0
    },
    progress: {
        visibility: "hidden"
    },
    notification: {
        display: "flex",
        alignItems: "center",
        background: "transparent",
        boxShadow: "none",
        overflow: "visible"
    },
    searchIcon: {
        color: "rgba(0, 0, 0, 0.23)"
    },
    imgWrap: {
        height: 100,
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: '0 0 10px 0px #ccc',
        marginRight: 20,
        marginBottom: 20,
        maxWidth: 125,
        position: 'relative'
    },
    galleryWrap: {
        display: 'flex'
    },
    uploadLabel: {
        backgroundColor: tinycolor(theme.palette.primary.main),
        color: '#f4f4f4',
        maxWidth: 220,
        display: 'inline-block',
        borderRadius: 8,
        textAlign: 'center',
        padding: '8px 12px',
        margin: '20px 0'
    },
    textDescriptionLabel: {
        backgroundColor: tinycolor(theme.palette.primary.main),
        color: '#f4f4f4',
        maxWidth: 220,
        display: 'inline-block',
        borderRadius: 8,
        textAlign: 'center',
        padding: '8px 12px',
        margin: "5px 0px"
    },
    deleteImageX: {
        fontSize: 20,
        position: 'absolute',
        top: 5,
        left: 5,
        cursor: 'pointer',
        lineHeight: .5
    },
    collapsableHeader: {

    },
    headerGreen: {
        backgroundColor: "#94c57e",
        borderRadius: "20px"
    },
    headerYellow: {
        backgroundColor: "#ea953c",
        borderRadius: "20px"
    },
    headerRed: {
        backgroundColor: "#e15e5e",
        borderRadius: "20px"
    },
    checkGreen: {
        color: "#17a506"
    },
    checkOrange: {
        color: "orange"
    },
    checkRed: {
        color: "red"
    },
    GridImage: {
        maxWidth: "100%",
        maxHeight: "200px",
        display: "inline",
        verticalAlign: "middle"
    },
    QuestionUnderline: {
        borderTopStyle: "dashed",
        borderTopWidth: "1px"
    },
    NewQuestionRow: {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        backgroundColor: "#ffffff",
        padding: "0px 16px",
        minHeight: "48px",
        alignItems: "center",
        display: "flex",
        textAlign: "center"
    },
    TextIcons: {
        display: "inline",
        verticalAlign: "middle",
        color: "#94c57e"
    },
    InlineCenter: {
        verticalAlign: "middle", display: "inline"
    },
    Buttons: {
        backgroundColor: "#17a506", color: "white", marginTop: "6px"
    },
    SignLine: {
        height: "40px", width: "600px", borderBottomStyle: "dotted"
    },
    SignLineSolid: {
        height: "40px", width: "600px", borderBottomStyle: "solid"
    },
    IntroImage: {
        maxWidth: "500px", maxHeight: "500px"
    },
    TableLeft: {
        width: "200px", fontWeight: "bold", padding: "1px"
    },
    TableRight: {
        width: "600px"
    },
    tableCellRed: {
        backgroundColor: "red",
        fontWeight: "bold",
        color: "black",
        borderWidth: "5px",
        borderStyle: "solid",
        borderColor: "white",
        padding: "6px 16px 6px 16px",
        textAlign: "center",
        width: "62px",
        "&:hover": {
            boxShadow: "inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5)"
        }
    },
    tableCellYellow: {
        backgroundColor: "#ffff00",
        fontWeight: "bold",
        color: "black",
        borderWidth: "5px",
        borderStyle: "solid",
        borderColor: "white",
        padding: "6px 16px 6px 16px",
        textAlign: "center",
        width: "62px",
        "&:hover": {
            boxShadow: "inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5)"
        }
    },
    tableCellGreen: {
        backgroundColor: "#17a506",
        fontWeight: "bold",
        color: "black",
        borderWidth: "5px",
        borderStyle: "solid",
        borderColor: "white",
        padding: "6px 16px 6px 16px",
        textAlign: "center",
        width: "62px",
        "&:hover": {
            boxShadow: "inset 0.2em 0.2em 0.2em 0 rgba(255,255,255,0.5), inset -0.2em -0.2em 0.2em 0 rgba(0,0,0,0.5)"
        }
    },
    tableHead: {
        fontWeight: "bold",
        color: "black",
        borderWidth: "5px",
        borderStyle: "solid",
        borderColor: "white",
        padding: "6px 16px 6px 16px",
        textAlign: "center",
        width: "62px"
    },
    modalImage: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "1024px",
        marginBottom: "24px"
    }
}))
