import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const AddSection = () => {
    const [newSection, setNewSection] = React.useState({
        name: '',
        facilityID: 0
    });

    const [selectedFacilityName, setSelectedFacilityName] = useState("");

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const facilityId = parseInt(urlParams.get('anläggningsID'));
            const facilityName = urlParams.get('anläggningsnamn');

            setSelectedFacilityName(facilityName);
            setNewSection({ ...newSection, facilityID: facilityId });
        }
    }, []);

    function handleChange(e) {
        setNewSection({
            ...newSection,
            [e.target.name]: e.target.value,
        });
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.post("/Sections/create/", newSection)
            .then(
                res => {
                    if (res.status === 200) {
                        console.log('Sections/create');
                        console.log(res.data);

                        sendNotification();
                        window.location.href = '/#/app/avdelningar/avdelningslista?anläggningsID=' + newSection.facilityID + "&anläggningsnamn=" + selectedFacilityName;
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Avdelning tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                {"Skapa ny avdelning åt " + selectedFacilityName}
                            </Typography>
                            <TextField
                                id="name"
                                label="Namn"
                                onChange={handleChange}
                                name="name"
                                value={newSection.name || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in avdelningens namn"
                            />
                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Skapa avdelning
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddSection
