import React from 'react';

const DocIcon = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.317243" fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#3CD4A0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3333 7.6665C16.5144 7.6665 16.6787 7.73873 16.7988 7.85594C16.9229 7.97702 17 8.1461 17 8.33317V9.94265L18.7239 11.6665H20.3333C20.7015 11.6665 21 11.965 21 12.3332V20.3332C21 21.4377 20.1046 22.3332 19 22.3332H11C9.89543 22.3332 9 21.4377 9 20.3332V9.6665C9 8.56193 9.89543 7.6665 11 7.6665H16.3333ZM11 8.99984H15.6667L19.6667 12.9998V20.3332C19.6667 20.7014 19.3682 20.9998 19 20.9998H11C10.6318 20.9998 10.3333 20.7014 10.3333 20.3332V9.6665C10.3333 9.29831 10.6318 8.99984 11 8.99984ZM12.3333 14.9998H17.6667C18.0349 14.9998 18.3333 15.2983 18.3333 15.6665C18.3333 16.0347 18.0349 16.3332 17.6667 16.3332H12.3333C11.9651 16.3332 11.6667 16.0347 11.6667 15.6665C11.6667 15.2983 11.9651 14.9998 12.3333 14.9998ZM17.6667 17.6665H12.3333C11.9651 17.6665 11.6667 17.965 11.6667 18.3332C11.6667 18.7014 11.9651 18.9998 12.3333 18.9998H17.6667C18.0349 18.9998 18.3333 18.7014 18.3333 18.3332C18.3333 17.965 18.0349 17.6665 17.6667 17.6665ZM12.3333 12.3332H13.6667C14.0349 12.3332 14.3333 12.6316 14.3333 12.9998C14.3333 13.368 14.0349 13.6665 13.6667 13.6665H12.3333C11.9651 13.6665 11.6667 13.368 11.6667 12.9998C11.6667 12.6316 11.9651 12.3332 12.3333 12.3332Z" fill="#14B17B"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="7" width="12" height="16">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3333 7.6665C16.5144 7.6665 16.6787 7.73873 16.7988 7.85594C16.9229 7.97702 17 8.1461 17 8.33317V9.94265L18.7239 11.6665H20.3333C20.7015 11.6665 21 11.965 21 12.3332V20.3332C21 21.4377 20.1046 22.3332 19 22.3332H11C9.89543 22.3332 9 21.4377 9 20.3332V9.6665C9 8.56193 9.89543 7.6665 11 7.6665H16.3333ZM11 8.99984H15.6667L19.6667 12.9998V20.3332C19.6667 20.7014 19.3682 20.9998 19 20.9998H11C10.6318 20.9998 10.3333 20.7014 10.3333 20.3332V9.6665C10.3333 9.29831 10.6318 8.99984 11 8.99984ZM12.3333 14.9998H17.6667C18.0349 14.9998 18.3333 15.2983 18.3333 15.6665C18.3333 16.0347 18.0349 16.3332 17.6667 16.3332H12.3333C11.9651 16.3332 11.6667 16.0347 11.6667 15.6665C11.6667 15.2983 11.9651 14.9998 12.3333 14.9998ZM17.6667 17.6665H12.3333C11.9651 17.6665 11.6667 17.965 11.6667 18.3332C11.6667 18.7014 11.9651 18.9998 12.3333 18.9998H17.6667C18.0349 18.9998 18.3333 18.7014 18.3333 18.3332C18.3333 17.965 18.0349 17.6665 17.6667 17.6665ZM12.3333 12.3332H13.6667C14.0349 12.3332 14.3333 12.6316 14.3333 12.9998C14.3333 13.368 14.0349 13.6665 13.6667 13.6665H12.3333C11.9651 13.6665 11.6667 13.368 11.6667 12.9998C11.6667 12.6316 11.9651 12.3332 12.3333 12.3332Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
    )
}

export default DocIcon;