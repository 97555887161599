import { makeStyles } from "@material-ui/styles";
import tinycolor from "tinycolor2";

export default makeStyles(theme => ({
    imgContainer: {
        maxWidth: "200px",
        position: "relative",
        display: "inline-block",
        '&:hover .image': {
            opacity: 0.3
        },
        '&:hover .middle': {
            opacity: 1
        }
    },
    imgMiddle: {
        transition: ".5s ease",
        opacity: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    imgText: {
        backgroundColor: "#04AA6D",
        color: "white",
        fontSize: "16px",
        padding: "16px 32px",
    },
    imgCross: {
        color: "red",
        position: "absolute",
        right: "5px",
        top: "0",
        fontSize: "24px",
        cursor: "pointer"
    },
    thumbnail: {
        maxWidth: "200px",
        maxHeight: "180px",
        margin: "0 3px",
        opacity: 1,
        display: "block",
        width: "auto",
        height: "auto",
        transition: ".5s ease",
        backfaceVisibility: "hidden",
    },
    splicedFields: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    splicedField: {
        width: '100%',
        marginBottom: 35
    },
    splicedFieldDistancer: {
        width: '10%'
    },
    galleryWrap: {
        display: 'flex'
    },
    uploadLabel: {
        backgroundColor: tinycolor(theme.palette.primary.main),
        color: '#f4f4f4',
        maxWidth: 220,
        display: 'inline-block',
        borderRadius: 8,
        textAlign: 'center',
        padding: '8px 12px',
        marginBottom: '20px'
    },
    deleteImageX: {
        fontSize: 20,
        cursor: 'pointer',
        lineHeight: 0,
        color: "#E40613"
    },
    deleteImageTrashcan: {
        fontSize: 20,
        cursor: 'pointer',
        lineHeight: 0,
        color: "#E40613",
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    TextIcons: {
        display: "inline",
        verticalAlign: "middle",
        color: "#94c57e"
      },
    columnContainer: {
        maxWidth: "50%",
        '@media (max-width: 600px)': {
            maxWidth: "100%"
        },
    },
    questionContainer: {
        height: "106px",
        overflowY: "scroll",
        marginBottom: 30
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 540px)': {
            alignItems: 'inherit',
        },
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modalImage: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "1024px",
        marginBottom: "24px"
    },
    signButton: {
        textAlign: "center",
    },
    dashTable: {
        width: "100%"
    },
    dashRightCell: {
        textAlign: "right",
    },
    dashButton: {
        height: "26px"
    },
    dashTextSquare: {
        paddingLeft: "16px"
    },
    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column"
    },
    progressSection: {
        marginBottom: theme.spacing(1)
    },
    progressTitle: {
        marginBottom: theme.spacing(2)
    },
    progress: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'rgb(236, 236, 236)',
    },
    pieChartLegendWrapper: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        marginRight: theme.spacing(1)
    },
    legendItemContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        justifyContent: "space-between",
        width: "100%"
    },
    fullHeightBody: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between"
    },
    alignStandaloneElement: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center"
    },
    tableWidget: {
        overflowX: "auto"
    },
    progressBar: {
        backgroundColor: theme.palette.warning.main
    },
    progressBarPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    progressBarWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    performanceLegendWrapper: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    legendElement: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(2)
    },
    legendElementText: {
        marginLeft: theme.spacing(1)
    },
    serverOverviewElement: {
        display: "flex",
        alignItems: "center",
        maxWidth: "100%"
    },
    serverOverviewElementText: {
        minWidth: 149,
        paddingRight: theme.spacing(2)
    },
    serverOverviewElementChartWrapper: {
        width: "100%"
    },
    mainChartBody: {
        overflowX: "auto"
    },
    mainChartHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.only("xs")]: {
            flexWrap: "wrap"
        }
    },
    mainChartHeaderLabels: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            order: 3,
            width: "100%",
            justifyContent: "center",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2)
        }
    },
    mainChartHeaderLabel: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(3)
    },
    mainChartSelectRoot: {
        borderColor: theme.palette.text.hint + "80 !important"
    },
    mainChartSelect: {
        padding: 10,
        paddingRight: 25
    },
    mainChartLegentElement: {
        fontSize: "14px !important",
        marginLeft: theme.spacing(1)
    },
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    fixIconRight: {
        "& .MuiSelect-icon": {
            right: 4
        }
    },
    actionsIcon: {
        color: "#76767B"
    }
}));
