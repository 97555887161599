import React, { useEffect } from 'react'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Grid,
    Box
} from "@material-ui/core";
import Widget from '../../components/Widget/Widget'

const EditMeasure = () => {
    const classes = useStyles();
    const [measure, setMeasure] = React.useState({
        actionText: "",
        actionComment: "",
        status: 0,
        objectID: null,
        formID: null
    });

    function handleChange(e) {
        setMeasure({
            ...measure,
            [e.target.name]: e.target.value,
        });
    }

    function handleChangeToggle(e) {
        if (measure.status === 0) {
            setMeasure({
                ...measure,
                ["status"]: 1,
            });
        } else {
            setMeasure({
                ...measure,
                ["status"]: 0,
            });
        }
    }

    const GreenCheckbox = withStyles({
        root: {
            color: "#17a506",
            '&$checked': {
                color: "#17a506",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const handleNext = () => {
        axios.post('/Action/create/', measure).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = '/#/app/åtgärdspunkter/åtgärdslista';
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Åtgärd skapad!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                                onClick={handleNext}
                                multiline
                            >
                                Skapa åtgärd
                            </Typography>
                            <TextField
                                id="text"
                                label="Åtgärdstext"
                                onChange={handleChange}
                                name="actionText"
                                value={measure.actionText}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Redigera texten till åtgärden. Tryck enter för att lägga till ny rad"
                                multiline
                            />
                            <TextField
                                id="text"
                                label="Kommentar"
                                onChange={handleChange}
                                name="actionComment"
                                value={measure.actionComment}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Redigera kommentaren till åtgärden. Tryck enter för att lägga till ny rad"
                            />
                            <div style={{ display: "flex" }}>
                                <GreenCheckbox
                                    checked={measure.status === 1 ? true : false}
                                    onClick={handleChangeToggle}
                                    style={{ marginTop: "-4px" }}
                                    name="status"
                                />
                                <span style={{ lineHeight: "42px" }}>Åtgärdad</span>
                            </div>

                            <div>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                    >
                                        Skapa åtgärd
                                    </Button>
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid >
    )
}

export default EditMeasure
