import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

import {
    AssignmentInd,
} from '@material-ui/icons'

const ChangeInformation = () => {
    const [newInfo, setNewInfo] = React.useState(
        {
            fullName: '',
            title: '',
            signatureImage: ''
        }
    );

    const [currentUser, setCurrentUser] = useState({});
    const fileInput = React.useRef(null);

    useEffect(() => {
        let newCurrentUser = localStorage.getItem('currentUser');

        if (newCurrentUser) {
            newCurrentUser = JSON.parse(newCurrentUser);

            setCurrentUser(newCurrentUser);

            axios.get('/Users/' + newCurrentUser.id).then(
                res => {
                    if (res.status === 200) {
                        setNewInfo({
                            fullName: res.data.fullName,
                            title: res.data.title,
                            signatureImage: res.data.signatureImage
                        });
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });

            setNewInfo({
                fullName: newCurrentUser.fullName,
                title: newCurrentUser.title,
                signatureImage: newCurrentUser.signatureImage
            });
        }

        
    }, []);

    const handleFile = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('File', file);
        formData.append('UserID', currentUser.id);

        axios.post('/Users/imageUpload', formData).then(
            res => {
                if (res.status === 201) {
                    setNewInfo({
                        ...newInfo,
                        signatureImage: res.data.filePath,
                    });
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });
    }

    function handleChange(e) {
        setNewInfo(
            {
                ...newInfo,
                [e.target.name]: e.target.value,
            }
        );
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.put('/Users/change-UserInfo', newInfo).then(
            res => {
                if (res.status === 200) {
                    sendNotification();
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Information ändrad!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Ändra din information
                            </Typography>
                            <TextField
                                id="nameField"
                                label="För- och efternamn"
                                onChange={handleChange}
                                name="fullName"
                                value={newInfo.fullName}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in ditt nya för- och efternamn"
                            />
                            <TextField
                                id="titleField"
                                label="Titel"
                                onChange={handleChange}
                                name="title"
                                value={newInfo.title}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in din nya titel"
                            />
                            <div>
                                <Grid item xs={12}>
                                    {newInfo.signatureImage &&
                                        <div>
                                            <img style={{ maxWidth: "400px", maxHeight: "200px" }} className={classes.thumbNail} src={newInfo.signatureImage} />
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <label
                                        className={classes.uploadLabel}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <AssignmentInd className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                        {newInfo.signatureImage ? 'Ersätt signatur' : 'Ladda upp signatur'}
                                        <input style={{ display: 'none' }} accept="image/*" type="file" ref={fileInput} onChange={e => handleFile(e)} />
                                    </label>
                                    <Typography
                                        size={'sm'}
                                        style={{ marginBottom: 30 }}
                                    >
                                        Accepterade filformat: .PNG, .JPG/.JPEG
                                        <br />
                                    </Typography>
                                </Grid>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Ändra information
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default ChangeInformation
