import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const AddUser = () => {
    const [newUser, setNewUser] = React.useState(
        {
            email: '',
            userName: '-',
            fullName: '',
            password: '',
            title: '',
            roles: ['Kund'],
            customerID: 0,
        }
    );

    const [customers, setCustomers] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false)

    useEffect(() => {
        axios.get("/customers/getall").then(
            res => {
                if (res.status === 200) {
                    setCustomers(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        let newUser = localStorage.getItem('currentUser');

        if (newUser) {
            if (JSON.parse(newUser).roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    }, []);

    function handleChange(e) {
        console.log(e.target.value);
        if (e.target.name === "roles") {
            setNewUser({
                ...newUser,
                roles: [e.target.value],
            });
        } else {
            setNewUser({
                ...newUser,
                [e.target.name]: e.target.value,
            });
        }
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.post('/users/create', newUser).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = '/#/app/användare/användarlista';
            }
        }).catch(err => {
            sendErrorNotification();
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Användare tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function sendErrorNotification() {
        const componentProps = {
            type: "feedback",
            message: "Användare kunde inte läggas till! Kontrollera lösenordet, och ifall epostadressen redan finns",
            variant: "contained",
            color: "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa ny användare
                            </Typography>
                            <TextField
                                id="fullName"
                                label="Förnamn och efternamn"
                                onChange={handleChange}
                                name="fullName"
                                value={newUser.fullName || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in användarens namn"
                            />
                            <TextField
                                id="title"
                                label="Titel"
                                onChange={handleChange}
                                name="title"
                                value={newUser.title || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in användarens titel"
                            />
                            <TextField
                                id="emailField"
                                label="E-post"
                                onChange={handleChange}
                                name="email"
                                value={newUser.email || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in användarens e-post"
                            />
                            <TextField
                                id="password"
                                label="Lösenord"
                                onChange={handleChange}
                                name="password"
                                value={newUser.password || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in lösenordet som användaren ska använda"
                                type={'password'}
                            />
                            <Typography
                                variant={'h5'}
                                style={{ marginBottom: 30, fontSize: "16px" }}
                            >
                                Lösenordet måste uppfylla följande krav:
                                <ul>
                                    <li>Minst 6 tecken</li>
                                    <li>Minst en liten bokstav ('a' - 'z')</li>
                                    <li>Minst en stor bokstav ('A' - 'Z')</li>
                                    <li>Minst en siffra ('0' - '9')</li>
                                    <li>Minst ett tecken som inte är bokstav eller siffra (Ex. '!' eller '#')</li>
                                </ul>
                            </Typography>
                            {isAdmin &&
                                <>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Roll</InputLabel>
                                        <Select
                                            name="roles"
                                            value={newUser.roles[0] ? newUser.roles[0] : '0'}
                                            onChange={handleChange}
                                            label="Roll"
                                            style={{ marginBottom: 35 }}
                                        >
                                            <MenuItem key="0" value="Kund">Kund</MenuItem>
                                            <MenuItem key="1" value="Admin">Administratör</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel>Företag</InputLabel>
                                        <Select
                                            name="customerID"
                                            value={newUser.customerID}
                                            onChange={handleChange}
                                            label="Företag"
                                            style={{ marginBottom: 35 }}
                                        >
                                            <MenuItem value={0}>
                                                Inget företag valt
                                            </MenuItem>
                                            {
                                                customers.map(
                                                    customer => {
                                                        return (
                                                            <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </>
                            }
                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={newUser.email.length === 0 || newUser.password.length === 0 || newUser.fullName.length === 0 || (newUser.roles[0] === "Kund" && newUser.customerID === 0)}
                                        >
                                            Skapa användare
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddUser
