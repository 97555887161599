import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const AddFacility = () => {
    const [newFacility, setNewFacility] = React.useState({
        name: '',
        city: '',
        customerID: 0
    });

    const [customers, setCustomers] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);

                setNewFacility({
                    ...newFacility,
                    customerID: currentUser.customerID
                });
            }
        }

        axios.get("/customers/getall").then(
            res => {
                if (res.status === 200) {
                    setCustomers(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }, []);

    function handleChange(e) {
        setErrorText("");
        switch (e.target.name) {
            case 'facilityName':
                setNewFacility({
                    ...newFacility,
                    "name": e.target.value,
                });
                break;

            case 'customerID':
                if (e.target.value > -1) {
                    setNewFacility({
                        ...newFacility,
                        customerID: e.target.value
                    });
                }

                break;

            case 'city':
                setNewFacility({
                    ...newFacility,
                    "city": e.target.value,
                });
                break;

            default:
                break;
        }
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.post('/facilities/create', newFacility).then(res => {
            if (res.status === 200) {
                console.log("res");
                console.log(res);
                sendNotification();
                window.location.href = '/#/app/anläggningar/anläggningslista';
            }
        }).catch(err => {
            if (err.response.data === "Facility already exsist") {
                setErrorText("Det finns redan en anläggning med det namnet");
            }
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Anläggning tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa ny anläggning
                            </Typography>
                            <TextField
                                id="nameField"
                                label="Namn"
                                onChange={handleChange}
                                name="facilityName"
                                value={newFacility.name}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in anläggningens namn"
                            />
                            <TextField
                                id="city"
                                label="Ort"
                                onChange={handleChange}
                                name="city"
                                value={newFacility.city}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in anläggningens ort"
                            />
                            {/*<TextField
                                id="cityField"
                                label="Stad"
                                onChange={handleChange}
                                name="city"
                                value={newFacility.city || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in anläggningens stad"
                            />*/}
                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                            >
                            </FormControl>
                            {isAdmin && <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Kund</InputLabel>
                                <Select
                                    name="customerID"
                                    value={newFacility.customerID}
                                    onChange={handleChange}
                                    label="Kund"
                                >
                                    <MenuItem value='0'>
                                        Ingen kund vald
                                    </MenuItem>
                                    {
                                        customers.map(
                                            customer => {
                                                return (
                                                    <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                                <FormHelperText>
                                    Välj vilken kund anläggningen tillhör
                                </FormHelperText>
                            </FormControl>}
                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                                style={{ marginBottom: 35 }}
                            >
                            </FormControl>

                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={newFacility.customerID == '0' || newFacility.name.length === 0}
                                        >
                                            Skapa anläggning
                                        </Button>
                                    </Box>
                                </div>
                            </div>

                            <Typography style={{ color: "red", textAlign: "right", marginTop: "34px" }}>{errorText}</Typography>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddFacility
