import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

const AddDocument = () => {
    const [objectId, setObjectId] = React.useState([]);
    const [docId, setDocId] = React.useState([]);

    const fileInput = React.useRef(null);

    const handleFile = async (event) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('File', file);
        formData.append('ShowOnStartPage', true);
        formData.append('ObjectID', objectId);

        axios.post('/Documents/upload', formData).then(
            res => {
                if (res.status === 201) {
                    axios.put('/Documents/update/' + docId, { documentFileID: res.data.id }).then(
                        res2 => {
                            if (res2.status === 200) {
                                sendNotification();
                                window.location.href = '/#/app/objekt/dokument?objektID=' + objectId;
                            }
                        }
                    ).catch(err => {
                        console.log('err');
                        console.log(err);
                    });
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        console.log(file);

        return;
    }

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const docId = urlParams.get('dokumentID');
            const objId = urlParams.get('objektID');
            setDocId(docId);
            setObjectId(objId);
        }
    }, []);

    const classes = useStyles()

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Fil tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'}>
                        <Typography weight={'medium'}>Ladda upp fil till dokumentet</Typography>
                        <label
                            className={classes.uploadLabel}
                            style={{ cursor: 'pointer' }}
                        >
                            {'Ladda upp ett dokument'}
                            <input style={{ display: 'none' }} accept="*" type="file" ref={fileInput} onChange={handleFile} />
                        </label>
                        <Typography
                            size={'sm'}
                            style={{ marginBottom: 30 }}
                        >
                            Accepterade filformat: .PDF, .DOC, .TXT
                        </Typography>
                    </Grid>
                </Widget>
            </Grid >
        </Grid >
    )
}

export default AddDocument
