import React, { useEffect } from "react";
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Accordion from "@material-ui/core/Accordion";
import Checkbox from '@material-ui/core/Checkbox';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { cleanseDateStringFromT } from '../../helpers/storedDataHelper';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import useStyles from './styles'
import { toast } from 'react-toastify'
import Notification from "../../components/Notification";
import { Button, Typography } from '../../components/Wrappers'
import Widget from '../../components/Widget'
import axios from 'axios';
import { withStyles } from '@material-ui/styles'
import {
    ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";

//function getSteps() {
//    return ['Grunddata', 'Tillverkningsdata', 'Bedömningsdata', 'Ytterligare data']
//}

const panelArray = [
    {
        id: 0,
        open: false,
    },
    {
        id: 1,
        open: false,
    },
    {
        id: 2,
        open: false,
    },
    {
        id: 3,
        open: false,
    },
    {
        id: 4,
        open: false,
    },
    {
        id: 5,
        open: false,
    },
    {
        id: 6,
        open: false,
    },
    {
        id: 7,
        open: false,
    },
];

const AddObject = () => {
    const [activeStep, setActiveStep] = React.useState(0)
    const [skipped, setSkipped] = React.useState(new Set())

    const [customers, setCustomers] = React.useState([]);
    const [facilities, setFacilities] = React.useState([]);
    const [sections, setSections] = React.useState([]);
    const [objectTypes, setObjectTypes] = React.useState([]);
    const [objectData, setObjectData] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [steps, setSteps] = React.useState(['Grunddata', 'Tillverkningsdata', 'Klassningsdata', 'Ytterligare data']);
    const [dimensionMetric, setDimensionMetric] = React.useState("liter"); //Choices: liter, m3, kW, DN
    const [dimensionText, setDimensionText] = React.useState("");
    const [volumeMetric, setVolumeMetric] = React.useState("liter"); //Choices: liter, m3, kW, DN
    const [volumeText, setVolumeText] = React.useState("");
    const [objectMainType, setObjectMainType] = React.useState(-1);//-1: Inget val gjort. 1: Trycksatt anordning. 2: Lyft
    const [customerID, setCustomerID] = React.useState(-1);
    const currentDate = new Date();
    const [currentObject, setCurrentObject] = React.useState(
        {
            latestRevisionDate: getProperCurrentDate(),
            facilityID: -1,
            typeID: -1,
            controlClass: -1,
            sectionID: 0,
            placement: "",
            objectName: "",
            objectNr: "",
            room: "",
            door: "",
            building: "",
            manufacturer: "",
            regNr: "",
            manufacturerNr: "",
            manufacturerYear: "",
            volume: "",
            designPressureOver: "",
            designPressureUnder: "",
            designTemperatureHighest: "",
            designTemperatureLowest: "",
            testPressure: "",
            allowedPressureOver: "",
            allowedPressureUnder: "",
            allowedTemperatureHighest: "",
            allowedTemperatureLowest: "",
            controlRegulation: 0,
            media: "",
            dangerGroup: 0,
            checkingDP: 0,
            checkingUI: 0,
            dateOfNextSelfCheck: null, //Leave as null
            yearOfNextSelfCheck: currentDate.getFullYear(),
            monthOfNextSelfCheck: currentDate.getMonth() + 1,
            liftweight: "",
            length: "",
            color: "",
            controlSignPosition: "",
            executorAKOLiftInspection: 0,
            isOk: false,
            comment: "",
            holdingTime: "",
            assetGroup: "",
            effectKw: "",
            journalIsNeeded: 0,
            isInactive: false,
            blueprintNr: "",
            safetyVentPosition: "",
            safetyVentPressure: "",
            executorAKODP: 0,
            mediaForm: 0,
            flowchart: "",
            dimension: ""
        }
    );

    function getStepContent(step) {
        //switch (step) {
        //    case 0:
        //        return 'Grunddata'
        //    case 1:
        //        return 'Tillverkningsdata'
        //    case 2:
        //        return 'Bedömningsdata'
        //    case 3:
        //        return 'Ytterligare data'
        //    default:
        //        return ''
        //}

        return steps[step];
    }

    const GreenCheckbox = withStyles({
        root: {
            color: "#17a506",
            '&$checked': {
                color: "#17a506",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    useEffect(() => {
        axios.get("/Objects/data").then(
            res => {
                if (res.status === 200) {
                    setObjectData(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/customers/getall").then(
            res => {
                if (res.status === 200) {
                    setCustomers(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/facilities/getall").then(
            res => {
                if (res.status === 200) {
                    setFacilities(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/ObjectTypes/getall").then(
            res => {
                if (res.status === 200) {
                    setObjectTypes(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        let newUser = localStorage.getItem('currentUser');

        if (newUser) {
            let currentUser = JSON.parse(newUser);
            if (currentUser.roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
                setCustomerID(currentUser.customerID);
            }
        }
    }, []);

    function getProperCurrentDate() {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return finalDateString;
    }

    function getProperCurrentDateAddMonths(monthsToAdd) {
        let finalDateString = currentDate.getFullYear() + "-";
        let newDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd))
        //currentDate.setMonth(currentDate.getMonth() + monthsToAdd);
        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return cleanseDateStringFromT(finalDateString);
    }

    function getProperMonth() {
        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        return month;
    }

    function getProperDayOfMonth() {
        let day = currentDate.getDate();

        if (day < 9) {
            day = "0" + (day);
        } else {
            day = "" + (day)
        }

        return day;
    }

    function handleChange(e) {
        if (e.target.name) {
            if (e.target.name === "comment" && e.target.value.length > 600) {
                return;
            }
            setCurrentObject({
                ...currentObject,
                [e.target.name]: e.target.value,
            });
        }
    }

    function handleChangeToggle(e) {
        setCurrentObject({
            ...currentObject,
            [e.target.name]: e.target.checked,
        });
    }

    function handleDatePickerChange(e) {
        //Has to be this way because the date picker element sends old values if other handleChange method is used
        const element = document.getElementsByName(e.target.name)[0];
        if (element) {
            setCurrentObject({
                ...currentObject,
                [e.target.name]: element.value,
            });
        }
    }

    function handleChangeMainType(e) {
        const mainTypeID = parseInt(e.target.value)
        setObjectMainType(mainTypeID);

        if (mainTypeID === 1) {
            setSteps(['Grunddata', 'Tillverkningsdata', 'Klassningsdata', 'Ytterligare data']);
        } else if (mainTypeID === 2) {
            setSteps(['Grunddata', 'Tillverkningsdata', 'Ytterligare data']);
        }

        if (currentObject.typeID > -1) {
            currentObject.typeID = -1;
            currentObject.objectName = "";
        }
    }

    function handleChangeCustomer(e) {
        const newCustomerID = parseInt(e.target.value)
        setCustomerID(newCustomerID);

        if (currentObject.facilityID > -1) {
            currentObject.facilityID = -1;
        }
    }

    /*function handleChangeLiftInspectionDates(e) {
        //Todo: Tie to new variables and not lift
        let tempObj = { ...currentObject };
        let period = 0;

        if (e.target.name === 'fltInterval') {
            const newInterval = parseInt(e.target.value);
            if (newInterval > 0) {
                period = newInterval;
            }

            let date = new Date(currentObject.dateOfLastFlt);
            date.setMonth(date.getMonth() + period);

            tempObj.fltInterval = e.target.value;
            tempObj.dateOfNextFlt = date.toISOString().split('T')[0];

        } else {
            if (currentObject.fltInterval > 0) {
                period = currentObject.fltInterval
            }

            if (e.target.name === "dateOfLastFlt") {
                tempObj.dateOfLastFlt = e.target.value;

                let date = new Date(e.target.value);
                date.setMonth(date.getMonth() + period);

                tempObj.dateOfNextFlt = date.toISOString().split('T')[0];
            } else if (e.target.name === "dateOfNextFlt") {
                tempObj.dateOfNextFlt = e.target.value;

                let date = new Date(e.target.value);
                date.setMonth(date.getMonth() - period);

                tempObj.dateOfLastFlt = date.toISOString().split('T')[0];
            }
        }

        setCurrentObject(tempObj);
    }*/

    function handleChangeFacility(e) {
        const newFacilityID = parseInt(e.target.value)

        if (newFacilityID !== null) {
            axios.get('Sections/facility/' + newFacilityID).then(
                res => {
                    if (res.status === 200) {
                        setSections(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });
        }

        let newObj = { ...currentObject };

        newObj.facilityID = parseInt(e.target.value);
        newObj.sectionID = 0;

        setCurrentObject(newObj);
    }

    function handleChangeINT(e) {
        const parsedInt = parseInt(e.target.value);
        let tempObj = { ...currentObject }

        if (!Number.isNaN(parsedInt)) {
            tempObj[e.target.name] = parsedInt;

            if (e.target.name === 'typeID') {
                if (parsedInt === -1) {
                    tempObj["objectName"] = "";
                } else {
                    let tempTypes = objectTypes.filter(objType => objType.mainType.id === objectMainType);
                    tempObj["objectName"] = tempTypes.find(tempType => tempType.id === parsedInt).name;
                }
            }

            setCurrentObject(tempObj);
        }
    }

    function handleSizeTypeTextChange(e) {
        const targetValue = e.target.value;
        let regex = /^[0-9,/]+$/;

        if (regex.test(targetValue) || targetValue === "") {
            if (e.target.name === "dimension") {
                setDimensionText(e.target.value);
            } else if (e.target.name === "volume") {
                setVolumeText(e.target.value);
            }
        }
    }

    //const steps = getSteps()
    const classes = useStyles()

    const [expanded, setExpanded] = React.useState(panelArray);

    const handleExpandChange = (panelId) => () => {
        setExpanded(
            expanded.map((panel) =>
                panel.id === panelId
                    ? { ...panel, open: !expanded[panelId].open }
                    : { ...panel }
            )
        );
    };

    const isStepSkipped = step => {
        return skipped.has(step)
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setSkipped(newSkipped);

        if ((objectMainType === 1 && activeStep === 3) || (objectMainType === 2 && activeStep === 2)) {
            try {
                let tempCurrentObject = { ...currentObject };
                tempCurrentObject.mainTypeID = objectMainType;

                if (tempCurrentObject.sectionID === 0 || tempCurrentObject.sectionID === "0") {
                    tempCurrentObject.sectionID = null;
                }

                if (dimensionMetric === "DN") {
                    tempCurrentObject.dimension = dimensionMetric + dimensionText;
                } else {
                    tempCurrentObject.dimension = dimensionText + " " + dimensionMetric;
                }

                if (volumeMetric === "DN") {
                    tempCurrentObject.volume = volumeMetric + volumeText;
                } else {
                    tempCurrentObject.volume = volumeText + " " + volumeMetric;
                }

                axios.post('/objects/create', tempCurrentObject).then(
                    res => {
                        if (res.status === 201) {
                            sendNotification();

                            window.location.href = '/#/app/objekt/forteckning?objektTyp=' + (objectMainType - 1);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                });
            } catch (e) {
                sendErrorNotification();
            }
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }

        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function sendErrorNotification() {
        const componentProps = {
            type: "feedback",
            message: "Kunde inte lägga till objekt!",
            variant: "contained",
            color: "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekt tillagt!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function getFacilitiesByCustomer(customerID) {
        return (
            facilities.map(
                facility => {
                    if (facility.customer && facility.customer.id === customerID) {
                        return (
                            <MenuItem key={facility.id} value={facility.id}>{facility.name}</MenuItem>
                        )
                    }
                }
            )
        )
    }

    function getObjectTypes(mainObjectType) {
        return (
            objectTypes.map(
                type => {
                    if (type.mainType.id === mainObjectType) {
                        return (
                            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                        )
                    }
                }
            )
        )
    }

    function getYearSpan(yearsIntoFuture) {
        const currentYear = currentDate.getFullYear();

        let yearArray = [];

        for (var i = 0; i <= yearsIntoFuture; i++) {
            yearArray.push(currentYear + i);
        }

        return yearArray;
    }

    function getMonthSelectList() {
        let monthArray = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']

        return monthArray;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {}
                            const labelProps = {}
                            if (isStepSkipped(index)) {
                                stepProps.completed = false
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps} classes={{ completed: classes.stepCompleted }}>{/*For the edit page: onClick={function () { setActiveStep(index) }} style={{ cursor: "pointer" }}*/}
                                        {label}
                                    </StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </Widget>
            </Grid>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                {getStepContent(activeStep)}
                            </Typography>
                            {activeStep === 0 ? (<>
                                {/*<TextField
                                    onChange={handleDatePickerChange}
                                    name="latestRevisionDate"
                                    value={currentObject.latestRevisionDate}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText="Ange när senaste revideringen skedde"
                                    type="date"
                                />*/}
                                {isAdmin && <FormControl
                                    variant="outlined"
                                    onChange={handleChange}
                                    style={{ marginBottom: 35 }}
                                >
                                    <InputLabel>
                                        Kund
                                    </InputLabel>
                                    <Select
                                        value={customerID}
                                        name="customerID"
                                        onChange={handleChangeCustomer}
                                        label="Kund"
                                    >
                                        <MenuItem value="-1">Ingen kund vald</MenuItem>
                                        {
                                            customers.map(
                                                customer => {
                                                    return (
                                                        <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                    <FormHelperText>
                                        Ange vilken kund objektet tillhör
                                    </FormHelperText>
                                </FormControl>}
                                <FormControl
                                    variant="outlined"
                                    onChange={handleChange}
                                    style={{ marginBottom: 35 }}
                                    key={customerID, currentObject.facilityID}
                                    disabled={customerID < 0}
                                >
                                    <InputLabel>
                                        Anläggning
                                    </InputLabel>
                                    <Select
                                        value={customerID < 0 ? -2 : currentObject.facilityID ? currentObject.facilityID : -1}
                                        name="facilityID"
                                        onChange={handleChangeFacility}
                                        label="Anläggning"
                                        key={currentObject.customerID, currentObject.facilityID}
                                    >
                                        <MenuItem value="-1">Ingen anläggning vald</MenuItem>
                                        {customerID < 0 ? <MenuItem value="-2">Välj kund först</MenuItem> : null}
                                        {getFacilitiesByCustomer(customerID)}
                                    </Select>
                                    <FormHelperText>
                                        Ange vilken anläggning objektet tillhör
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    disabled={currentObject.facilityID < 0}
                                >
                                    <InputLabel>
                                        Avdelning
                                    </InputLabel>
                                    <Select
                                        value={currentObject.facilityID < 0 ? -2 : currentObject.sectionID}
                                        name="sectionID"
                                        onChange={handleChange}
                                        label="Avdelning"
                                        key={currentObject.sectionID, currentObject.facilityID}
                                    >
                                        {currentObject.facilityID < 0 ? <MenuItem value="-2">Välj anläggning först</MenuItem> : null}
                                        <MenuItem value="0">Ingen avdelning vald</MenuItem>
                                        {
                                            sections.map(
                                                sec => {
                                                    return (
                                                        <MenuItem key={sec.id} value={sec.id}>{sec.name}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                    <FormHelperText>
                                        Ange vilken avdelning objektet tillhör
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    onChange={handleChange}
                                    style={{ marginBottom: 35 }}
                                >
                                    <InputLabel>
                                        Kategori
                                    </InputLabel>
                                    <Select
                                        value={objectMainType}
                                        name="Kategori"
                                        onChange={handleChangeMainType}
                                        label="Kategori"
                                    >
                                        <MenuItem value="-1">Ingen kategori vald</MenuItem>
                                        <MenuItem value="1">Trycksatt anordning</MenuItem>
                                        <MenuItem value="2">Lyft</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        Välj om objektet tillhör <strong>Trycksatt anordning</strong> eller <strong>Lyft</strong>
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    onChange={handleChange}
                                    style={{ marginBottom: 35 }}
                                >
                                    <InputLabel>
                                        Objekttyp
                                    </InputLabel>
                                    <Select
                                        value={objectMainType < 0 ? -2 : currentObject.typeID ? currentObject.typeID : -1}
                                        name="typeID"
                                        onChange={handleChangeINT}
                                        label="Objekttyp"
                                        disabled={objectMainType < 0}
                                    >
                                        <MenuItem value="-1" key="-2">Ingen objekttyp vald</MenuItem>
                                        {objectMainType < 0 ? <MenuItem value="-2" key="-2">Välj kategori först</MenuItem> : null}
                                        {getObjectTypes(objectMainType)}
                                    </Select>
                                    <FormHelperText>
                                        Ange objekttyp för objekt
                                    </FormHelperText>
                                </FormControl>
                                <TextField
                                    label="Objektnummer"
                                    onChange={handleChange}
                                    value={currentObject.objectNr}
                                    name="objectNr"
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText={
                                        'Ange objekt- eller posnummer för objektet'
                                    }
                                />
                                <TextField
                                    label="Objektnamn"
                                    onChange={handleChange}
                                    value={currentObject.objectName}
                                    name="objectName"
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText={
                                        'Ange namn för objektet'
                                    }
                                />
                                <TextField
                                    label="Plats"
                                    onChange={handleChange}
                                    value={currentObject.placement}
                                    name="placement"
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText={
                                        'Ange uppställningsplats för objektet'
                                    }
                                />

                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={expanded[0].open} onChange={handleExpandChange(0)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography>Detaljerad platsinformation, valfritt</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.QuestionUnderline} style={{ display: "grid", marginBottom: "35" }}>
                                            <TextField
                                                label="Byggnad"
                                                onChange={handleChange}
                                                name="building"
                                                value={currentObject.building}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Ange namn på byggnad som objektet är placerat i"
                                            />
                                            <TextField
                                                label="Rum"
                                                onChange={handleChange}
                                                name="room"
                                                value={currentObject.room}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Ange namn på rummet som objektet är placerat i"
                                            />
                                            <TextField
                                                label="Dörr"
                                                onChange={handleChange}
                                                value={currentObject.door}
                                                name="door"
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText={'Ange dörr som leder till rummet där objektet är placerad'}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </>
                            ) : activeStep === 1 ? (<>
                                {/*<TextField
                                    label="Objekt-/ posnummer"
                                    onChange={handleChange}
                                    name="objectNr"
                                    value={currentObject.objectNr}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText="Ange objekt- eller posnummer"
                                />*/}
                                <TextField
                                    label="Tillverkningsnummer"
                                    variant="outlined"
                                    value={currentObject.manufacturerNr}
                                    onChange={handleChange}
                                    name="manufacturerNr"
                                    style={{ marginBottom: 35 }}
                                    helperText={'Ange tillverkningsnummer'}
                                />

                                <div className={classes.splicedFields}>
                                    <TextField
                                        label="Tillverkare"
                                        onChange={handleChange}
                                        name="manufacturer"
                                        value={currentObject.manufacturer}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        className={classes.splicedField}
                                        helperText={'Ange tillverkare'}
                                    />
                                    <div className={classes.splicedFieldDistancer} />
                                    <TextField
                                        label="Tillverkningsår"
                                        variant="outlined"
                                        value={currentObject.manufacturerYear}
                                        onChange={handleChange}
                                        name="manufacturerYear"
                                        style={{ marginBottom: 35 }}
                                        className={classes.splicedField}
                                        helperText={'Ange tillverkningsår'}
                                    />
                                </div>
                                {objectMainType === 1 && (
                                    <>
                                        <div className={classes.splicedFields}>
                                            <TextField
                                                label="Storlek"
                                                variant="outlined"
                                                value={volumeText}
                                                onChange={handleSizeTypeTextChange}
                                                name="volume"
                                                className={classes.splicedField}
                                                style={{ marginBottom: 35 }}
                                                helperText={'Ange antal enheter för objektets storlek'}
                                            />
                                            <div className={classes.splicedFieldDistancer} />
                                            <FormControl
                                                variant="outlined"
                                                onChange={handleChange}
                                                style={{ marginBottom: 35 }}
                                                className={classes.splicedField}
                                            >
                                                <InputLabel>
                                                    Mått
                                                </InputLabel>
                                                <Select
                                                    value={volumeMetric}
                                                    name="sizeMetric"
                                                    onChange={e => setVolumeMetric(e.target.value)}
                                                    label="Mått"
                                                >
                                                    <MenuItem value="liter">Volym (liter)</MenuItem>
                                                    <MenuItem value="m3">Volym (kubikmeter)</MenuItem>
                                                    <MenuItem value="kW">Effekt (kW)</MenuItem>
                                                    <MenuItem value="DN">DN</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    Ange vilket mått objektets storlek är i
                                                </FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className={classes.splicedFields}>
                                            <TextField
                                                label="Designtryck PS (bar(g)) lägsta"
                                                onChange={handleChange}
                                                name="designPressureUnder"
                                                value={currentObject.designPressureUnder}
                                                variant="outlined"
                                                className={classes.splicedField}
                                                helperText={'Ange lägsta designtryck i bar(g)'}
                                            />
                                            <div className={classes.splicedFieldDistancer} />
                                            <TextField
                                                label="Designtryck PS (bar(g)) högsta"
                                                onChange={handleChange}
                                                name="designPressureOver"
                                                value={currentObject.designPressureOver}
                                                variant="outlined"
                                                className={classes.splicedField}
                                                helperText={'Ange högsta designtryck i bar(g)'}
                                            />
                                        </div>
                                        <div className={classes.splicedFields}>
                                            <TextField
                                                label="Designtemperatur TS (°C) lägsta"
                                                variant="outlined"
                                                value={currentObject.designTemperatureLowest}
                                                onChange={handleChange}
                                                name="designTemperatureLowest"
                                                className={classes.splicedField}
                                                helperText={'Ange lägsta tillåtna temperatur i grader (C*)'}
                                            />
                                            <div className={classes.splicedFieldDistancer} />
                                            <TextField
                                                label="Designtemperatur TS (°C) högsta"
                                                variant="outlined"
                                                value={currentObject.designTemperatureHighest}
                                                onChange={handleChange}
                                                name="designTemperatureHighest"
                                                className={classes.splicedField}
                                                helperText={'Ange högsta tillåtna temperatur i grader (C*)'}
                                            />
                                        </div>
                                        <TextField
                                            label="Ritning"
                                            onChange={handleChange}
                                            name="blueprintNr"
                                            value={currentObject.blueprintNr}
                                            variant="outlined"
                                            style={{ marginBottom: 35 }}
                                            helperText="Ange ritningsnummer till aktuell ritning"
                                        />
                                        {/*
                                             * <TextField
                                                label="TS min/max (°C)"
                                                variant="outlined"
                                                onChange={handleChangeINT}
                                                value={currentObject.lowestHighestAllowedTemperature}
                                                name="lowestHighestAllowedTemperature"
                                                style={{ marginBottom: 35 }}
                                                helperText={'Ange lägsta och högsta tillåtna temperatur för objektet'}
                                                type="number"
                                            />
                                            */}
                                    </>
                                )}

                                {objectMainType === 2 && (<>
                                    <TextField
                                        label="Lyftvikt"
                                        onChange={handleChange}
                                        name="liftweight"
                                        value={currentObject.liftweight}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange lyftvikt i kilogram'}
                                    />
                                    <TextField
                                        label="Längd"
                                        onChange={handleChange}
                                        value={currentObject.length}
                                        name="length"
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange längd av objektet i meter'}
                                    />
                                    <TextField
                                        label="Färg"
                                        variant="outlined"
                                        value={currentObject.color}
                                        name="color"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange färg av objektet'}
                                    />
                                    <TextField
                                        label="Utrustningsgrupp"
                                        onChange={handleChange}
                                        name="assetGroup"
                                        value={currentObject.assetGroup}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText="Ange Utrustningsgrupp av objektet"
                                    />
                                </>
                                )}
                            </>
                            ) : activeStep === 2 ? (<>
                                {objectMainType < 1 && (<>
                                    <Typography
                                        variant={'h5'}
                                        style={{ marginBottom: 30, fontSize: "16px" }}
                                    >
                                        Fler val för {objectMainType === 1 ? "klassningsdata" : "bedömningsdata"}finns om en kategori är vald
                                    </Typography></>
                                )}
                                {objectMainType === 1 && (<>
                                    <div className={classes.splicedFields}>
                                        <TextField
                                            label="Dimension"
                                            variant="outlined"
                                            value={dimensionText}
                                            onChange={handleSizeTypeTextChange}
                                            name="dimension"
                                            className={classes.splicedField}
                                            style={{ marginBottom: 35 }}
                                            helperText={'Ange antal enheter för objektets dimension'}
                                        />
                                        <div className={classes.splicedFieldDistancer} />
                                        <FormControl
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{ marginBottom: 35 }}
                                            className={classes.splicedField}
                                        >
                                            <InputLabel>
                                                Mått
                                            </InputLabel>
                                            <Select
                                                value={dimensionMetric}
                                                name="dimensionMetric"
                                                onChange={e => setDimensionMetric(e.target.value)}
                                                label="Mått"
                                            >
                                                <MenuItem value="liter">Volym (liter)</MenuItem>
                                                <MenuItem value="m3">Volym (kubikmeter)</MenuItem>
                                                <MenuItem value="kW">Effekt (kW)</MenuItem>
                                                <MenuItem value="DN">DN</MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                Ange vilket mått objektets dimension är i
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className={classes.splicedFields}>
                                        <TextField
                                            label="Lägsta tillåtna tryck"
                                            variant="outlined"
                                            value={currentObject.allowedPressureUnder}
                                            onChange={handleChange}
                                            name="allowedPressureUnder"
                                            className={classes.splicedField}
                                            helperText="Ange lägsta tillåtna tryck"
                                        />
                                        <div className={classes.splicedFieldDistancer} />
                                        <TextField
                                            label="Högsta tillåtna tryck"
                                            variant="outlined"
                                            value={currentObject.allowedPressureOver}
                                            onChange={handleChange}
                                            name="allowedPressureOver"
                                            className={classes.splicedField}
                                            helperText="Ange högsta tillåtna tryck"
                                        />
                                    </div>
                                    <div className={classes.splicedFields}>
                                        <TextField
                                            label="Lägsta tillåtna temp"
                                            variant="outlined"
                                            value={currentObject.allowedTemperatureLowest}
                                            onChange={handleChange}
                                            name="allowedTemperatureLowest"
                                            className={classes.splicedField}
                                            helperText={'Ange lägsta tillåtna temp'}
                                        />
                                        <div className={classes.splicedFieldDistancer} />
                                        <TextField
                                            label="Högsta tillåtna temp"
                                            variant="outlined"
                                            value={currentObject.allowedTemperatureHighest}
                                            onChange={handleChange}
                                            name="allowedTemperatureHighest"
                                            className={classes.splicedField}
                                            helperText={'Ange högsta tillåtna temp'}
                                        />
                                    </div>
                                    <div className={classes.splicedFields}>
                                        <TextField
                                            label="Media"
                                            onChange={handleChange}
                                            name="media"
                                            variant="outlined"
                                            value={currentObject.media}
                                            className={classes.splicedField}
                                            helperText={'Ange innehåll/media'}
                                        />
                                        <div className={classes.splicedFieldDistancer} />
                                        <FormControl
                                            variant="outlined"
                                            onChange={handleChange}
                                            className={classes.splicedField}
                                        >
                                            <InputLabel>
                                                Riskgrupp
                                            </InputLabel>
                                            <Select
                                                value={currentObject.dangerGroup}
                                                name="dangerGroup"
                                                onChange={handleChange}
                                                label="Riskgrupp"
                                            >
                                                {
                                                    objectData.dangerGroups.map(
                                                        dGroup => {
                                                            return (
                                                                <MenuItem key={dGroup.name} value={dGroup.value}>{dGroup.name}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Ange riskgrupp för media (1a / 2a)
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Mediaform
                                        </InputLabel>
                                        <Select
                                            value={currentObject.mediaForm}
                                            name="mediaForm"
                                            onChange={handleChange}
                                            label="Mediaform"
                                        >
                                            {
                                                objectData.mediaForm.map(
                                                    form => {
                                                        return (
                                                            <MenuItem key={form.name} value={form.value}>{form.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange om media är i gasform eller vätskeform (Gas/Vätska)
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Kontrollklass
                                        </InputLabel>
                                        <Select
                                            value={currentObject.controlClass}
                                            name="controlClass"
                                            onChange={handleChange}
                                            label="Kontrollklass"
                                        >
                                            {
                                                objectData.classifications.map(
                                                    classification => {
                                                        return (
                                                            <MenuItem key={classification.name} value={classification.value}>{classification.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange vilken kontrollklass objektet tillhör
                                        </FormHelperText>
                                    </FormControl>
                                    <div style={{ display: "flex", marginBottom: 35 }}>
                                        <Typography
                                            variant={'h5'}
                                            style={{ fontSize: "16px", lineHeight: '42px' }}
                                        >
                                            Ej i drift
                                        </Typography>
                                        <GreenCheckbox
                                            checked={currentObject.isInactive}
                                            onClick={handleChangeToggle}
                                            name="isInactive"
                                        />
                                    </div>
                                </>)}
                                {objectMainType === 2 && (<>
                                    <TextField
                                        label="Kommentar"
                                        onChange={handleChange}
                                        value={currentObject.comment}
                                        variant="outlined"
                                        name="comment"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange övrig kommentar eller information om objektet (Max 600 tecken)'}
                                    />
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Kontrollföreskrift
                                        </InputLabel>
                                        <Select
                                            value={currentObject.controlRegulation}
                                            name="controlRegulation"
                                            onChange={handleChange}
                                            label="Kontrollföreskrift"
                                        >
                                            {
                                                objectData.controlRegulationsLift.map(
                                                    regu => {
                                                        return (
                                                            <MenuItem key={regu.name} value={regu.value}>{regu.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange kontrollföreskrift som objektet omfattas av
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            AKO
                                        </InputLabel>
                                        <Select
                                            value={currentObject.executorAKOLiftInspection}
                                            name="executorAKOLiftInspection"
                                            onChange={handleChangeINT}
                                            label="AKO"
                                        >
                                            {
                                                objectData.executorAKO.map(
                                                    exeAko => {
                                                        return (
                                                            <MenuItem key={exeAko.name} value={exeAko.value}>{exeAko.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange ackrediterat kontrollorgan (KIWA / DEKRA)
                                        </FormHelperText>
                                    </FormControl>

                                    <TextField
                                        label="Reg-nr, AKO"
                                        onChange={handleChange}
                                        value={currentObject.regNr}
                                        name="regNr"
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange vilket reg-nr AKO har tilldelat objeket'}
                                    />
                                    <TextField
                                        label="Placering kontrollskylt"
                                        onChange={handleChange}
                                        value={currentObject.controlSignPosition}
                                        variant="outlined"
                                        name="controlSignPosition"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange placering av kontrollskylten'}
                                    />
                                </>
                                )
                                }
                            </>
                            ) : (
                                <>
                                    <TextField
                                        label="Kommentar"
                                        onChange={handleChange}
                                        value={currentObject.comment}
                                        variant="outlined"
                                        name="comment"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange övrig kommentar eller information om objektet (Max 600 tecken)'}
                                    />
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Kontrollföreskrift
                                        </InputLabel>
                                        <Select
                                            value={currentObject.controlRegulation}
                                            name="controlRegulation"
                                            onChange={handleChange}
                                            label="Kontrollföreskrift"
                                        >
                                            {
                                                objectMainType === 1 &&
                                                objectData.controlRegulations.map(
                                                    regu => {
                                                        return (
                                                            <MenuItem key={regu.name} value={regu.value}>{regu.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }

                                            {
                                                objectMainType === 2 &&
                                                objectData.controlRegulationsLift.map(
                                                    regu => {
                                                        return (
                                                            <MenuItem key={regu.name} value={regu.value}>{regu.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange kontrollföreskrift som objektet omfattas av
                                        </FormHelperText>
                                    </FormControl>

                                    {objectMainType === 1 && <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            AKO
                                        </InputLabel>
                                        <Select
                                            value={currentObject.executorAKODP}
                                            name="executorAKODP"
                                            onChange={handleChangeINT}
                                            label="AKO"
                                        >
                                            {
                                                objectData.executorAKO.map(
                                                    exeAko => {
                                                        return (
                                                            <MenuItem key={exeAko.name} value={exeAko.value}>{exeAko.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange ackrediterat kontrollorgan (KIWA / DEKRA)
                                        </FormHelperText>
                                    </FormControl>}

                                    {objectMainType === 2 && <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            AKO
                                        </InputLabel>
                                        <Select
                                            value={currentObject.executorAKOLiftInspection}
                                            name="executorAKOLiftInspection"
                                            onChange={handleChangeINT}
                                            label="AKO"
                                        >
                                            {
                                                objectData.executorAKO.map(
                                                    exeAko => {
                                                        return (
                                                            <MenuItem key={exeAko.name} value={exeAko.value}>{exeAko.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange ackrediterat kontrollorgan (KIWA / DEKRA)
                                        </FormHelperText>
                                    </FormControl>}

                                    <TextField
                                        label="Reg-nr, AKO"
                                        onChange={handleChange}
                                        value={currentObject.regNr}
                                        name="regNr"
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange vilket reg-nr AKO har tilldelat objeket'}
                                    />
                                    <TextField
                                        label="Placering kontrollskylt"
                                        onChange={handleChange}
                                        value={currentObject.controlSignPosition}
                                        variant="outlined"
                                        name="controlSignPosition"
                                        style={{ marginBottom: 35 }}
                                        helperText={'Ange placering av kontrollskylten'}
                                    />
                                    {objectMainType === 1 && <>
                                        <TextField
                                            label="Flödesschema"
                                            variant="outlined"
                                            value={currentObject.flowchart}
                                            onChange={handleChange}
                                            name="flowchart"
                                            style={{ marginBottom: 35 }}
                                            helperText="Ange ritningsnr för aktuellt flödesschema"
                                        />
                                        <TextField
                                            label="Posnummer SÄV"
                                            variant="outlined"
                                            value={currentObject.safetyVentPosition}
                                            onChange={handleChange}
                                            name="safetyVentPosition"
                                            style={{ marginBottom: 35 }}
                                            helperText="Ange posnr för säkerhetsventil(er)"
                                        />
                                        <TextField
                                            label="Avsäkringstryck SÄV"
                                            variant="outlined"
                                            value={currentObject.safetyVentPressure}
                                            onChange={handleChange}
                                            name="safetyVentPressure"
                                            style={{ marginBottom: 35 }}
                                            helperText="Ange avsäkringstryck på säkerhetsventil(er)"
                                        />
                                    </>}
                                </>
                            )}
                            <div>
                                <div>
                                    {activeStep === 0 ? (
                                        <Box
                                            display={'flex'}
                                            justifyContent={'flex-end'}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                style={{ marginTop: 35 }}
                                                disabled={(currentObject.typeID === -1 || currentObject.facilityID === -1)}
                                            >
                                                Nästa steg
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                        >
                                            <Button
                                                onClick={handleBack}
                                                variant={'outlined'}
                                                color={'primary'}
                                            >
                                                Tillbaka
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                {activeStep === steps.length - 1
                                                    ? 'Klar'
                                                    : 'Nästa steg'}
                                            </Button>
                                        </Box>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddObject
