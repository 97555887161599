import React, { useState, useEffect } from "react";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Drawer, IconButton, List } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar
} from "../../context/LayoutContext";

function Sidebar({ location, structure }) {
    var classes = useStyles();
    var theme = useTheme();

    const toggleDrawer = value => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        if (value && !isPermanent) toggleSidebar(layoutDispatch);
    };

    // global
    var { isSidebarOpened } = useLayoutState();
    var layoutDispatch = useLayoutDispatch();

    // local
    var [isPermanent, setPermanent] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false)
    const [permissions, setPermissions] = useState([]);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();

        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        if (currentUser) {
            currentUser = JSON.parse(currentUser);
            if (currentUser.roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }

            if (currentUser.userPermission) {
                setPermissions(currentUser.userPermission);
            }
        }
    }, []);

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: !isPermanent ? !isSidebarOpened : isSidebarOpened,
                [classes.drawerClose]: !isPermanent ? isSidebarOpened : !isSidebarOpened
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: !isPermanent
                        ? !isSidebarOpened
                        : isSidebarOpened,
                    [classes.drawerClose]: !isPermanent
                        ? isSidebarOpened
                        : !isSidebarOpened
                })
            }}
            open={!isPermanent ? !isSidebarOpened : isSidebarOpened}
            onClose={toggleDrawer(true)}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse)
                        }}
                    />
                </IconButton>
            </div>
            <List
                className={classes.sidebarList}
                classes={{ padding: classes.padding }}
            >
                {structure.map(link => (
                    <>
                        <SidebarLink
                            key={link.id}
                            location={location}
                            isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
                            isAdmin={isAdmin}
                            adminOnly={link.adminOnly}
                            permissions={permissions}
                            {...link}
                            toggleDrawer={toggleDrawer(true)}
                        />
                    </>
                ))}
            </List>
        </Drawer >
    );

    // ##################################################################
    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(Sidebar);
