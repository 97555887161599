import React from 'react'
import Widget from '../Widget'
import { Link } from 'react-router-dom'
import {
    Box,
    Grid,
    Breadcrumbs,
    Tabs,
    Tab,
} from '@material-ui/core'
import { Typography } from '../Wrappers'
import {
    NavigateNext as NavigateNextIcon,
    CalendarToday as CalendarIcon,
} from '@material-ui/icons'
import { useLocation } from 'react-router-dom';

// styles
import useStyles from '../Layout/styles'

// components

import structure from '../Sidebar/SidebarStructure'

const BreadCrumbs = () => {
    const location = useLocation()
    const classes = useStyles()

    const renderBreadCrumbs = () => {
        let url = location.pathname;
        let route = url.split('/')
            .slice(1)
            .map(route => route
                .split('-')
                .map(word => word[0].toUpperCase() + word.slice(1))
                .join(' ')
            );
        const length = route.length;
        return route.map((item, index) => {
            let middlewareUrl = "/" + url.split('/').slice(1, index + 2).join('/');
            switch (item) {
                case "Frågor":
                    middlewareUrl = "/app/frågor/lista" + location.search;
                    break;
                case "Forteckning":
                    item = "Förteckning";
                    break;
                case "Mallar": 
                case "Dokument" :
                    middlewareUrl = "/app/objekt/dokument" + location.search;
                    break;
                default:
                    break;
            }

            return (
                <Breadcrumbs
                    key={index + '_b'}
                    separator={
                        <NavigateNextIcon fontSize="small" />
                    }
                    aria-label="breadcrumb"
                >
                    <Typography variant="h6" color={length === index + 1 ? "primary" : ""}>
                        {length === index + 1 ?
                            item :
                            <Link to={middlewareUrl} style={{ color: 'unset', textDecoration: 'none' }}>
                                {item}
                            </Link>
                        }
                    </Typography>
                </Breadcrumbs>
            )
        })
    }

    return (
        <Widget
            disableWidgetMenu
            inheritHeight
            className={classes.margin + " " + classes.noPrint}
            bodyClass={classes.navPadding}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap={'nowrap'}
                style={{ overflowX: 'auto' }}
            >

                {// eslint-disable-next-line
                    structure.map(
                        c => {
                            if (
                                window.location.hash.includes(c.link) &&
                                c.link && c.label === "Dashboard"
                            ) {
                                return (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        key={c.id}
                                    >
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Typography variant="h4">
                                                {c.label}
                                            </Typography>
                                        </Breadcrumbs>
                                    </Box>
                                )
                            }
                        }
                    )
                }
                {
                    <Breadcrumbs
                        separator={
                            <NavigateNextIcon fontSize="small" />
                        }
                        aria-label="breadcrumb"
                    >
                        {renderBreadCrumbs()}
                    </Breadcrumbs>
                }
            </Grid>
        </Widget>

    )
}
export default BreadCrumbs
