import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "./styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from '@material-ui/core/Button';
import rorventil from "../../images/TemplateImages/Rorventiler.jpg";

import {
    ExpandMore as ExpandMoreIcon,
    SubdirectoryArrowRight,
    RadioButtonUnchecked,
    Done,
    Clear,
    RemoveCircleOutline,
    Image,
    Comment,
} from "@material-ui/icons";

import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const AddTemplate = () => {

    const classes = useStyles();

    const panelArray = [
        {
            id: 0,
            open: false,
        },
        {
            id: 1,
            open: false,
        },
        {
            id: 2,
            open: false,
        },
        {
            id: 3,
            open: false,
        },
        {
            id: 4,
            open: false,
        },
        {
            id: 5,
            open: false,
        },
        {
            id: 6,
            open: false,
        },
        {
            id: 7,
            open: false,
        },
    ];

    const [expanded, setExpanded] = React.useState(panelArray);

    const handleExpandChange = (panelId) => () => {
        setExpanded(
            expanded.map((panel) =>
                panel.id === panelId
                    ? { ...panel, open: !expanded[panelId].open }
                    : { ...panel }
            )
        );
    };

    return (
        <Grid container spacing={3}>
            <Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} variant='h3'>
                Beskrivning
            </Typography>
            <Grid item xs={12}>
                <Widget disableWidgetMenu>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <table>
                                <tr>
                                    <td className={classes.TableLeft}>Typ: </td>
                                    <td className={classes.TableRight}>Tryckluftbehållare</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Tilllverkare: </td>
                                    <td className={classes.TableRight}>Somatherm</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Tillverkningsnr: </td>
                                    <td className={classes.TableRight}>123</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Registrering: </td>
                                    <td className={classes.TableRight}>T555333</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Uppställ.plats: </td>
                                    <td className={classes.TableRight}>Källare</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Platsnr: </td>
                                    <td className={classes.TableRight}>T52B</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Kontrollklass: </td>
                                    <td className={classes.TableRight}>A</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Kund/Avdelning: </td>
                                    <td className={classes.TableRight}>Flåklypa Kommun/ Vattenverket</td>
                                </tr>
                            </table>

                            <br />
                            <br />

                            <table>
                                <tr>
                                    <td className={classes.TableLeft}>Datum för riskbedömning: </td>
                                    <td className={classes.TableRight}></td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Deltagare: </td>
                                    <td className={classes.TableRight}></td>
                                </tr>
                                <tr>
                                    <td className={classes.TableLeft}>Underlag: </td>
                                    <td className={classes.TableRight}>Flödeschema F333</td>
                                </tr>
                            </table>

                            <br />
                            <br />

                            <Typography>Ansvarig namn:</Typography>
                            <div className={classes.SignLineSolid}></div>
                            <br />
                            <br />
                            <Typography>Ansvarig underskrift:</Typography>
                            <div className={classes.SignLineSolid}></div>
                            <div className={classes.SignLineSolid}></div>
                        </Grid>
                        <Grid item xs={6}>
                            <img src="/downloadImage/62f23b50-32c8-45e5-85be-425b46be1eaf" className={classes.IntroImage} alt="Tryckluft" />
                        </Grid>
                    </Grid>
                </Widget>
            </Grid>

            <Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} variant='h3'>Avseende säkerhet:</Typography>
            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[0].open} onChange={handleExpandChange(0)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>1. Är ventiler och rör uppmärkta så risken minskar för felaktigt handhavande?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[1].open} onChange={handleExpandChange(1)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>2. Finns en korrekt monterad säkerhetsventil (eller sprängbleck) på objektet?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[2].open} onChange={handleExpandChange(2)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>3. Finns en korrekt visande manometer på objektet?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} variant='h3'>Avseende placering:</Typography>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[3].open} onChange={handleExpandChange(3)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>4. Är anordningen stående?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[4].open} onChange={handleExpandChange(4)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>5. Är anordningen förankrad?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[5].open} onChange={handleExpandChange(5)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>6. Finns möjlighet till underhåll / reparation / kontroll?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[6].open} onChange={handleExpandChange(6)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>7. Kan objekt skadas av närliggande aktiviteter/objekt?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[7].open} onChange={handleExpandChange(7)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>8. Kan de som befinner sig på plats utsättas för skadliga tryckvågor?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion
                    expanded={expanded[7].open} onChange={handleExpandChange(7)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>9. Kan de som befinner sig på plats stänga av objekt?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                            <div>
                                                <FormControlLabel value="Ej aktuell" control={<Radio style={{ color: "#17a506" }} />} label="Ej aktuell" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                <Button variant="contained" className={classes.Buttons}><Comment className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till kommentar</Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" className={classes.Buttons}><Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} /> Lägg till bild</Button>
                            <Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid style={{ display: "none" }} item xs={12}>
                <Accordion
                    expanded={expanded[1].open} onChange={handleExpandChange(1)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>2. Finns korrekt monterad manometer?</Typography>
                                </Grid>
                                <Grid item xs={12} style={{}}>

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Ja" control={<Radio style={{ color: "#17a506" }} />} label="Ja" />
                                            <FormControlLabel value="Nej" control={<Radio style={{ color: "#17a506" }} />} label="Nej" />
                                            <div>
                                                <FormControlLabel value="Annat" control={<Radio style={{ color: "darkgrey" }} />} label="Annat" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={6}>
                            <Typography>
                                Kommentar:
                                <br />
                                Montera manometer
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.InlineCenter}><Image className={classes.TextIcons} /> Lägg till bild</Typography>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid container>
                <Grid item xs={2}>
                    <SubdirectoryArrowRight style={{ marginLeft: "10px" }} /><Button className={classes.Buttons} variant="contained">Lägg till egen fråga</Button>
                </Grid>
                <Grid hidden item xs={2}>
                    <Button className={classes.Buttons} color="#17a506" variant="contained">Lägg till ny rubrik</Button>
                </Grid>
            </Grid>

            <Grid style={{ display: "none" }} item xs={12}>
                <Accordion expanded={true}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid item xs={3}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Titel på fråga"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    style={{ display: "flex", justifyContent: "space-around" }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div>
                                            <span className={classes.checkGreen}>
                                                <RadioButtonUnchecked />
                                            </span>
                                            <span className={classes.checkOrange}>
                                                <RadioButtonUnchecked />
                                            </span>
                                            <span className={classes.checkRed}>
                                                <RadioButtonUnchecked />
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField style={{ backgroundColor: "darkgray" }} id="outlined-basic" label="Datum" variant="outlined" />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-basic"
                                label="Utfärdare"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-basic"
                                label="Anläggningsägare"
                                variant="outlined"
                            />
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails className={classes.QuestionUnderline}>
                        <Grid item xs={3}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Text för fråga"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField style={{ backgroundColor: "darkgray" }} id="outlined-basic" label="Svar" variant="outlined" />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                style={{ backgroundColor: "#a9a9a9" }}
                                id="outlined-basic"
                                label="Förslag till åtgärd"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                style={{ display: "none" }}
                                id="outlined-basic"
                                label="Lägg till bild"
                                variant="outlined"
                            />
                            <img style={{ maxHeight: "300px" }} src={rorventil} alt="Rörventil" />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid style={{ display: "none" }} container>
                <Grid item xs={1}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            textAlign: "right",
                            paddingRight: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <SubdirectoryArrowRight />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.NewQuestionRow}>
                        <Done style={{ marginRight: "10%", color: "#17a506" }} />
                        <Typography>OK</Typography>
                    </div>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                    <div className={classes.NewQuestionRow}>
                        <Clear style={{ marginRight: "10%", color: "red" }} />
                        <Typography>Avbryt</Typography>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddTemplate;
