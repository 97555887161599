import React from 'react'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import {
    TextField,
    Grid,
    Box
} from "@material-ui/core";
import Widget from '../../components/Widget/Widget'

const AddQuestionGroup = () => {
    const classes = useStyles()

    const [questionGroup, setQuestionGroup] = React.useState({
        name: "",
        description: ""
    });

    //useEffect(() => {
    //    if (newDocument.executiveDate && newDocument.fltInterval > 0) {
    //        let date = new Date(newDocument.executiveDate);
    //        date.setMonth(date.getMonth() + newDocument.fltInterval);
    //        setNewDocument({ ...newDocument, dateOfNextFlt: date.toISOString().split('T')[0] });
    //    }
    //}, [newDocument.executiveDate]);

    function handleChange(e) {
        setQuestionGroup({
            ...questionGroup,
            [e.target.name]: e.target.value,
        });
    }

    const handleNext = () => {
        axios.post('/FormQuestionGroup/Create', questionGroup).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = '/#/app/checklistor/lista';
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Checklista tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa ny checklista
                            </Typography>
                            <TextField
                                id="name"
                                label="Namn"
                                onChange={handleChange}
                                name="name"
                                value={questionGroup.name}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Skriv in namn för checklistan"
                            />
                            <TextField
                                id="description"
                                label="Beskrivning"
                                onChange={handleChange}
                                name="description"
                                value={questionGroup.description}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Ge en frivillig beskrivning om checklistan"
                            />

                            <div>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        disabled={questionGroup.name.length === 0}
                                    >
                                        Skapa checklista
                                    </Button>
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid >
        </Grid >
    )
}

export default AddQuestionGroup
