import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { tableOptions } from './TableOptions';
import { Button } from '../../components/Wrappers'

import {
    Speed,
    Edit,
    Delete,
    MoreVert
} from '@material-ui/icons'

export default function SectionTable() {
    const [customerFilter, setCustomerFilter] = useState([]);
    const [sections, setSections] = useState();
    const [selectedFacilityName, setSelectedFacilityName] = useState("");
    const [selectedFacilityId, setSelectedFacilityId] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const classes = useStyles();
    const muiTable = useRef(null);

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const facilityId = parseInt(urlParams.get('anläggningsID'));
            const facilityName = urlParams.get('anläggningsnamn');

            console.log('params');
            console.log(params);
            console.log(urlParams);

            setSelectedFacilityName(facilityName);

            if (facilityId) {
                setSelectedFacilityId(facilityId);
                axios.get("/Sections/facility/" + facilityId)
                    .then(
                        res => {
                            if (res.status === 200) {
                                console.log('facilities getall result');
                                console.log(res.data);
                                setSections(res.data);
                            }
                        }
                    ).catch(err => {
                        console.log('err');
                        console.log(err);
                    })
            }
            //const customerName = urlParams.get('avdelningID');
            //setCustomerFilter([customerName]);
        }
    }, []);

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Anläggning borttagen!",
            variant: "contained",
            color: "success",
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function menuClick(sectionId) {
        if (!sectionId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(sectionId);
        setShowMenu(true);
    }

    function GetBtns(sectionId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(sectionId)}>
                <MoreVert id={"menuBtn" + sectionId} />
            </div>
        )
        //return (
        //    <div style={{ display: "flex", justifyContent: "space-between", width: "160px", float: "right" }}>
        //        <div style={{ cursor: "pointer" }} onClick={() => editBtnClick(section.id)}>
        //            <Edit />
        //        </div>
        //        <div style={{ cursor: "pointer" }} onClick={() => delBtnClick(section.id)}>
        //            <Delete />
        //        </div>
        //    </div>
        //)
    }

    function editBtnClick(e, sectionId) {
        let selectedSec = sections.find(fac => fac.id === sectionId);

        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/avdelningar/redigera?anläggningsID=' + selectedFacilityId + "&avdelningsID=" + selectedSec.id + "&anläggningsnamn=" + selectedSec.name;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/avdelningar/redigera?anläggningsID=' + selectedFacilityId + "&avdelningsID=" + selectedSec.id + "&anläggningsnamn=" + selectedSec.name,
                '_blank'
            );
        }
    }

    function createBtnClick(e) {
        if (e.button === 0 && !e.ctrlKey) {
            window.location.replace('/#/App/avdelningar/ny?anläggningsID=' + selectedFacilityId + "&anläggningsnamn=" + selectedFacilityName);
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/App/avdelningar/ny?anläggningsID=' + selectedFacilityId + "&anläggningsnamn=" + selectedFacilityName,
                '_blank'
            );
        }
    }

    function getHeader() {
        return (<div style={{ width: "300px" }}></div>)
    }

    function userBtnClick(facilityName) {
        console.log(facilityName);
    }

    function delBtnClick(sectionId) {
        if (window.confirm("Är du säker på att du vill ta bort anläggningen?")) {
            axios.delete("/sections/" + sectionId).then(
                res => {
                    if (res.status === 200) {
                        setSections(sections.filter(sec => sec.id != sectionId));
                        sendDeletedNotification();
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> Redigera
                    </MenuItem>

                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> Ta bort
                    </MenuItem>
                </Menu>}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={e => createBtnClick(e)}
                        style={{ marginBottom: 28 }}
                    >
                        Skapa avdelning
                    </Button>
                    {sections && (
                        <>
                            <MUIDataTable
                                title={"Lista över avdelningar för " + selectedFacilityName}
                                data={sections.map(
                                    (section, index) => {
                                    return [
                                        GetBtns(section.id, index),
                                        section.id,
                                        section.name
                                    ]
                                })}
                                columns={[
                                    {
                                        name: "",
                                        options: {
                                            filter: false,
                                            viewColumns: false,
                                        }
                                    },
                                    {
                                        name: "ID",
                                        options: {
                                            display: false,
                                            viewColumns: false,
                                            filterList: []

                                        }
                                    },
                                    {
                                        name: "Namn",
                                        options: {
                                            filterList: []
                                        }
                                    }
                                    ]}
                                options={tableOptions(null)}
                                key={sections}
                                ref={muiTable}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
