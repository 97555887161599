import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const EditPassword = () => {
    const [newPassword, setNewPassword] = React.useState(
        {
            password: '',
            confirmPassword: ''
        }
    );

    const [users, setUsers] = React.useState([]);
    const [id, setId] = React.useState("");

    useEffect(() => {
        axios.get("/Users/getall").then(
            res => {
                if (res.status === 200) {
                    setUsers(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }, []);

    function handleChange(e) {
        if (e.target.name === "id") {
            setId(e.target.value);
        } else {
            setNewPassword(
                {
                    ...newPassword,
                    [e.target.name]: e.target.value,
                }
            );
        }
    }

    const classes = useStyles()

    const handleNext = () => {
        axios.put('/Users/updatePassword/' + id, newPassword).then(
            res => {
                if (res.status === 201) {
                    sendNotification();
                    window.location.href = '/#/app/användare/användarlista';
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Lösenord ändrat!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Ändra användares lösenord
                            </Typography>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Välj användare</InputLabel>
                                <Select
                                    labelId="simple-select-label"
                                    id="id"
                                    name="id"
                                    value={id}
                                    onChange={handleChange}
                                    label="Välj användare"
                                    style={{ marginBottom: 35 }}
                                >
                                    <MenuItem key="" value="">Ingen användare vald</MenuItem>
                                    {
                                        users.map(
                                            user => {
                                                return (
                                                    <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </FormControl>
                            <TextField
                                id="password"
                                label="Nytt lösenord"
                                onChange={handleChange}
                                name="password"
                                value={newPassword.password}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in användarens nya lösenord"
                            />
                            <TextField
                                id="confirmPassword"
                                label="Bekräfta nytt lösenord"
                                onChange={handleChange}
                                name="confirmPassword"
                                value={newPassword.confirmPassword}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in användarens nya lösenord igen för att bekräfta"
                            />
                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Ändra lösenord
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default EditPassword
