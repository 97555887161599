import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import { cleanseDateStringFromT } from '../../helpers/storedDataHelper';
import {
    TextField,
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Fade,
    Modal,
    Backdrop,
} from "@material-ui/core";
import { Delete, Image, ExpandMore as ExpandMoreIcon, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import Widget from '../../components/Widget/Widget'
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";

const EditMeasure = () => {
    const currentDate = new Date();
    const fileInput = React.useRef(null);
    const classes = useStyles();
    const [measureID, setMeasureID] = useState(""); //Actions are called measures locally, but are still named Actions in the back-end
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [currentName, setCurrentName] = useState("");
    const [addCommentString, setAddCommentString] = useState("");
    const [measure, setMeasure] = useState();
    const [measureImageList, setMeasureImageList] = useState([]);
    const [currentShownImage, setCurrentShownImage] = useState(0);
    const [commentList, setCommentList] = useState([]);
    let history = useHistory();

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        if (currentUser) {
            currentUser = JSON.parse(currentUser);
            setCurrentName(currentUser.fullName);
        }

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const id = urlParams.get('åtgärdsID');
            setMeasureID(id);

            axios.get("/Action/" + id)
                .then(res => {
                    if (res.status === 200) {
                        const { actionText, status, objectID, actionExpirationDate, actionResponsible } = res.data;
                        let newMeasure = { actionText, status, objectID, actionExpirationDate, actionResponsible }

                        setMeasureImageList(res.data.actionImages);

                        //let newMeasure = res.data;

                        if (newMeasure.actionExpirationDate) {
                            newMeasure.actionExpirationDate = cleanseDateStringFromT(newMeasure.actionExpirationDate);
                        } else {
                            newMeasure.actionExpirationDate = getProperCurrentDate();
                        }

                        if (newMeasure.changed) {
                            newMeasure.changed = cleanseDateStringFromT(newMeasure.changed);
                        }

                        if (newMeasure.created) {
                            newMeasure.created = cleanseDateStringFromT(newMeasure.created);
                        }

                        setCommentList(res.data.actionComments);
                        setMeasure(newMeasure);
                    }
                }).catch(err => {
                    console.log('err');
                    console.log(err);
                })
        }
    }, []);

    const handleFile = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('File', file);
        formData.append('ObjectID', measure.objectID);

        axios.post('/Forms/imageUpload', formData).then(
            res => {
                if (res.status === 201) {
                    let newMeasureImageList = [...measureImageList]
                    newMeasureImageList.push(res.data);
                    setMeasureImageList(newMeasureImageList);
                    setCurrentShownImage(newMeasureImageList.length - 1);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        return;
    }

    function addComment() {
        if (addCommentString.length > 0) {
            let newComment = {
                text: addCommentString,
                author: currentName,
                date: getProperCurrentDate(),
                id: measureID
            };

            let newCommentList = [...commentList];
            newCommentList.push(newComment);


            axios.post('/Action/comment/create', newComment).then(res => {
                if (res.status === 200) {
                    sendNotification("Kommentar tillagd!", true);
                    setAddCommentString("");
                    setCommentList(newCommentList);
                    //window.location.href = '/#/app/åtgärdspunkter/åtgärdslista';
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }

    function removeComment(id) {
        if (window.confirm("Är du säker på att du vill ta bort kommentaren?")) {
            let newComments = [...commentList];
            newComments = newComments.filter(com => com.id !== id);
            setCommentList(newComments);
        }
    }

    function removeSelectedImage() {
        let newImages = [...measureImageList].filter((item, index) => { return (index !== currentShownImage) });

        if (currentShownImage > 0) {
            setCurrentShownImage(currentShownImage - 1);
        }

        setMeasureImageList(newImages);
    }

    function handleChange(e) {
        setMeasure({
            ...measure,
            [e.target.name]: e.target.value,
        });
    }

    const imageModalOpenNow = () => {
        setImageModalOpen(true);
    };

    const imageModalCloseNow = () => {
        setImageModalOpen(false);
    };

    function getProperCurrentDate() {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return finalDateString;
    }

    const handleNext = () => {
        let newMeasure = { ...measure }
        newMeasure.images = measureImageList;

        axios.put('/Action/update/' + measureID, newMeasure).then(res => {
            if (res.status === 200) {
                sendNotification("Åtgärd redigerad!", true);
                //window.location.href = '/#/app/åtgärdspunkter/åtgärdslista';
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            {measure && <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Grid item xs={12}>
                                <Accordion
                                    //expanded={expanded[0].open} onChange={handleExpandChange(0)}
                                    style={{ marginBottom: 30 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography>Redigera åtgärd</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.QuestionUnderline} style={{ display: "grid", marginBottom: "35" }}>
                                        <TextField
                                            id="text"
                                            label="Åtgärdstext"
                                            onChange={handleChange}
                                            value={measure.actionText}
                                            name="actionText"
                                            variant="outlined"
                                            style={{ marginBottom: 30, marginTop: 30 }}
                                            helperText="Redigera texten till åtgärden. Tryck enter för att lägga till ny rad"
                                            multiline
                                        />
                                        <div className={classes.splicedFields}>
                                            <TextField
                                                id="text"
                                                label="Ansvarig"
                                                onChange={handleChange}
                                                name="actionResponsible"
                                                value={measure.actionResponsible}
                                                variant="outlined"
                                                style={{ marginBottom: 30 }}
                                                className={classes.splicedField}
                                                helperText="Skriv in namn på ansvarig till åtgärdspunkten"
                                            />
                                            <div className={classes.splicedFieldDistancer} />
                                            <TextField
                                                type="date"
                                                onChange={handleChange}
                                                name="actionExpirationDate"
                                                value={measure.actionExpirationDate}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Ange sista datum för åtgärdspunkten"
                                                className={classes.splicedField}
                                            />
                                        </div>

                                        <div className={classes.splicedFields}>
                                            <FormControl variant="outlined" className={classes.splicedField}>
                                                <InputLabel>Status</InputLabel>
                                                <Select
                                                    name="status"
                                                    onChange={handleChange}
                                                    label="Status"
                                                    value={measure.status}
                                                >
                                                    <MenuItem value={0}>Ej åtgärdad</MenuItem>
                                                    <MenuItem value={1}>Pågående</MenuItem>
                                                    <MenuItem value={2}>Åtgärdad</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    Ange vilken status åtgärdspunkten ska ha
                                                </FormHelperText>
                                            </FormControl>

                                            <div className={classes.splicedFieldDistancer} />

                                            <Grid container item xs={12} justifyContent="center" className={classes.splicedField} style={{ position: "relative" }}>
                                                {measureImageList.length !== 0 && <div>
                                                    {measureImageList[currentShownImage - 1] && <ArrowBackIos style={{ color: "#17a506", position: "absolute", top: "50px", transform: "translateY(-50%)", left: "0%", zIndex: 10, cursor: "pointer" }} onClick={() => setCurrentShownImage(currentShownImage - 1)} />}
                                                    {measureImageList[currentShownImage + 1] && <ArrowForwardIos style={{ color: "#17a506", position: "absolute", top: "50px", transform: "translateY(-50%)", right: "0%", zIndex: 10, cursor: "pointer" }} onClick={() => setCurrentShownImage(currentShownImage + 1)} />}
                                                    <div className={classes.imgContainer}>
                                                        {measureImageList[currentShownImage] && <img className={classes.thumbnail + " image"} src={measureImageList[currentShownImage].filePath} />}
                                                        <span
                                                            onClick={() => removeSelectedImage()}
                                                            className={classes.imgCross}
                                                        >
                                                            <Delete />
                                                        </span>
                                                        <div className={classes.imgMiddle + " middle"}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => imageModalOpenNow()}
                                                                style={{ height: "50%" }}
                                                            >
                                                                Förstora
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>}

                                                <div style={{ marginTop: 10 }}>
                                                    <label
                                                        className={classes.uploadLabel}
                                                        style={{ cursor: 'pointer', margin: "0 auto", display: "block" }}
                                                    >
                                                        <Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                        {'Ladda upp'}
                                                        <input style={{ display: 'none' }} accept="image/jpeg, image/png, .pdf, .doc, .docx, .txt, .xls, .xlsx" type="file" ref={fileInput} onChange={e => handleFile(e, measureID)} />
                                                    </label>
                                                    <Typography
                                                        size={'sm'}
                                                    >
                                                        Accepterade filformat: <br /> .PNG, .JPG/.JPEG
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </div>

                                        <div>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-end'}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                >
                                                    Spara åtgärdsändringar
                                                </Button>
                                            </Box>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <div className={classes.commentContainer}>
                                    {commentList && commentList.map(comment =>
                                        <div key={comment.id} style={{ marginBottom: 30 }} className={classes.commentRow}>
                                            <div>
                                                <Typography>
                                                    {comment.text}
                                                </Typography>
                                            </div>
                                            {/*<span onClick={() => removeComment(comment.id)} className={classes.commentX}><Delete /></span>*/}
                                            <Typography className={classes.commentName}>
                                                {comment.author}
                                            </Typography>
                                            <Typography className={classes.commentDate}>
                                                Inlagd {cleanseDateStringFromT(comment.date)}
                                            </Typography>
                                        </div>)}
                                </div>

                                <Grid container>
                                    <Grid item>
                                        <TextField
                                            id="text"
                                            label="Lägg till kommentar"
                                            onChange={e => setAddCommentString(e.target.value)}
                                            name="addCommentString"
                                            style={{ marginBottom: 16 }}
                                            value={addCommentString}
                                            variant="outlined"
                                            helperText="Lägg till ny kommentar till åtgärden. Tryck enter för att lägga till ny rad"
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item style={{ margin: "0 auto" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => addComment()}
                                            style={{ maxHeight: "36.5px", marginTop: "10px" }}
                                        >
                                            Lägg till
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { history.goBack() }}
                                    style={{ maxHeight: "36.5px", marginTop: "10px", marginLeft: "14px" }}
                                >
                                    Tillbaka
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                </Widget>

                <Modal //Enlarged image modal
                    className={classes.modalContent}
                    open={imageModalOpen}
                    onClose={() => imageModalCloseNow()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={imageModalOpen} onClick={() => imageModalCloseNow()}>
                        <div className={classes.modalPaper}>
                            {measureImageList[currentShownImage] && <img src={measureImageList[currentShownImage].filePath} className={classes.modalImage} />}
                        </div>
                    </Fade>
                </Modal>
            </Grid >}
        </Grid>
    )
}

export default EditMeasure