import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    root: {
        display: "flex",
        maxWidth: "100vw",
        overflowX: "hidden"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100vw - 240px)`,
        minHeight: "100vh"
    },
    contentShift: {
        width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    fakeToolbar: {
        ...theme.mixins.toolbar
    },
    margin: {
        marginBottom: 24
    },
    tabPanel: {
        backgroundColor: '#f5f5f5',
        padding: theme.spacing(2),
    },
    '@media print': {
        noPrint: {
            visibility: "hidden!important",
            display: "none!important"
        },
        onlyInPrint: {
            visibility: "visible!important",
            display: "unset!important"
        }
    },
    noPrint: {
        visibility: "visible"
    },
    onlyInPrint: {
        visibility: "hidden!important",
        display: "none!important"
    },
}));
