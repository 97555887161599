import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import NewsTasksItem from './NewsTasksItem';
import NewsTasksItemAdd from './NewsTasksItemAdd';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Widget from '../../../components/Widget/Widget'
import { Edit } from '@material-ui/icons';
import { Typography } from "../../../components/Wrappers";
import { Button } from '../../../components/Wrappers'
import axios from 'axios';
import { Grid } from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ background: index % 2 === 0 && '#E1EFFF' }} p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const AntTabs = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid rgba(0, 0, 0, 0.4)`,
        margin: '0 24px 0 0',
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 2,
    },
}))(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        position: "relative",
        textTransform: 'none',
        minWidth: 72,
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: "4px",
        color: theme.palette.text.primary,
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        '&:hover': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: theme.palette.text.primary,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const styles = (theme) => ({
    root: {
        maxHeight: 575,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

        overflow: 'auto',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-track': {
            width: '3px',
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D8D8D8',
            borderRadius: 5,
            outline: '1px solid #D8D8D8',
        },
        flexGrow: 1,
        '& .react-swipeable-view-container': {
            transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important'
        },
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: '#2e1534',
    },
    folderWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    plusTab: {
        color: "#17a506",
        position: "absolute",
        top: "12px",
        right: "0",
        cursor: "pointer",
    },
    deleteButton: {
        color: "#17a506",
        position: "absolute",
        right: 0
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modalImage: {
        maxWidth: "80vw",
        maxHeight: "80vh"
    },
    imgContainer: {
        maxWidth: "200px",
        position: "relative",
        display: "inline-block",
        '&:hover .image': {
            opacity: 0.3
        },
        '&:hover .middle': {
            opacity: 1
        }
    },
    icons: {
        color: "#17a506",
        cursor: "pointer"
    },
    iconContainer: {
        marginTop: "8px"
    },
    addTab: {
        fontSize: "16px!important",
        lineHeight: '42px',
        marginLeft: "4px",
        cursor: "pointer",
        color: "#17a506",
    }
})

function CustomizedTabs({ classes, tabs, setTabs, createTab, deleteTab, updateTabTitle }) {
    const [index, setIndex] = React.useState(0);
    const [currentRowIndex, setCurrentRowIndex] = React.useState(-1);
    const [currentTabIndex, setCurrentTabIndex] = React.useState(-1);
    const [currentRowTitle, setCurrentRowTitle] = React.useState("");
    const [tabModalOpen, setTabModalOpen] = React.useState(false);
    const [rowModalOpen, setRowModalOpen] = React.useState(false);

    const tabModalOpenNow = () => {
        setTabModalOpen(true);
    };

    const tabModalCloseNow = (save) => {
        setTabModalOpen(false);
    };

    const rowModalOpenNow = (tabIndex, rowIndex) => {
        setCurrentTabIndex(tabIndex);

        if (tabIndex === -1) { //New tab
            setCurrentRowIndex(-3);
            setCurrentRowTitle("");
        } else {
            if (rowIndex > -1) { //Edit row
                setCurrentRowIndex(rowIndex);
                setCurrentRowTitle(tabs[tabIndex].rows[rowIndex].title);
            } else if (rowIndex === -2) { //New row
                setCurrentRowIndex(-2);
                setCurrentRowTitle("");
            } else {
                setCurrentRowTitle(tabs[tabIndex].title); //Edit tab
            }
        }

        setRowModalOpen(true);
    };

    const rowModalCloseNow = (save) => {
        if (save) {
            if (currentRowIndex > -1) {
                editRow(currentTabIndex, currentRowIndex, currentRowTitle);
            } else if (currentRowIndex === -2) { //If creating new row
                addNewRow(currentTabIndex, currentRowTitle);
            } else if (currentRowIndex === -3) {
                createTab(currentRowTitle);
            } else { //If editing tabs
                updateTabTitle(currentTabIndex, currentRowTitle);
            }
        }

        setRowModalOpen(false);
        setCurrentRowTitle(""); //Make sure these are not kept for the next row selection
        setCurrentRowIndex(-1);
    };

    const addTab = (newTitle) => {
        let newTabs = [...tabs];

        newTabs.push({ title: newTitle, rows: [] });

        setTabs(newTabs);
    }

    const editTab = (tabIndex, newTitle) => {
        let newTabs = [...tabs];

        newTabs[tabIndex].title = newTitle;

        setTabs(newTabs);
    }

    const removeTab = (tabIndex) => {
        let newTabs = [...tabs];

        newTabs = newTabs.filter((tab, currentTabIndex) => tabIndex !== currentTabIndex)

        if (newTabs) {
            setTabs(newTabs);
        }
    }

    const addNewRow = (taskbarTabIndex, title) => {
        let newTabs = [...tabs];
        const order = newTabs[taskbarTabIndex].rows.length;
        const taskbarTabID = newTabs[taskbarTabIndex].id;
        const newRow = { title: title, taskbarTabID: taskbarTabID, order: order }

        console.log("order");
        console.log(order);

        axios.post("Taskbar/createTaskbarRow/", newRow).then(
            res => {
                if (res.status === 201) {
                    newTabs[taskbarTabIndex].rows.push({ title: title, order: order, id: res.data.id, taskbarTabID: taskbarTabID });
                    setTabs(newTabs);
                    console.log(newTabs);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    const editRow = (tabIndex, rowIndex, newTitle) => {
        let newTabs = [...tabs];

        newTabs[tabIndex].rows[rowIndex].title = newTitle;

        axios.put("Taskbar/UpdateTaskbarRow/" + newTabs[tabIndex].rows[rowIndex].id, { title: newTitle }).then(
            res => {
                if (res.status === 200) {
                    setTabs(newTabs);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    const removeRow = () => {
        if (currentRowIndex === -1) {
            if (window.confirm("Är du säker på att du vill ta bort tabben?")) {
                deleteTab(currentTabIndex);
                rowModalCloseNow(false);
            }
        } else {
            if (window.confirm("Är du säker på att du vill ta bort raden?")) {
                let newTabs = [...tabs];

                axios.delete("Taskbar/deleteTaskbarRow/" + newTabs[currentTabIndex].rows[currentRowIndex].id).then(
                    res => {
                        if (res.status === 200) {
                            newTabs[currentTabIndex].rows = newTabs[currentTabIndex].rows.filter((row, iteratedRowIndex) => currentRowIndex !== iteratedRowIndex)
                            setTabs(newTabs);
                            setRowModalOpen(false);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    const handleChange = (event, index) => {
        setIndex(index)
    }

    const handleChangeIndex = (index) => {
        setIndex(index)
    }

    return (
        <div className={classes.root} style={{ position: "relative" }}>
            <Edit className={classes.plusTab} onClick={() => setTabModalOpen(true)} />
            <AntTabs
                value={index}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabs.length === 0 && <span style={{ fontWeight: "bold" }}>Skapa en tabb genom att klicka på pennan</span>}
                {tabs.map((tab, tabIndex) => (
                    <AntTab value={tabIndex} label={tab.title} />
                ))}
            </AntTabs>

            <SwipeableViews
                index={index}
                style={{ padding: 0, overflow: "hidden" }}
                onChangeIndex={handleChangeIndex}
                hysteresis={1}
            >
                {
                    tabs.map((tab, tabIndex) => (
                        <TabPanel style={{ padding: 0 }}>
                            {tab.rows.map((row, rowIndex) => (
                                <NewsTasksItem tabIndex={tabIndex} rowIndex={rowIndex} rowModalOpenNow={() => rowModalOpenNow(tabIndex, rowIndex)} editRow={editRow} removeRow={removeRow} key={rowIndex} title={row.title} />
                            ))}

                            <NewsTasksItemAdd tabIndex={tabIndex} addRow={addNewRow} rowModalOpenNow={() => rowModalOpenNow(tabIndex, -2)} />
                        </TabPanel>))
                }
            </SwipeableViews>

            <Modal //Tab modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalContent}
                open={tabModalOpen}
                onClose={() => tabModalCloseNow()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={tabModalOpen}>
                    <div className={classes.modalPaper} style={{ overflow: "auto", maxWidth: "648px", width: "100%" }}>
                        <Widget>
                            <Grid item justifyContent={'center'} style={{ boxShadow: "none" }} container>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    width={600}
                                    style={{ width: "100%", maxWidth: "600px" }}
                                >
                                    <Typography
                                        variant={'h5'}
                                        weight={'medium'}
                                        style={{ marginBottom: 30 }}
                                    >
                                        Redigera tabbar
                                    </Typography>

                                    <div className={classes.questionContainer}>
                                        <TabPanel style={{ padding: 0 }}>
                                            {tabs.map((tab, tabIndex) => (
                                                <NewsTasksItem tabOnly tabIndex={tabIndex} rowModalOpenNow={() => rowModalOpenNow(tabIndex)} editRow={updateTabTitle} removeRow={deleteTab} key={tab.title} title={tab.title} />
                                            ))}

                                            <NewsTasksItemAdd tabOnly addRow={createTab} rowModalOpenNow={() => rowModalOpenNow(-1, -3)} />
                                        </TabPanel>
                                    </div>
                                    <div>
                                        <Box
                                            display={'flex'}
                                            justifyContent={'flex-end'}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setTabModalOpen(false)}
                                            >
                                                Stäng
                                            </Button>
                                        </Box>
                                    </div>
                                </Box>
                            </Grid>
                        </Widget>
                    </div>
                </Fade>
            </Modal>

            <Modal //Row modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalContent}
                open={rowModalOpen}
                onClose={() => rowModalCloseNow(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={rowModalOpen}>
                    <div className={classes.modalPaper} style={{ overflow: "auto", maxWidth: "648px", width: "100%" }}>
                        <Grid item justifyContent={'center'} style={{ boxShadow: "none" }} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                style={{ width: "100%", maxWidth: "600px" }}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    {currentRowIndex > -1 && "Redigera rad"}
                                    {currentRowIndex === -1 && "Redigera tabb"}
                                    {currentRowIndex === -2 && "Skapa rad"}
                                    {currentRowIndex === -3 && "Skapa tabb"}
                                </Typography>
                                <input value={currentRowTitle} onChange={e => setCurrentRowTitle(e.target.value)} key={"RowInput"} />
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        style={{ marginTop: "18px" }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => rowModalCloseNow(true)}
                                        >
                                            Spara
                                        </Button>
                                        {currentRowIndex > -2 && <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => removeRow()}
                                        >
                                            Ta bort
                                        </Button>}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => rowModalCloseNow(false)}
                                        >
                                            Stäng
                                        </Button>
                                    </Box>
                                </div>
                            </Box>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default withStyles(styles)(CustomizedTabs);