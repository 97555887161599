import React, { useEffect, useState, useRef } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { tableOptions } from './TableOptions';

import {
    PermIdentity,
    Business,
    Edit,
    Delete,
    Speed,
    MoreVert
} from '@material-ui/icons'

export default function UserTable() {
    const [users, setUsers] = useState([]);
    const [customerFilter, setCustomerFilter] = useState([]);
    const classes = useStyles();
    const [showMenu, setShowMenu] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const muiTable = useRef(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(function () {
        let newUser = localStorage.getItem('currentUser');

        if (newUser) {
            if (JSON.parse(newUser).roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    });

    useEffect(() => {
        axios.get("/users/getall")
            .then(res => {
                if (res.status === 200) {
                    setUsers(res.data);
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const customerName = urlParams.get('kundnamn');
            setCustomerFilter([customerName]);
        }
    }, []);

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Användare borttagen!",
            variant: "contained",
            color: "success",
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function GetBtns(userId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(userId)}>
                <MoreVert id={"menuBtn" + userId} />
            </div>
        )

        //return (
        //    <div style={{ display: "flex", justifyContent: "space-between", width: "160px", float: "right" }}>
        //        <div style={{ cursor: "pointer" }} onClick={() => editBtnClick(user.id)}>
        //            <Edit />
        //        </div>
        //        <div style={{ cursor: "pointer" }} onClick={() => delBtnClick(user.id)}>
        //            <Delete />
        //        </div>
        //    </div>
        //)
    }

    function editBtnClick(e, userId) {
        if (e.button === 0 && !e.ctrlKey) {
            window.location.replace('/#/app/användare/redigera?användarId=' + userId);
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/användare/redigera?användarId=' + userId,
                '_blank'
            );
        }
    }

    function delBtnClick(userId) {
        if (window.confirm("Är du säker på att du vill ta bort användaren?")) {
            axios.delete("/users/delete/" + userId).then(
                res => {
                    if (res.status === 200) {
                        setUsers(users.filter(user => user.id !== userId));
                        sendDeletedNotification();
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }

    function onFilterChange(changedColumn, filterList, type) {
        console.log(filterList);

        setCustomerFilter(filterList[1]);
    }

    return (
        <>
            <Grid container spacing={4}>
                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    {isAdmin && <>
                        <MenuItem
                            onMouseDown={e => editBtnClick(e, menuPlacement)}
                        >
                            <Edit className={classes.profileMenuIcon} /> Redigera
                        </MenuItem>

                        <MenuItem
                            onClick={() => delBtnClick(menuPlacement)}
                        >
                            <Delete className={classes.profileMenuIcon} /> Ta bort
                        </MenuItem></>}
                </Menu>}
                <Grid item xs={12}>
                    {users && (
                        <>
                            <MUIDataTable
                                title="Lista över användare"
                                data={
                                    users.map(
                                        user => {
                                            return [
                                                GetBtns(user.id),
                                                user.email ? user.email : "",
                                                user.fullName,
                                                user.roles[0] ? user.roles[0] : "",
                                                user.title,
                                                user.customer ? user.customer.name : ""
                                            ]
                                        }
                                    )
                                }
                                columns={
                                    [
                                        {
                                            name: "",
                                            options: {
                                                filter: false,
                                                viewColumns: false,
                                                display: isAdmin ? true : false
                                            }
                                        },
                                        {
                                            name: "E-post",
                                        },
                                        {
                                            name: "Namn"
                                        },
                                        {
                                            name: "Systemroll"
                                        },
                                        {
                                            name: "Roll/Titel"
                                        },
                                        {
                                            name: "Kund",
                                            options: {
                                                filterList: customerFilter
                                            }
                                        }
                                    ]}
                                options={tableOptions(null, onFilterChange)}
                                key={users}
                                ref={muiTable}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
