import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import Reset from "../../pages/reset";

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import { Link } from '../../components/Wrappers'

// pages
import Dashboard from '../../pages/dashboard'
import TypographyPage from '../../pages/typography'
import Notifications from '../../pages/notifications'
import Icons from '../../pages/icons'
import Charts from '../../pages/charts'
import LineCharts from '../../pages/charts/LineCharts'
import BarCharts from '../../pages/charts/BarCharts'
import PieCharts from '../../pages/charts/PieCharts'
import Colors from '../../pages/colors'
import GridPage from '../../pages/grid'
import Badge from '../../pages/badge'
import Carousel from '../../pages/сarousel'
import Modal from '../../pages/modal'
import Navbar from '../../pages/nav/Navbar'
import Tooltips from '../../pages/tooltips'
import TabsPage from '../../pages/tabs'
import FormsElements from '../../pages/forms/elements'
import FormValidation from '../../pages/forms/validation'
import Cards from '../../pages/cards'
//import DynamicTables from '../../pages/tables/dynamic'
import WidgetPage from '../../pages/widget'
import Progress from '../../pages/progress'
import Ecommerce from '../../pages/ecommerce'
import Product from '../../pages/ecommerce/Products'
import ProductsGrid from '../../pages/ecommerce/ProductsGrid'
import MapsGoogle from '../../pages/maps'
import VectorMaps from '../../pages/maps/VectorMap'
import Timeline from '../../pages/timeline'
import Search from '../../pages/search'
import Gallery from '../../pages/gallery'
import Invoice from '../../pages/invoice'
import CreateProduct from '../../pages/ecommerce/CreateProduct'
import Calendar from '../../pages/calendar'
import UserList from '../../pages/user/UserList'
import BreadCrumbs from '../../components/BreadCrumbs'
import Profile from '../../pages/profile'
import DraggableGrid from '../../pages/draggablegrid'
import TemplateAddRisk from '../../pages/templates/AddTemplateRisk'
import DetailsTemplate from '../../pages/templates/DetailsTemplate'

//Tables
import CustomerTable from '../../pages/tables/CustomerTable'
import FacilityTable from '../../pages/tables/FacilityTable'
import SectionTable from '../../pages/tables/SectionTable'
import ObjectTypesTable from '../../pages/tables/ObjectTypesTable'
import ObjectTable from '../../pages/tables/ObjectTable'
import DocumentTypesTable from '../../pages/tables/DocumentTypesTable'
import UserTable from '../../pages/tables/UserTable'
import QuestionGroupTable from '../../pages/tables/QuestionGroupTable'
import QuestionTable from '../../pages/tables/QuestionTable'

import MeasureTable from '../../pages/tables/MeasureTable'

import AddCustomer from '../../pages/user/AddCustomer'
import AddFacility from '../../pages/user/AddFacility'
import AddSection from '../../pages/user/AddSection'
import AddObjectType from '../../pages/user/AddObjectType'
import AddObject from '../../pages/user/AddObject'
import AddDocument from '../../pages/user/AddDocument'
import AddUser from '../../pages/user/AddUser'
import AddQuestion from '../../pages/user/AddQuestion'
import AddQuestionGroup from '../../pages/user/AddQuestionGroup'
import AddMeasure from '../../pages/user/AddMeasure'

import EditPassword from '../../pages/user/EditPassword'
import EditFacility from '../../pages/user/EditFacility'
import EditUser from '../../pages/user/EditUser'
import EditCustomer from '../../pages/user/EditCustomer'
import EditObject from '../../pages/user/EditObject'
import EditDocument from '../../pages/user/EditDocument'
import EditObjectType from '../../pages/user/EditObjectType'
import EditSection from '../../pages/user/EditSection';
import EditQuestion from '../../pages/user/EditQuestion';
import EditQuestionGroup from '../../pages/user/EditQuestionGroup';
import EditMeasure from '../../pages/user/EditMeasure';

import DocumentItem from '../../pages/user/DocumentItem'
import TemplateItem from '../../pages/templates/TemplateItem';
import ArchiveItem from '../../pages/user/ArchiveItem'

// context
import { useLayoutState } from '../../context/LayoutContext'
import { ProductsProvider } from '../../context/ProductContext'

//Sidebar structure
import structure from '../Sidebar/SidebarStructure'
import ChangePassword from '../../pages/user/ChangePassword';
import ChangeInformation from '../../pages/user/ChangeInformation';
import AddTemplate from '../../pages/templates/AddTemplate';

function Layout(props) {
    const classes = useStyles()
    // eslint-disable-next-line no-unused-vars
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isAdmin, setIsAdmin] = React.useState(false)

    const open = Boolean(anchorEl)

    React.useEffect(function () {
        let newUser = localStorage.getItem('currentUser');

        if (newUser) {
            if (JSON.parse(newUser).roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    });

    // global
    const layoutState = useLayoutState();

    return (
        <div className={classes.root}>
            <Header history={props.history} />
            {isAdmin ?
                <Sidebar structure={structure} />
                :
                <Sidebar structure={structure.filter(obj => !obj.adminOnly)} />
            }
            <div
                className={classnames(classes.content, {
                    [classes.contentShift]: layoutState.isSidebarOpened,
                })}
            >
                <div className={classes.fakeToolbar + " " + classes.noPrint} />
                <BreadCrumbs />
                <Switch>
                    <Route path="/app/profile" component={Profile} />
                    <Route path="/app/dashboard" component={Dashboard} />
                    <Route
                        path="/app/core/typography"
                        component={TypographyPage}
                    />
                    <Route path="/app/core/grid" component={GridPage} />
                    <Route
                        path="/app/ui/notifications"
                        component={Notifications}
                    />
                    <Route
                        path="/app/forms/elements"
                        component={FormsElements}
                    />
                    <Route
                        path="/app/forms/validation"
                        component={FormValidation}
                    />
                    <Route path="/app/ui/badge" component={Badge} />
                    <Route path="/app/ui/carousel" component={Carousel} />
                    <Route path="/app/ui/modal" component={Modal} />
                    <Route path="/app/ui/navbar" component={Navbar} />
                    <Route path="/app/ui/tooltips" component={Tooltips} />
                    <Route path="/app/ui/tabs" component={TabsPage} />
                    <Route path="/app/ui/cards" component={Cards} />
                    <Route path="/app/ui/widget" component={WidgetPage} />
                    <Route path="/app/ui/progress" component={Progress} />

                    {/* New */}

                    <Route path="/app/lösenord" component={ChangePassword} />
                    <Route path="/app/information" component={ChangeInformation} />

                    <Route path="/app/kunder/kundlista" component={CustomerTable} />
                    <Route path="/app/kunder/ny" component={AddCustomer} />
                    <Route path="/app/kunder/redigera" component={EditCustomer} />
                    <Route exact path="/app/kunder" render={() => <Redirect to={'/app/kunder/kundlista'} />} />

                    <Route path="/app/anläggningar/anläggningslista" component={FacilityTable} />
                    <Route path="/app/anläggningar/ny" component={AddFacility} />
                    <Route path="/app/anläggningar/redigera" component={EditFacility} />
                    <Route path="/app/anläggningar/anläggning" component={ArchiveItem} />
                    <Route exact path="/app/anläggningar" render={() => <Redirect to={'/app/anläggningar/anläggningslista'} />} />

                    <Route path="/app/avdelningar/avdelningslista" component={SectionTable} />
                    <Route path="/app/avdelningar/ny" component={AddSection} />
                    <Route path="/app/avdelningar/redigera" component={EditSection} />
                    <Route exact path="/app/avdelningar" render={() => <Redirect to={'/app/avdelningar/avdelningslista'} />} />

                    <Route path="/app/objekttyper/objekttyplista" component={ObjectTypesTable} />
                    <Route path="/app/objekttyper/ny" component={AddObjectType} />
                    <Route path="/app/objekttyper/redigera" component={EditObjectType} />
                    <Route exact path="/app/objekttyper" render={() => <Redirect to={'/app/objekttyper/objekttyplista'} />} />

                    <Route path="/app/användare/användarlista" component={UserTable} />
                    <Route path="/app/användare/ny" component={AddUser} />
                    <Route path="/app/användare/redigera" component={EditUser} />
                    <Route path="/app/användare/lösenord" component={EditPassword} />
                    <Route exact path="/app/användare" render={() => <Redirect to={'/app/användare/användarlista'} />} />

                    <Route path="/app/mallar/mallista" component={QuestionGroupTable} />
                    <Route exact path="/app/mallar" render={() => <Redirect to={'/app/objekt/forteckning'} />} />

                    <Route path="/app/objekt/forteckning" component={ObjectTable} />
                    <Route path="/app/objekt/nytt" component={AddObject} />
                    <Route path="/app/objekt/redigera" component={EditObject} />
                    <Route path="/app/objekt/Dokument" component={DocumentItem} />
                    <Route path="/app/Dokument/nytt" component={AddDocument} />
                    <Route path="/app/Dokument/redigera" component={EditDocument} />
                    <Route exact path="/app/objekt" render={() => <Redirect to={'/app/objekt/forteckning'} />} />

                    <Route path="/app/mallar/mall" component={TemplateItem} />

                    <Route exact path="/app/checklistor" render={() => <Redirect to={'/app/checklistor/lista'} />} />
                    <Route path="/app/checklistor/lista" component={QuestionGroupTable} />
                    <Route path="/app/checklistor/ny" component={AddQuestionGroup} />
                    <Route path="/app/checklistor/redigera" component={EditQuestionGroup} />
                    <Route path="/app/frågor/lista" component={QuestionTable} />
                    <Route path="/app/frågor/ny" component={AddQuestion} />
                    <Route path="/app/frågor/redigera" component={EditQuestion} />

                    <Route exact path="/app/åtgärdspunkter" render={() => <Redirect to={'/app/åtgärdspunkter/åtgärdslista'} />} />
                    <Route path="/app/åtgärdspunkter/åtgärdslista" component={MeasureTable} />
                    <Route path="/app/åtgärdspunkter/ny" component={AddMeasure} />
                    <Route path="/app/åtgärdspunkter/redigera" component={EditMeasure} />

                    <Route path="/app/dokumenttyper/dokumenttyplista" component={DocumentTypesTable} />
                    <Route exact path="/app/kunder" render={() => <Redirect to={'/app/kunder/kundlista'} />} />

                    {/*<Route
                        path="/app/tables/dynamic"
                        component={DynamicTables}
                    />*/}

                    <Route path="/app/grid" component={DraggableGrid} />
                    <Route path="/app/charts/overview" component={Charts} />
                    <Route path="/app/charts/line" component={LineCharts} />
                    <Route path="/app/charts/bar" component={BarCharts} />
                    <Route path="/app/charts/pie" component={PieCharts} />
                    <Route path="/app/ecommerce/management" exact>
                        <ProductsProvider>
                            <Ecommerce />
                        </ProductsProvider>
                    </Route>
                    <Route path="/app/ecommerce/management/edit/:id" exact>
                        <ProductsProvider>
                            <CreateProduct />
                        </ProductsProvider>
                    </Route>
                    <Route path="/app/ecommerce/management/create">
                        <ProductsProvider>
                            <CreateProduct />
                        </ProductsProvider>
                    </Route>
                    <Route
                        path="/app/ecommerce/product/:id"
                        component={Product}
                    />
                    <Route path="/app/ecommerce/product" component={Product} />
                    <Route
                        path="/app/ecommerce/gridproducts"
                        component={ProductsGrid}
                    />
                    <Route
                        exact
                        path="/app/kunder"
                        render={() => <Redirect to={'/app/kunder/kundlista'} />}
                    />
                    <Route
                        exact
                        path="/app/charts"
                        render={() => <Redirect to={'/app/charts/overview'} />}
                    />
                    <Route
                        exact
                        path="/app/ui"
                        render={() => <Redirect to="/app/ui/icons" />}
                    />
                    <Route
                        exact
                        path="/app/core"
                        render={() => <Redirect to="/app/core/typography" />}
                    />
                    <Route
                        exact
                        path="/app/forms"
                        render={() => <Redirect to="/app/forms/elements" />}
                    />
                    <Route
                        exact
                        path="/app/ecommerce"
                        render={() => (
                            <Redirect to="/app/ecommerce/management" />
                        )}
                    />
                    <Route
                        exact
                        path="/app/extra"
                        render={() => <Redirect to="/app/extra/timeline" />}
                    />
                    <Route
                        exact
                        path="/app/maps"
                        render={() => <Redirect to="/app/maps/google" />}
                    />
                    <Route path="/app/ui/icons" component={Icons} />
                    <Route path="/app/extra/timeline" component={Timeline} />
                    <Route path="/app/extra/search" component={Search} />
                    <Route path="/app/extra/gallery" component={Gallery} />
                    <Route path="/app/extra/invoice" component={Invoice} />
                    <Route path="/app/extra/calendar" component={Calendar} />
                    <Route path="/app/core/colors" component={Colors} />
                    <Route path="/app/maps/google" component={MapsGoogle} />
                    <Route path="/app/maps/vector" component={VectorMaps} />
                    <Route
                        exact
                        path="/app/user"
                        render={() => <Redirect to="/app/user/list" />}
                    />
                    <Route path="/app/user/list" component={UserList} />
                    <Route path="/app/user/add" component={AddUser} />
                    <Route path="/app/user/:id/edit" component={EditUser} />
                    <Route path="/app/user/:id" component={EditUser} />

                    <Route path="/app/mallar/Riskbedomning" component={TemplateAddRisk} />
                    <Route path="/app/riskbedomningar/kund1/mall2" component={DetailsTemplate} />
                </Switch>
                {/*                 <Fab
                    color="primary"
                    aria-label="settings"
                    onClick={e => handleClick(e)}
                    className={classes.changeThemeFab}
                    style={{ zIndex: 100 }}
                >
                    <Icon path={SettingsIcon} size={1} color="#fff" />
                </Fab>
                <ColorChangeThemePopper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                /> */}
                <Footer>
                    <div>
                        <Link
                            color={'primary'}
                            href={'https://www.checkab.se/'}
                            target={'_blank'}
                            className={classes.link}
                        >
                            Check AB
                        </Link>
                    </div>
                </Footer>
            </div>
        </div>
    )
}

export default withRouter(Layout)
