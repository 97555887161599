import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';

import Notification from "../../components/Notification/Notification";

import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

import { actions } from '../../context/ManagementContext'
import {
    useManagementDispatch,
} from '../../context/ManagementContext'

const EditCustomer = () => { //https://localhost:5001/#/app/kunder/redigera?kundId=1
    useEffect(() => {
        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const customerId = urlParams.get('kundId');

            axios.get('/Customers/' + customerId).then(res => {
                if (res.status === 200) {
                    setNewCustomer(res.data);
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }, []);

    const [newCustomer, setNewCustomer] = React.useState({
        name: '',
        customerNumber: '',
        id: -1,
    });

    function handleChange(e) {
        setNewCustomer({
            ...newCustomer,
            [e.target.name]: e.target.value,
        });
    }

    const classes = useStyles()

    var managementDispatch = useManagementDispatch()
    const history = useHistory()
    const doSubmit = (id, data) => {
        actions.doCreate(data, history)(managementDispatch);
    };

    const handleNext = () => {
        axios.put('/Customers/update/' + newCustomer.id, newCustomer).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = '/#/app/kunder/kundlista';
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Kund ändrad!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Redigera kund
                            </Typography>
                            <TextField
                                id="nameField"
                                label="Namn"
                                onChange={handleChange}
                                name="name"
                                value={newCustomer.name || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in kundens nya namn"
                            />
                            <TextField
                                id="numberField"
                                label="Kundnummer"
                                onChange={handleChange}
                                name="customerNumber"
                                value={newCustomer.customerNumber || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in kundens nummer"
                            />
                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                                style={{ marginBottom: 35 }}
                            >
                            </FormControl>
                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Spara ändringar
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default EditCustomer
