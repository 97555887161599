import React, { useState, useEffect } from "react";
import axios from 'axios';
import { cleanseDateStringFromT, getProperCurrentDate } from '../../helpers/storedDataHelper';
import {
    Grid,
    Paper,
    CircularProgress
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Widget from '../../components/Widget/Widget'
import { Delete, Image, ExpandMore as ExpandMoreIcon, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { toast } from 'react-toastify'
import Notification from "../../components/Notification/Notification";
import { withStyles } from '@material-ui/styles'
import {
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    Checkbox,
    FormControl,
    Box,
    TextField
} from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../components/Wrappers";
import { Button } from '../../components/Wrappers'
import NewsTask from "../profile/Components/NewsTasks";

function Dashboard() {
    var classes = useStyles();
    const fileInput = React.useRef(null);
    const [docsWaitingForApproval, setDocsWaitingForApproval] = useState([]);
    const [name, setName] = useState();
    const [dashObject, setDashObject] = useState();
    const [shortcutModalOpen, setShortcutModalOpen] = React.useState(false);
    const [createMeasureModalOpen, setCreateMeasureModalOpen] = React.useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [tabModalOpen, setTabModalOpen] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [customerId, setCustomerId] = useState("-1");
    const [measureFacilityId, setMeasureFacilityId] = useState("-1");
    const [facilityList, setFacilityList] = useState([]);
    const [filteredFacilityList, setFilteredFacilityList] = useState([]);
    const [facilityId, setFacilityId] = useState("-1");
    const [sectionList, setSectionList] = useState([]);
    const [sectionId, setSectionId] = useState("-1");
    const [checkListId, setCheckListId] = useState("-1");
    const [checkedQuestions, setCheckedQuestions] = React.useState([]);
    const [questionGroupList, setQuestionGroupList] = React.useState([]);
    const [archiveObjects, setArchiveObjects] = React.useState([]);
    const [archiveId, setArchiveId] = React.useState("-1");
    const [allowShortcutProceed, setAllowShortcutProceed] = React.useState(false);
    const [loadingShortcut, setLoadingShortcut] = React.useState(false);
    const [measureImageList, setMeasureImageList] = useState([]);
    const [currentShownImage, setCurrentShownImage] = useState(0);
    const [tabs, setTabs] = React.useState([]);

    const GreenCheckbox = withStyles({
        root: {
            color: "#17a506",
            '&$checked': {
                color: "#17a506",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [measure, setMeasure] = React.useState({
        actionText: "",
        actionComment: "",
        status: 0,
        objectID: null,
        formID: null,
        actionExpirationDate: getProperCurrentDate(),
        actionResponsible: "",
    });

    useEffect(() => {
        if (facilityList && facilityList.length > 0 && facilityList[0].customer) {
            setFilteredFacilityList(facilityList.filter(fac => fac.customer && fac.customer.id && fac.customer.id === parseInt(customerId)))
        }
    }, [customerId]);

    useEffect(() => {
        let allowProceed = true;

        if (customerId === "-1") {
            allowProceed = false;
        }

        if (facilityId === "-1") {
            allowProceed = false;
        }

        if (checkListId === "-1") {
            allowProceed = false;
        }

        if (loadingShortcut) {
            allowProceed = false;
        }

        if (questionList.length === 0) {
            allowProceed = false;
        }

        setAllowShortcutProceed(allowProceed);
    }, [customerId, facilityId, sectionId, checkListId, loadingShortcut, questionList]);

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        getTabs();

        if (currentUser) {
            currentUser = JSON.parse(currentUser)
            setName(currentUser.fullName);

            if (currentUser.roles[0] === "Admin") {
                setIsAdmin(true);

                axios.get("/Customers/getall").then(
                    res => {
                        if (res.status === 200) {
                            setCustomerList(res.data);
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            } else {
                if (currentUser.customerID) {
                    setCustomerId(currentUser.customerID.toString());
                }
            }
        }

        axios.get("/Objects/type/3").then(
            res => {
                if (res.status === 200) {
                    setArchiveObjects(res.data)
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/FormQuestionGroup/getall").then(
            res => {
                if (res.status === 200) {
                    setQuestionGroupList(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/Facilities/getall").then(
            res => {
                if (res.status === 200) {
                    setFacilityList(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/Dashboard/getDashboard").then(
            res => {
                if (res.status === 200) {
                    setDashObject(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/Objects/data").then(
            res => {
                if (res.status === 200) {
                    const dataStorage = sessionStorage.getItem('dataStorage');

                    if (!dataStorage) {
                        sessionStorage.setItem('dataStorage', JSON.stringify(res.data));
                    } else {
                        let data = JSON.parse(sessionStorage.getItem('dataStorage'));

                        if (data.version < res.data.version) {
                            sessionStorage.setItem('dataStorage', JSON.stringify(res.data));
                        }
                    }
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        axios.get("/Forms/getall").then(
            res => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setDocsWaitingForApproval(res.data);
                    }
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }, []);

    const handleFile = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('File', file);
        formData.append('ObjectID', archiveId);

        axios.post('/Forms/imageUpload', formData).then(
            res => {
                if (res.status === 201) {
                    let newMeasureImageList = [...measureImageList]
                    newMeasureImageList.push(res.data);
                    setMeasureImageList(newMeasureImageList);
                    setCurrentShownImage(newMeasureImageList.length - 1);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        return;
    }

    const shortcutModalOpenNow = () => {
        setShortcutModalOpen(true);
    };

    const shortcutModalCloseNow = (save) => {
        setShortcutModalOpen(false);

        if (!save) {
            resetShortcutModal();
        }
    };

    const createMeasureModalOpenNow = () => {
        setCreateMeasureModalOpen(true);
    };

    const createMeasureModalCloseNow = () => {
        setMeasureFacilityId("-1");
        setCustomerId("-1");
        setMeasureImageList([]);
        setCreateMeasureModalOpen(false);
        resetCreateMeasureModal();
}
    const tabModalOpenNow = () => {
        setTabModalOpen(true);
    };

    const tabModalCloseNow = (save) => {
        setTabModalOpen(false);
    };

    if (!docsWaitingForApproval) {
        return (<CircularProgress />);
    }

    const imageModalOpenNow = () => {
        setImageModalOpen(true);
    };

    const imageModalCloseNow = () => {
        setImageModalOpen(false);
    };

    function removeSelectedImage() {
        fileInput.current.value = "";
        let newImages = [...measureImageList].filter((item, index) => { return (index !== currentShownImage) });

        if (currentShownImage > 0) {
            setCurrentShownImage(currentShownImage - 1);
        }

        setMeasureImageList(newImages);
    }

    function handleMeasureChange(e) {
        setMeasure({
            ...measure,
            [e.target.name]: e.target.value,
        });
    }

    function handleMeasureFacilityChange(e) {
        setMeasureFacilityId(e.target.value);
        setMeasureImageList([]);

        const facilityArchive = archiveObjects.find(archObj => archObj.facility?.id === e.target.value);

        if (facilityArchive) {
            setArchiveId(facilityArchive.id);
        }
    }

    function handleCreateMeasure() {
        let newMeasure = { ...measure };

        newMeasure.objectID = archiveId;
        newMeasure.images = measureImageList;

        axios.post('/Action/Create', newMeasure).then(res => {
            if (res.status === 200) {
                sendNotification("Åtgärd skapad!", true);
                createMeasureModalCloseNow();
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })

        console.log(newMeasure);
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function handleMeasureStatus(e) {
        if (measure.status === 0) {
            setMeasure({
                ...measure,
                ["status"]: 1,
            });
        } else {
            setMeasure({
                ...measure,
                ["status"]: 0,
            });
        }
    }

    function resetShortcutModal() {
        if (isAdmin) {
            setCustomerId("-1");
        }

        setFacilityId("-1");
        setSectionId("-1");
        setCheckListId("-1");
        setQuestionList([]);
    }

    function resetCreateMeasureModal() {
        setMeasure({
            actionText: "",
            actionComment: "",
            status: 0,
            objectID: null,
            formID: null,
            actionExpirationDate: getProperCurrentDate(),
            actionResponsible: "",
        });
      }

    function getTabs() {
        axios.get("/Taskbar/getalltaskbar").then(
            res => {
                if (res.status === 200) {
                    let newTabs = res.data;

                    for (var i = 0; i < newTabs.length; i++) {
                        let index = i;

                        axios.get("Taskbar/getalltaskbarRows/" + newTabs[i].id).then(
                            res => {
                                if (res.status === 200) {
                                    newTabs[index]['rows'] = res.data;

                                    if (index === newTabs.length - 1) {
                                        setTabs(newTabs);
                                    }
                                }
                            }
                        ).catch(err => {
                            console.log('err');
                            console.log(err);
                        })
                    }
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function createTab(newTabTitle) {
        let newTabs = [...tabs]

        newTabs.push({ title: newTabTitle, rows: [], id: "", order: newTabs.length });

        axios.post("Taskbar/UpdateTaskbarTabs/", newTabs).then(
            res => {
                if (res.status === 200) {
                    newTabs[newTabs.length - 1].id = res.data[res.data.length - 1].id;
                    setTabs(newTabs);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function deleteTab(tabIndex) {
        let newTabs = [...tabs];
        newTabs[tabIndex].isDeleted = true;
        console.log(newTabs);

        axios.post("Taskbar/UpdateTaskbarTabs/", newTabs).then(
            res => {
                if (res.status === 200) {
                    newTabs = newTabs.filter((tab, currentTabIndex) => tabIndex !== currentTabIndex)
                    setTabs(newTabs);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function updateTabTitle(tabIndex, newTabTitle) {
        console.log("updateTabTitle");
        console.log(tabIndex);
        console.log(newTabTitle);

        let newTabs = [...tabs];
        newTabs[tabIndex].title = newTabTitle;

        axios.post("Taskbar/UpdateTaskbarTabs/", newTabs).then(
            res => {
                if (res.status === 200) {
                    setTabs(newTabs);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function handleCheckClick(e, index) {
        let checkedQuestionsChange = [...checkedQuestions];
        checkedQuestionsChange[index] = e.target.checked;
        setCheckedQuestions(checkedQuestionsChange);
    }

    function createChecklist() {
        let questionIDString = "";
        let qGroupName = questionGroupList.find(qGroup => qGroup.id === checkListId).name;
        let currentSection = sectionList.find(section => section.id === sectionId);

        if (questionList.length > 0) {
            for (var i = 0; i < questionList.length; i++) {
                if (checkedQuestions[i]) {
                    questionIDString += questionList[i].id + ","
                }
            }
        }

        if (questionIDString.charAt(questionIDString.length - 1) === ",") {
            questionIDString = questionIDString.substring(0, questionIDString.length - 1);
        }

        let templatePayload = {
            objectID: archiveId.id,
            formTemplateID: "0ec78479-d7d9-4fcf-8917-TOM",
            questionIDs: questionIDString,
            newFormGroupName: qGroupName
        };

        axios.post('/Templates/CreateNewTemplate', templatePayload).then(res => {
            if (res.status === 200) {
                window.location.href = "/#/app/mallar/mall?dokumentNamn=" + qGroupName + " " + (currentSection ? currentSection.name + " " : "") + getProperCurrentDate() + '&objektID=' + archiveId.id + "&mallID=" + res.data.id + "&dokumentTypID=19" + "&skapa&arkiv";
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
            setLoadingShortcut(false);
        })
    }

    function handleCustomerChange(e) {
        setCustomerId(e.target.value);
        setMeasureImageList([]);
        setMeasureFacilityId("-1");
        setFacilityId("-1");
        setSectionId("-1");
        setCheckListId("-1");
        setQuestionList([]);

        if (isAdmin) {
            axios.get("/FormQuestionGroup/getallbycustomer/" + e.target.value).then(
                res => {
                    if (res.status === 200) {
                        setQuestionGroupList(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }

    function handleFacilityChange(e) {
        setFacilityId(e.target.value);
        setSectionId("-1");
        setCheckListId("-1");
        setQuestionList([]);

        let facilityArchive = archiveObjects.find(facObj => facObj.facility?.id === e.target.value);

        if (typeof facilityArchive !== "undefined") {
            setArchiveId(facilityArchive);

            axios.get("/Sections/facility/" + e.target.value).then(
                res => {
                    if (res.status === 200) {
                        setSectionList(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })

            axios.get("/FormQuestion/getall/" + e.target.value).then(
                res => {
                    if (res.status === 200) {
                        setQuestionList(res.data);

                        let checkedQuestionsChange = [];
                        for (var i = 0; i < res.data.length; i++) {
                            checkedQuestionsChange.push(true);
                        }

                        setCheckedQuestions(checkedQuestionsChange);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });
        }
    }

    function handleQuestionGroupChange(e) {
        axios.get("/FormQuestion/getall/" + e.target.value).then(
            res => {
                if (res.status === 200) {

                    setCheckListId(e.target.value);
                    setQuestionList(res.data);

                    let checkedQuestionsChange = [];
                    for (var i = 0; i < res.data.length; i++) {
                        checkedQuestionsChange.push(true);
                    }

                    setCheckedQuestions(checkedQuestionsChange);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });
    }

    if (typeof (dashObject) == "undefined" || typeof (docsWaitingForApproval) == "undefined") {
        return <CircularProgress />
    }

    const leftSideWidth = 10, rightSideWidth = 12;

    return (
        <>
            <Typography style={{ marginBottom: "24px" }}><strong>Välkommen, {name}!</strong></Typography>

            <Grid container spacing={0}>
                <Grid container item xs={12} sm={7} spacing={1} style={{ height: "100%" }}>
                    <Grid item xs={12} lg={leftSideWidth}>
                        <Paper style={{ padding: '30px' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {dashObject ? <table className={classes.dashTable}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography><strong>Checklistor</strong></Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography>Du kan skapa nya checklistor</Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => { shortcutModalOpenNow() }}
                                                        className={classes.dashButton}
                                                        style={{ marginTop: "12px" }}
                                                    >
                                                        Fyll i checklista
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> : <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography><strong>Åtgärdspunkter</strong></Typography>
                                                    <Typography><strong>Du har inga åtgärdspunkter att se över</strong></Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={leftSideWidth}>
                        <Paper style={{ padding: '30px' }}>
                            <Grid item xs={12}>
                                {dashObject ? <table className={classes.dashTable}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Typography><strong>Åtgärdspunkter ({dashObject.formActionsCount})</strong></Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography>Du har {dashObject.formActionsCount} åtgärdspunkter att se över</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={createMeasureModalOpenNow}
                                                    className={classes.dashButton}
                                                    style={{ marginTop: "12px", marginRight: "12px" }}
                                                >
                                                    Skapa åtgärd
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => { window.location.href = '/#/app/åtgärdspunkter/åtgärdslista'; }}
                                                    className={classes.dashButton}
                                                    style={{ marginTop: "12px" }}
                                                >
                                                    Till åtgärdssidan
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> : <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Typography><strong>Åtgärdspunkter</strong></Typography>
                                                <Typography><strong>Du har inga åtgärdspunkter att se över</strong></Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>}
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid container item xs={12} lg={leftSideWidth}>
                        <Grid item xs={12}>
                            <Paper style={{ padding: '30px' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {dashObject ? <table className={classes.dashTable}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography><strong>Fortlöpande tillsyn ({dashObject.fltCount})</strong></Typography>
                                                    </td>
                                                    <td className={classes.dashRightCell}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => { window.location.href = '/#/app/objekt/Forteckning'; }}
                                                            className={classes.dashButton}
                                                            style={{ display: "none" }}
                                                        >
                                                            Till FLT
                                                        </Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <Typography>Du har {dashObject.fltCount} FLT som behöver utföras</Typography>
                                                </tr>
                                            </tbody>
                                        </table> : <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography><strong>Fortlöpande tillsyn</strong></Typography>
                                                        <Typography><strong>Du har inga FLT denna månad</strong></Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} lg={leftSideWidth}>
                        <Grid item xs={12}>
                            <Paper style={{ padding: '30px' }}>
                                <Grid container spacing={1}>
                                    <Typography><strong>Dokument att signera ({docsWaitingForApproval.length})</strong></Typography>
                                    {docsWaitingForApproval && docsWaitingForApproval.length > 0 && <>
                                        {docsWaitingForApproval.map(doc => <>
                                            <Grid container spacing={1} justifyContent="space-between">
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>{doc.name}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <strong>
                                                        {doc.documents[0].dateChanged === "0001-01-01T00:00:00" ? cleanseDateStringFromT(doc.documents[0].dateCreated) : cleanseDateStringFromT(doc.documents[0].dateChanged)}
                                                    </strong>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.dashButton}
                                                        onClick={() => window.location.href = '/#/app/mallar/mall?dokumentNamn=' + doc.name + '&objektID=' + doc.objectID + '&formID=' + doc.id}
                                                    >
                                                        Signera
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>)}
                                    </>}
                                    {(!docsWaitingForApproval || docsWaitingForApproval.length === 0) &&
                                        <Typography>Finns för tillfället inga dokument för dig att godkänna</Typography>}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={5} spacing={1} style={{ height: "100%" }}>
                    <Grid item xs={12} lg={rightSideWidth}>
                        <Paper style={{ padding: '30px' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {dashObject && <>
                                        <table className={classes.dashTable}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography>
                                                            <strong>Översikt</strong>
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Trycksatta Anordningar (A & B):
                                                    </td>

                                                    <td className={classes.dashRightCell}>
                                                        {dashObject.pressureControlledCount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Trycksatta Anordningar (UK):
                                                    </td>

                                                    <td className={classes.dashRightCell}>
                                                        {dashObject.pressureUncontrolledCount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Lyft:
                                                    </td>

                                                    <td className={classes.dashRightCell}>
                                                        {dashObject.liftCount}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table></>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={rightSideWidth}>
                        <Paper style={{ padding: '30px' }}>
                            <Typography>
                                <strong>Anteckningar</strong>
                            </Typography>
                            <NewsTask tabs={tabs} setTabs={setTabs} createTab={createTab} deleteTab={deleteTab} updateTabTitle={updateTabTitle} />
                        </Paper>
                    </Grid>
                </Grid>

                <Modal //Checklist modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modalContent}
                    open={shortcutModalOpen}
                    onClose={() => shortcutModalCloseNow()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={shortcutModalOpen}>
                        <div className={classes.modalPaper} style={{ overflow: "auto", maxWidth: "648px", width: "100%" }}>
                            <Widget>
                                <Grid item justifyContent={'center'} style={{ boxShadow: "none" }} container>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={600}
                                        style={{ width: "100%", maxWidth: "600px" }}
                                    >
                                        <Typography
                                            variant={'h5'}
                                            weight={'medium'}
                                            style={{ marginBottom: 30 }}
                                        >
                                            Grunddata
                                        </Typography>
                                        {isAdmin && <> <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl}>
                                            <InputLabel>Kund</InputLabel>
                                            <Select
                                                value={customerId}
                                                name="customerId"
                                                onChange={e => handleCustomerChange(e)}
                                                label="Kund"
                                            >
                                                <MenuItem value="-1">Ingen kund vald</MenuItem>
                                                {
                                                    customerList.map(
                                                        customer => {
                                                            return (
                                                                <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Ange vilken kund checklistan tillhör
                                            </FormHelperText>
                                        </FormControl></>}
                                        <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl} disabled={customerId === "-1"}>
                                            <InputLabel>Anläggning</InputLabel>
                                            <Select
                                                value={(customerId === "-1" || customerId === "-2") ? "-2" : facilityId ? facilityId : "-1"}
                                                name="facilityID"
                                                onChange={e => handleFacilityChange(e)}
                                                label="Anläggning"
                                            >
                                                <MenuItem value="-1">Ingen anläggning vald</MenuItem>
                                                {customerId < 0 ? <MenuItem value="-2">Välj kund först</MenuItem> : null}

                                                {isAdmin && filteredFacilityList.map(
                                                    facility => {
                                                        return (
                                                            <MenuItem key={facility.id} value={facility.id}>{facility.name}</MenuItem>
                                                        )
                                                    }
                                                )}

                                                {!isAdmin && facilityList.map(
                                                    facility => {
                                                        return (
                                                            <MenuItem key={facility.id} value={facility.id}>{facility.name}</MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                Ange vilken anläggning checklistan tillhör
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl} disabled={facilityId === "-1"}>
                                            <InputLabel>Avdelning</InputLabel>
                                            <Select
                                                value={(facilityId === "-1" || facilityId === "-2") ? "-2" : sectionId ? sectionId : "-1"}
                                                name="sectionId"
                                                onChange={e => setSectionId(e.target.value)}
                                                label="Avdelning"
                                            >
                                                {facilityId === "-1" ? <MenuItem value="-2">Välj anläggning först</MenuItem> : null}
                                                <MenuItem value="-1">Ingen avdelning vald</MenuItem>
                                                {
                                                    sectionList.map(
                                                        sec => {
                                                            return (
                                                                <MenuItem key={sec.id} value={sec.id}>{sec.name}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Ange vilken avdelning checklistan tillhör
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl} disabled={facilityId === "-1"}>
                                            <InputLabel>Checklista</InputLabel>
                                            <Select
                                                name="typeID"
                                                label="Checklista"
                                                onChange={handleQuestionGroupChange}
                                                value={(facilityId === "-1" || facilityId === "-2") ? "-2" : checkListId ? checkListId : "-1"}
                                            >
                                                <MenuItem key={-1} value={"-1"}>Checklista ej vald</MenuItem>
                                                {facilityId === "-1" && <MenuItem key={-2} value={"-2"}>Välj anläggning först</MenuItem>}
                                                {
                                                    questionGroupList.map(
                                                        (questionGroup) => {
                                                            return (
                                                                <MenuItem key={questionGroup.id} value={questionGroup.id}>{questionGroup.name}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Ange vilken checklista som ska fyllas i
                                            </FormHelperText>
                                        </FormControl>
                                        <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                            Välj frågor
                                        </Typography>

                                        <div className={classes.questionContainer}>
                                            {questionList.length === 0 && <Typography>Inga frågor att visa</Typography>}
                                            {questionList && questionList.map((question, index) =>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <GreenCheckbox
                                                            checked={checkedQuestions[index]}
                                                            name={"question" + question.id}
                                                            onClick={e => handleCheckClick(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={10}>
                                                        <Typography
                                                            variant={'h5'}
                                                            style={{ fontSize: "16px", lineHeight: '42px', marginLeft: "4px" }}
                                                        >
                                                            {question.text}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                </Grid>
                                            )}
                                        </div>
                                        <div>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-end'}
                                            >
                                                {!loadingShortcut && <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginRight: "20px" }}
                                                    onClick={() => { setLoadingShortcut(true); createChecklist() }}
                                                    disabled={!allowShortcutProceed}
                                                >
                                                    Börja fylla i checklista
                                                </Button>}

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => shortcutModalCloseNow(false)}
                                                >
                                                    Avbryt
                                                </Button>
                                            </Box>
                                        </div>
                                    </Box>
                                </Grid>
                            </Widget>
                        </div>
                    </Fade>
                </Modal>

                <Modal //CreateMeasure Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modalContent}
                    open={createMeasureModalOpen}
                    onClose={() => createMeasureModalCloseNow()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={createMeasureModalOpen}>
                        <div className={classes.modalPaper} style={{ overflow: "auto", maxWidth: "648px", width: "100%" }}>
                            <Widget>
                                <Grid item justifyContent={'center'} style={{ boxShadow: "none" }} container>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={600}
                                        style={{ width: "100%", maxWidth: "600px" }}
                                    >
                                        <Typography
                                            variant={'h5'}
                                            weight={'medium'}
                                            style={{ marginBottom: 30 }}
                                            onClick={() => handleCreateMeasure()}
                                        >
                                            Skapa åtgärd
                                        </Typography>

                                        {isAdmin && <> <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl}>
                                            <InputLabel>Kund</InputLabel>
                                            <Select
                                                value={customerId}
                                                name="customerId"
                                                onChange={e => handleCustomerChange(e)}
                                                label="Kund"
                                            >
                                                <MenuItem value="-1">Ingen kund vald</MenuItem>
                                                {
                                                    customerList.map(
                                                        customer => {
                                                            return (
                                                                <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Ange vilken kund åtgärden tillhör
                                            </FormHelperText>
                                        </FormControl></>}

                                        <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl} disabled={customerId === "-1"}>
                                            <InputLabel>Anläggning</InputLabel>
                                            <Select
                                                value={measureFacilityId}
                                                name="measureFacilityId"
                                                onChange={e => { handleMeasureFacilityChange(e) }}
                                                label="Anläggning"
                                            >
                                                {customerId < 0 ? <MenuItem value="-1">Välj kund först</MenuItem> : <MenuItem value="-1">Ingen anläggning vald</MenuItem>}

                                                {isAdmin && filteredFacilityList.map(
                                                    facility => {
                                                        return (
                                                            <MenuItem key={facility.id} value={facility.id}>{facility.name}</MenuItem>
                                                        )
                                                    }
                                                )}

                                                {!isAdmin && facilityList.map(
                                                    facility => {
                                                        return (
                                                            <MenuItem key={facility.id} value={facility.id}>{facility.name}</MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                Ange vilken anläggning åtgärden tillhör
                                            </FormHelperText>
                                        </FormControl>

                                        <TextField
                                            id="text"
                                            label="Åtgärdstext"
                                            onChange={handleMeasureChange}
                                            value={measure.actionText}
                                            name="actionText"
                                            variant="outlined"
                                            style={{ marginBottom: 30 }}
                                            helperText="Redigera texten till åtgärden. Tryck enter för att lägga till ny rad"
                                            multiline
                                        />

                                        <div className={classes.splicedFields}>
                                            <TextField
                                                id="text"
                                                label="Ansvarig"
                                                onChange={handleMeasureChange}
                                                name="actionResponsible"
                                                value={measure.actionResponsible}
                                                variant="outlined"
                                                style={{ marginBottom: 30 }}
                                                className={classes.splicedField}
                                                helperText="Skriv in namn på ansvarig till åtgärdspunkten"

                                            />
                                            <div className={classes.splicedFieldDistancer} />
                                            <TextField
                                                type="date"
                                                onChange={handleMeasureChange}
                                                name="actionExpirationDate"
                                                value={measure.actionExpirationDate}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Ange sista datum för åtgärdspunkten"
                                                className={classes.splicedField}
                                            />
                                        </div>

                                        <div className={classes.splicedFields}>
                                            <FormControl variant="outlined" className={classes.splicedField}>
                                                <InputLabel>Status</InputLabel>
                                                <Select
                                                    name="status"
                                                    onChange={handleMeasureChange}
                                                    label="Status"
                                                    value={measure.status}
                                                >
                                                    <MenuItem value={0}>Ej åtgärdad</MenuItem>
                                                    <MenuItem value={1}>Pågående</MenuItem>
                                                    <MenuItem value={2}>Åtgärdad</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    Ange vilken status åtgärdspunkten ska ha
                                                </FormHelperText>
                                            </FormControl>

                                            <div className={classes.splicedFieldDistancer} />

                                            <Grid container item xs={12} justifyContent="center" className={classes.splicedField} style={{ position: "relative" }}>
                                                {measureImageList.length !== 0 && <div>
                                                    {measureImageList[currentShownImage - 1] && <ArrowBackIos style={{ color: "#17a506", position: "absolute", top: "50px", transform: "translateY(-50%)", left: "0%", zIndex: 10, cursor: "pointer" }} onClick={() => setCurrentShownImage(currentShownImage - 1)} />}
                                                    {measureImageList[currentShownImage + 1] && <ArrowForwardIos style={{ color: "#17a506", position: "absolute", top: "50px", transform: "translateY(-50%)", right: "0%", zIndex: 10, cursor: "pointer" }} onClick={() => setCurrentShownImage(currentShownImage + 1)} />}
                                                    <div className={classes.imgContainer}>
                                                        {measureImageList[currentShownImage] && <img className={classes.thumbnail + " image"} src={measureImageList[currentShownImage].filePath} />}
                                                        <span
                                                            onClick={() => removeSelectedImage()}
                                                            className={classes.imgCross}
                                                        >
                                                            <Delete />
                                                        </span>
                                                        <div className={classes.imgMiddle + " middle"}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => imageModalOpenNow()}
                                                                style={{ height: "50%" }}
                                                            >
                                                                Förstora
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>}

                                                <div style={{ marginTop: 10 }}>
                                                    {measureFacilityId !== "-1" && <label
                                                        className={classes.uploadLabel}
                                                        style={{ cursor: 'pointer', margin: "0 auto", display: "block" }}
                                                    >
                                                        <Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                        {'Ladda upp'}
                                                        <input style={{ display: 'none' }} accept="image/png, image/gif, image/jpeg" type="file" ref={fileInput} onChange={e => handleFile(e)} />
                                                    </label>}
                                                    <Typography
                                                        size={'sm'}
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        {measureFacilityId === "-1" ? <span>Välj en anläggning för att ladda upp bilder</span> : <span>Accepterade filformat: <br /> .PNG, .JPG/.JPEG</span>}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </div>

                                        <div>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-end'}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginRight: "20px" }}
                                                    onClick={() => { handleCreateMeasure() }}
                                                    disabled={customerId === "-1" || measureFacilityId === "-1"}
                                                >
                                                    Skapa åtgärd
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => createMeasureModalCloseNow()}
                                                >
                                                    Avbryt
                                                </Button>
                                            </Box>
                                        </div>
                                    </Box>
                                </Grid>
                            </Widget>
                        </div>
                    </Fade>
                </Modal>

                <Modal //Enlarged image modal
                    className={classes.modalContent}
                    open={imageModalOpen}
                    onClose={() => imageModalCloseNow()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={imageModalOpen} onClick={() => imageModalCloseNow()}>
                        <div className={classes.modalPaper}>
                            {measureImageList[currentShownImage] && <img src={measureImageList[currentShownImage].filePath} className={classes.modalImage} />}
                        </div>
                    </Fade>
                </Modal>
            </Grid>
        </>);
}

export default Dashboard;
