import React, { useEffect, useState, useRef } from "react";
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import MUIDataTable from "mui-datatables";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'
import {
    PersonOutline as PersonOutlineIcon,
    Lock as LockIcon,
    Settings as SettingsIcon,
    AssignmentInd,
    VpnLock
} from '@material-ui/icons'

const EditUser = () => {
    const [currentUser, setCurrentUser] = React.useState(
        {
            id: '',
            email: '',
            userName: '-',
            fullName: '',
            roles: ['Kund'],
            title: '',
            customerID: 0,
            signatureImage: "",
            userPermission: null
        }
    );

    const [tab, setTab] = React.useState(0);
    const [id, setId] = React.useState("");
    const [customers, setCustomers] = React.useState([]);
    const [permissions, setPermissions] = useState([false, false, false, false, false, false]);
    const permissionsList = ["11", "41", "51", "61", "71", "81"];
    const [isAdmin, setIsAdmin] = React.useState(false);
    const fileInput = React.useRef(null);

    const GreenCheckbox = withStyles({
        root: {
            color: "#17a506",
            '&$checked': {
                color: "#17a506",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    React.useEffect(function () {
        let newUser = localStorage.getItem('currentUser');

        if (newUser) {
            if (JSON.parse(newUser).roles[0] === "Admin") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    });

    const [newPassword, setNewPassword] = React.useState({
        password: '',
        confirmPassword: ''
    });

    useEffect(() => {
        const params = window.location.href.split('?')[1];

        axios.get("/customers/getall").then(
            res => {
                if (res.status === 200) {
                    setCustomers(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const userId = urlParams.get('användarId');
            setId(userId);
            axios.get("/Users/" + userId).then(
                res => {
                    if (res.status === 200) {
                        setCurrentUser({
                            id: res.data.id,
                            email: res.data.email,
                            userName: "-",
                            fullName: res.data.fullName ? res.data.fullName : "",
                            title: res.data.title,
                            roles: res.data.roles[0] === "Kund" ? ["Kund"] : res.data.roles,
                            customerID: res.data.customer ? res.data.customer.id : 0,
                            signatureImage: res.data.signatureImage,
                            userPermission: res.data.userPermission
                        });

                        let newPermissions = [false, false, false, false, false, false];

                        if (res.data.userPermission.findIndex(obj => obj.permissionID === "11") > -1) {
                            newPermissions[0] = true;
                        }

                        if (res.data.userPermission.findIndex(obj => obj.permissionID === "41") > -1) {
                            newPermissions[1] = true;
                        }

                        if (res.data.userPermission.findIndex(obj => obj.permissionID === "51") > -1) {
                            newPermissions[2] = true;
                        }

                        if (res.data.userPermission.findIndex(obj => obj.permissionID === "61") > -1) {
                            newPermissions[3] = true;
                        }

                        if (res.data.userPermission.findIndex(obj => obj.permissionID === "71") > -1) {
                            newPermissions[4] = true;
                        }

                        if (res.data.userPermission.findIndex(obj => obj.permissionID === "81") > -1) {
                            newPermissions[5] = true;
                        }

                        setPermissions(newPermissions);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }, []);

    function handleChange(e) {
        if (e.target.name === "roles") {
            setCurrentUser({
                ...currentUser,
                roles: [e.target.value],
            });
        } else {
            setCurrentUser({
                ...currentUser,
                [e.target.name]: e.target.value
            });
        }
    }

    function handleCheckboxChange(e, number) {
        let newPermissions = [...permissions];
        newPermissions[number] = e.target.checked;
        setPermissions(newPermissions);
    }

    function savePermissions() {
        let newPermissions = [];

        for (var i = 0; i < permissions.length; i++) {
            if (permissions[i]) {
                newPermissions.push(permissionsList[i]);
            }
        }

        axios.put('/users/updateUserPermissions/' + currentUser.id, newPermissions).then(res => {
            if (res.status === 200) {
                sendNotification("Behörigheter ändrade!");
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    }

    function handleChangePassword(e) {
        setNewPassword({
            ...newPassword,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    }

    const classes = useStyles()

    const handleNext = () => {
        let tempUser;
        if (currentUser.customerID === 0) {
            tempUser = { ...currentUser };
            tempUser.customerID = null;
        } else {
            tempUser = currentUser;
        }

        axios.put('/users/update/' + id, tempUser).then(res => {
            if (res.status === 200) {
                sendNotification("Uppgifter ändrade!");
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    const handlePasswordNext = () => {
        axios.put('/Users/updatePassword/' + id, newPassword).then(res => {
            if (res.status === 200) {
                sendNotification("Lösenord ändrat!");
            }
        })
    };

    function sendNotification(text) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    const handleFile = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('File', file);
        formData.append('UserID', id);

        axios.post('/Users/imageUpload', formData).then(
            res => {
                if (res.status === 201) {
                    setCurrentUser({
                        ...currentUser,
                        signatureImage: res.data.filePath,
                    });
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        return;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            value={tab}
                            onChange={handleChangeTab}
                            aria-label="full width tabs example"
                        >
                            <Tab
                                label="INSTÄLLNINGAR"
                                icon={<SettingsIcon />}
                                classes={{ wrapper: classes.icon }}
                            />
                            <Tab
                                label="BYT LÖSENORD"
                                icon={<LockIcon />}
                                classes={{ wrapper: classes.icon }}
                            />
                            <Tab
                                label="BEHÖRIGHETER"
                                icon={<VpnLock />}
                                classes={{ wrapper: classes.icon }}
                            />
                            {/*<Tab*/}
                            {/*    label="BEHÖRIGHETER"*/}
                            {/*    icon={<LockIcon />}*/}
                            {/*    classes={{ wrapper: classes.icon }}*/}
                            {/*/>*/}
                        </Tabs>
                    </Box>
                </Widget>
            </Grid>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            {tab === 0 ? (
                                <Widget>
                                    <Grid item justifyContent={'center'} container>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            width={600}
                                        >
                                            <Typography
                                                variant={'h5'}
                                                weight={'medium'}
                                                style={{ marginBottom: 30 }}
                                            >
                                                Redigera användare
                                            </Typography>
                                            <TextField
                                                id="fullName"
                                                label="Förnamn och efternamn"
                                                onChange={handleChange}
                                                name="fullName"
                                                value={currentUser.fullName ? currentUser.fullName : ""}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Skriv in namnet på användaren"
                                            />
                                            <TextField
                                                id="title"
                                                label="Titel"
                                                onChange={handleChange}
                                                name="title"
                                                value={currentUser.title || ''}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Skriv in användarens titel"
                                            />
                                            <TextField
                                                id="emailField"
                                                label="E-post"
                                                onChange={handleChange}
                                                name="email"
                                                value={currentUser.email ? currentUser.email : ""}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Skriv in användarens e-post"
                                            />
                                            {isAdmin &&
                                                <>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel>Roll</InputLabel>
                                                        <Select
                                                            labelId="simple-select-label"
                                                            id="roles"
                                                            name="roles"
                                                            value={currentUser.roles[0] ? currentUser.roles[0] : "Kund"}
                                                            onChange={handleChange}
                                                            label="Roll"
                                                            style={{ marginBottom: 35 }}
                                                        >
                                                            <MenuItem key="Kund" value="Kund">Kund</MenuItem>
                                                            <MenuItem key="Admin" value="Admin">Administratör</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel>Kund</InputLabel>
                                                        <Select
                                                            name="customerID"
                                                            value={currentUser.customerID}
                                                            onChange={handleChange}
                                                            label="Kund"
                                                            style={{ marginBottom: 35 }}
                                                        >
                                                            <MenuItem value={0}>
                                                                Ingen kund vald
                                                            </MenuItem>
                                                            {
                                                                customers.map(
                                                                    customer => {
                                                                        return (
                                                                            <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <Grid item xs={12}>
                                                        {currentUser.signatureImage &&
                                                            <div>
                                                                <img style={{ maxWidth: "400px", maxHeight: "200px" }} className={classes.thumbNail} src={currentUser.signatureImage} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <label
                                                            className={classes.uploadLabel}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <AssignmentInd className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                            {currentUser.signatureImage ? 'Ersätt signatur' : 'Ladda upp signatur'}
                                                            <input style={{ display: 'none' }} accept="image/*" type="file" ref={fileInput} onChange={e => handleFile(e)} />
                                                        </label>
                                                        <Typography
                                                            size={'sm'}
                                                            style={{ marginBottom: 30 }}
                                                        >
                                                            Accepterade filformat: .PNG, .JPG/.JPEG
                                                            <br />
                                                            Obs! Bild sparas till användaren omedelbart
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <div>
                                                <div>
                                                    <Box
                                                        display={'flex'}
                                                        justifyContent={'flex-end'}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            disabled={currentUser.email.length === 0 || currentUser.fullName.length === 0 || (currentUser.roles[0] === "Kund" && currentUser.customerID === 0)}
                                                        >
                                                            Ändra inställningar
                                                        </Button>
                                                    </Box>
                                                </div>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Widget>
                            ) : tab === 1 ? (
                                <Widget>
                                    <Grid item justifyContent={'center'} container>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            width={600}
                                        >
                                            <Typography
                                                variant={'h5'}
                                                weight={'medium'}
                                                style={{ marginBottom: 30 }}
                                            >
                                                Ändra användarens lösenord
                                            </Typography>
                                            <TextField
                                                id="password"
                                                label="Nytt lösenord"
                                                onChange={handleChangePassword}
                                                name="password"
                                                value={newPassword.password}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Skriv in användarens nya lösenord"
                                                type={'password'}
                                            />
                                            <TextField
                                                id="confirmPassword"
                                                label="Bekräfta nytt lösenord"
                                                onChange={handleChangePassword}
                                                name="confirmPassword"
                                                value={newPassword.confirmPassword}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText="Skriv in användarens nya lösenord igen för att bekräfta"
                                                type={'password'}
                                            />
                                            <Typography
                                                variant={'h5'}
                                                style={{ marginBottom: 30, fontSize: "16px" }}
                                            >
                                                Lösenordet måste uppfylla följande krav:
                                                <ul>
                                                    <li>Minst 6 tecken</li>
                                                    <li>Minst en liten bokstav ('a' - 'z')</li>
                                                    <li>Minst en stor bokstav ('A' - 'Z')</li>
                                                    <li>Minst en siffra ('0' - '9')</li>
                                                    <li>Minst ett tecken som inte är bokstav eller siffra (Ex. '!' eller '#')</li>
                                                </ul>
                                            </Typography>
                                            <div>
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handlePasswordNext}
                                                    >
                                                        Ändra lösenord
                                                    </Button>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Widget>
                            ) : tab === 2 ? (
                                <Widget>
                                    <Grid item justifyContent={'center'} container>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            width={600}
                                        >
                                            <Typography
                                                variant={'h5'}
                                                weight={'medium'}
                                                style={{ marginBottom: 30 }}
                                            >
                                                Ändra användarens behörigheter
                                            </Typography>

                                            {/*<div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="11"
                                                    onChange={e => handleCheckboxChange(e, 0)}
                                                    checked={permissions[0]}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa och redigera kunder
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="21"
                                                    onChange={e => handleCheckboxChange(e, 1)}
                                                    checked={permissions[1]}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa och redigera anläggningar
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="31"
                                                    onChange={e => handleCheckboxChange(e, 2)}
                                                    checked={permissions[2]}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa och redigera objekttyper
                                                </Typography>
                                            </div>*/}

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="11"
                                                    onChange={e => handleCheckboxChange(e, 0)}
                                                    checked={permissions[0] || currentUser.roles.includes('Admin')}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa och redigera anläggningar
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="41"
                                                    onChange={e => handleCheckboxChange(e, 1)}
                                                    checked={permissions[1] || currentUser.roles.includes('Admin')}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa, redigera, och exportera objekt
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="51"
                                                    onChange={e => handleCheckboxChange(e, 2)}
                                                    checked={permissions[2] || currentUser.roles.includes('Admin')}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa, redigera och exportera åtgärdspunkter
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="61"
                                                    onChange={e => handleCheckboxChange(e, 3)}
                                                    checked={permissions[3] || currentUser.roles.includes('Admin')}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    Kan skapa och redigera checklistor
                                                </Typography>
                                            </div>

                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="71"
                                                    onChange={e => handleCheckboxChange(e, 4)}
                                                    checked={permissions[4] || currentUser.roles.includes('Admin')}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '22px' }}
                                                >
                                                    Kan skapa och redigera dokument, samt lägga till checklistor som dokument
                                                </Typography>
                                            </div>
                                            <div style={{ display: "flex", marginBottom: 35 }}>
                                                <GreenCheckbox
                                                    name="81"
                                                    onChange={e => handleCheckboxChange(e, 5)}
                                                    checked={permissions[5] || currentUser.roles.includes('Admin')}
                                                />
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '22px' }}
                                                >
                                                    Kan skapa mallar för Riskbedömning, Fortlöpande Tillsyn, och Livslängdsjournal
                                                </Typography>
                                            </div>
                                            <div>
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={savePermissions}
                                                        disabled={currentUser.roles.includes('Admin')}
                                                    >
                                                        Spara behörigheter
                                                    </Button>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Widget>
                            ) : <></>}
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default EditUser
