import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';

import Notification from "../../components/Notification/Notification";

import { Button, Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'

import { actions } from '../../context/ManagementContext'
import {
    useManagementDispatch,
} from '../../context/ManagementContext'

const AddCustomer = () => {
    const [newUser, setNewUser] = React.useState({
        name: '',
        customerNumber: ''
    });

    function handleChange(e) {
        setNewUser({
            ...newUser,
            [e.target.name]: e.target.value,
        });
    }

    const classes = useStyles()

    var managementDispatch = useManagementDispatch()
    const history = useHistory()
    const doSubmit = (id, data) => {
        actions.doCreate(data, history)(managementDispatch);
    };

    const handleNext = () => {
        axios.post('/Customers/create', newUser).then(res => {
            if (res.status === 200) {
                sendNotification();
                window.location.href = '/#/app/kunder/kundlista';
            }
        }).catch(err => {
            console.log('err');
            console.log(err);
        })
    };

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Kund tillagd!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa ny kund
                            </Typography>
                            <TextField
                                id="nameField"
                                label="Namn"
                                onChange={handleChange}
                                name="name"
                                value={newUser.name || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in kundens namn"
                            />
                            <TextField
                                id="numberField"
                                label="Kundnummer"
                                onChange={handleChange}
                                name="customerNumber"
                                value={newUser.customerNumber || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Skriv in kundens nummer"
                            />
                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                                style={{ marginBottom: 35 }}
                            >
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                onChange={handleChange}
                                style={{ marginBottom: 35 }}
                            >
                            </FormControl>

                            <div>
                                <div>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Skapa kund
                                        </Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddCustomer
